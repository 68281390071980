<div class="purpl-table-feedback">
  <span *ngIf="label?.length <= 13" class="text-capitalize">{{ label }}</span>

  <span
    class="text-capitalize feedback-hover"
    *ngIf="label?.length > 13"
    [title]="label"
  >
    {{ label | slice: 0:13 }}<strong> ...</strong>
  </span>
  <span *ngIf="feedback_status">
    <button
      type="button"
      class="resolve-btn-feedback resolve-btn-common"
      [title]="
        feedback_status == 'Resolved'
          ? 'Feedback is resolved'
          : 'Resolve this feedback.'
      "
      [ngClass]="{
        'resolved-btn-feedback': feedback_status == 'Resolved'
      }"
      (click)="onResolveRating.emit($event)"
    >
      {{ feedback_status }}
    </button>
  </span>
</div>
