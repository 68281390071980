const FEATURES = {
  DASHBOARD: {
    name: 'dashboard',
  },
  DASHBOARD_TRANSACTIONS: {
    name: 'dashboard_transactions',
  },
  DASHBOARD_APP_USERS: {
    name: 'dashboard_app_users',
  },
  DASHBOARD_SENDERS_ANALYTICS: {
    name: 'dashboard_senders_analytics',
  },
  BRAND_PROFILE: {
    name: 'brand_profile',
  },
  BRAND_PROFILE_CASHOUT_POINT_LISTING: {
    name: 'brand_profile_cashout_points',
  },
  BRAND_PROFILE_CASHOUT_POINT_ADD: {
    name: 'brand_profile_cashout_point_add',
  },
  ENTITIES: {
    name: 'entities',
  },
  ENTITIES_LISTING: {
    name: 'entities_listing',
  },
  ENTITIES_ADD: {
    name: 'entities_add',
  },
  BRAND_CMS_USERS: {
    name: 'brand_cms_users',
  },
  BRAND_CMS_USERS_LISTING: {
    name: 'brand_cms_users_listing',
  },
  BRAND_CMS_USERS_ADD: {
    name: 'brand_cms_users_add',
  },
  ANALYTICS: {
    name: 'analytics',
  },
  ANALYTICS_DASHBOARD: {
    name: 'analytics_dashboard',
  },
  ANALYTICS_SENDERS: {
    name: 'analytics_senders',
  },
  USERS: {
    name: 'users',
  },
  USERS_APP_USERS: {
    name: 'users_app_users',
  },
  USERS_UNREGISTERED_BENEFICIARY: {
    name: 'users_unregistered_beneficiaries',
  },
  USERS_APP_USER_FEEDBACK: {
    name: 'users_app_user_feedbacks',
  },
  USERS_CMS_USERS_LISTING: {
    name: 'users_cms_users_listing',
  },
  USERS_CMS_USERS_ADD: {
    name: 'users_add_cms_user',
  },
  USERS_SENDERS: {
    name: 'users_senders',
  },
  USERS_ROLES_ACCESS: {
    name: 'users_roles_access',
  },
  APP_SETTINGS: {
    name: 'app_settings',
  },
  APP_SETTINGS_HOMEPAGE: {
    name: 'app_settings_home_page',
  },
  APP_SETTINGS_PRIVACY_POLICY: {
    name: 'app_settings_privacy_policy',
  },
  APP_SETTINGS_HELP_CENTER: {
    name: 'app_settings_help_center',
  },
  APP_SETTINGS_HELP_CENTER_FAQ_TOPICS: {
    name: 'app_settings_help_center_faq_topics',
  },
  APP_SETTINGS_HELP_CENTER_FAQS: {
    name: 'app_settings_help_center_faqs',
  },
  APP_SETTINGS_HELP_CENTER_VIDEOS: {
    name: 'app_settings_help_center_faq_videos',
  },
  APP_SETTINGS_TERMS_CONDITIONS: {
    name: 'app_settings_terms_conditions',
  },
  APP_SETTINGS_LANGUAGES: {
    name: 'app_settings_languages',
  },
  APP_SETTINGS_LANGUAGE: {
    name: 'app_settings_language',
  },
  APP_SETTINGS_LANGUAGES_CONTENT: {
    name: 'app_settings_languages_content',
  },
  REMITTANCE_PLATFORMS: {
    name: 'remittance_platforms',
  },
  REMITTANCE_PLATFORMS_LISTING: {
    name: 'remittance_platforms_listing',
  },
  REMITTANCE_PLATFORMS_ADD: {
    name: 'remittance_platforms_add',
  },
  TRANSACTION_MANAGEMENT: {
    name: 'transaction_management',
  },
  TRANSACTION_MANAGEMENT_RECEIVED: {
    name: 'transaction_management_received',
  },
  TRANSACTION_MANAGEMENT_CASHOUTS: {
    name: 'transaction_management_cashout',
  },
  TRANSACTION_MANAGEMENT_REVENUE: {
    name: 'transaction_management_revenue',
  },
  TRANSACTION_MANAGEMENT_BETA: {
    name: 'transaction_beta',
  },
  COMPLIANCE: {
    name: 'compliance',
  },
  COMPLIANCE_BETA: {
    name: 'compliance_beta',
  },
  COMPLIANCE_USERS: {
    name: 'compliance_users',
  },
  SUSPENSIONS: {
    name: 'suspensions',
  },
  SETTING: {
    name: 'setting',
  },
  ACCEPTED_TRANSACTION: {
    name: 'accepted_transactions',
  },
  MERCHANTS: {
    name: 'merchants',
  },
  MERCHANT_ADD: {
    name: 'merchant_add',
  },
  PARTNERS: {
    name: 'partners',
  },
  TRANSFERS: {
    name: 'transfers',
  },
  NGOs: {
    name: 'ngos',
  },
  NGO_ADD: {
    name: 'ngo_add',
  },
};

export default FEATURES;
