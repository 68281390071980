import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-purpl-table-open-new-tab',
  templateUrl: './purpl-table-open-new-tab.component.html',
  styleUrls: ['./purpl-table-open-new-tab.component.scss'],
})
export class PurplTableOpenNewTabComponent implements OnInit {
  @Input() label?: string;
  @Input() un_registered_user_name?: string;
  @Input() registered_user_name?: string;
  @Input() user_id?: string;
  @Input() user_dto_id?: string;
  @Input() receiver_phone_number?: string;

  constructor() {}

  ngOnInit(): void {}
}
