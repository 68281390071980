<div class="position-relative" (click)="onPress.emit($event)">
  <span class="d-inline-block cursor">
    <img
      class="imgTable"
      alt="app-user-image"
      [src]="avatarSrc ? avatarSrc : 'assets/images/dashboard/user5.jpg'"
    />
  </span>
  <purpl-verification-badge
    [status_id]="status_id"
    [compliance]="compliance"
    [did_jumio_and_failed]="
      bounced_jumio === true ? false : did_jumio_and_failed
    "
    [bounced_jumio]="bounced_jumio"
    [iconOnly]="
      status_id === 6 || status_id === 7 || status_id === 3 || status_id === 5
        ? true
        : false
    "
  ></purpl-verification-badge>

  <!-- <purpl-verification-badge
    [status_id]="status_id"
    [compliance]="compliance"
    [did_jumio_and_failed]="
      bounced_jumio === true ? false : did_jumio_and_failed
    "
    [bounced_jumio]="bounced_jumio"
    [iconOnly]="
      status_id === 'SOFT_DELETED' ||
      status_id === 'BLOCKED' ||
      status_id === 'PENDING'
        ? true
        : false
    "
  ></purpl-verification-badge> -->
</div>
