import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerificationBadgeComponent } from './verification-badge.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [VerificationBadgeComponent],
  imports: [CommonModule, NgbTooltipModule],
  exports: [VerificationBadgeComponent],
})
export class VerificationBadgeModule {}
