import { createFeatureSelector, createSelector } from '@ngrx/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiResponseBodyInterface } from '../../interfaces/api-response.interface';

export interface AcceptedTransactionState {
  acceptedTransaction: ApiResponseBodyInterface | null;
  loadingAccepted: boolean;
}

const initialState: AcceptedTransactionState = {
  acceptedTransaction: null,
  loadingAccepted: false,
};

const { actions, reducer, name } = createSlice({
  name: 'acceptedTransaction',
  initialState,
  reducers: {
    getAllAcceptedTransactionPending: (state) => {
      state.loadingAccepted = true;
    },
    getAllAcceptedTransactionFullfilled: (
      state,
      { payload }: PayloadAction<ApiResponseBodyInterface>
    ) => {
      state.loadingAccepted = false;
      state.acceptedTransaction = payload;
    },
    getAllAcceptedTransactionRejected: (state) => {
      state.loadingAccepted = true;
    },
  },
});

const selectAcceptedTransactionFeature =
  createFeatureSelector<ReturnType<typeof reducer>>(name);

export const selectAcceptedTransactionLoading = createSelector(
  selectAcceptedTransactionFeature,
  (state) => state.loadingAccepted
);

export const selectAllAcceptedTransaction = createSelector(
  selectAcceptedTransactionFeature,
  (state) => state.acceptedTransaction
);

export { name };
export const {
  getAllAcceptedTransactionPending,
  getAllAcceptedTransactionFullfilled,
  getAllAcceptedTransactionRejected,
} = actions;

export default reducer;
