import { Component, Injector, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Location } from '@angular/common';
import { FormBaseComponent } from 'src/app/shared/components/base/form-base.component';
import { finalize, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { Positions } from 'src/app/shared/interfaces/Positions';
import { HelperService } from 'src/app/shared/services';
import { orderBy } from 'lodash';
import { APP_PARTNERS } from 'src/app/shared/constants/apis-list';
@Component({
  selector: 'app-create-user',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent extends FormBaseComponent implements OnInit {
  loading = false;
  public form: UntypedFormGroup | undefined;
  image: any = { img: 'assets/images/dashboard/designer.jpg', file: null };
  hide = false;
  editDetails: any;
  brands: any[] = [];
  partners: any[] = [];
  positions: Positions[] = [];
  selectedRole?: string;

  constructor(
    injector: Injector,
    private formBuilder: UntypedFormBuilder,
    private _location: Location
  ) {
    super(injector);
    this.createUserForm();
  }

  ngOnInit() {
    this.getBrandList();
    this.getPositionList();
    this.getAllPartners();

    if (this.isEditMode() == null) {
      this.form?.controls.active.setValue(true);
    } else {
      this.route.data.subscribe((data) => {
        if (data && data.data) {
          this.editDetails = data.data;
          this.editDetails?.image
            ? (this.image.img = this.editDetails?.image)
            : null;
          this.form?.patchValue({
            active: this.editDetails.active,
            firstName: this.editDetails.first_name,
            lastName: this.editDetails.last_name,
            email: this.editDetails.email,
            role: this.editDetails.position,
            branch: this.editDetails.branch_id,
            brand: this.editDetails.brand_id,
            partner: this.editDetails.partner?.id,
          });
          setTimeout(() => {
            this.form?.get('role')?.setValue(this.editDetails.position);
            this.form?.get('brand')?.setValue(this.editDetails.brand_id);
            this.form?.get('partner')?.setValue(this.editDetails.partner?.id);
          }, 100);

          this.form?.updateValueAndValidity();
        }
      });
    }
  }

  createUserForm() {
    this.form = this.formBuilder.group({
      active: [''],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: [
        '',
        [
          Validators.email,
          Validators.pattern(this.constantList.REGEX_EMAIL_ADDRESS),
        ],
      ],
      password: [
        '',
        [
          this.isEditMode() == null
            ? Validators.required
            : Validators.nullValidator,
          Validators.minLength(this.constantList.PASSWORD_MIN_LENGTH),
        ],
      ],
      role: [''],
      brand: [''],
      partner: [''],
    });
  }

  onStatusChange() {
    this.form?.controls.active.setValue(!this.form?.controls.active.value);
  }

  onFirstNameChange(value: string) {
    this.form?.controls.firstName.setValue(value);
  }

  onLastNameChange(value: string) {
    this.form?.controls.lastName.setValue(value);
  }

  onEmailChange(value: string) {
    this.form?.controls.email.setValue(value);
  }

  onPasswordChange(value: string) {
    this.form?.controls.password.setValue(value);
    this.form?.updateValueAndValidity();
  }

  roleChanged(value: string) {
    this.form?.controls.role.setValue(value);
    this.selectedRole = value;
    if (value === 'brand_manager') {
      this.getBrandList();
    }
  }

  brandChanged(value: string) {
    this.form?.controls.brand.setValue(value);
  }

  partnerChanged(value: string) {
    this.form?.controls.partner.setValue(value);
  }

  onImageSelected($event: any) {
    const MAX_SIZE = 2097152; // 2MB in binary
    if ($event.target.files.length === 0) {
      return;
    }
    const file = $event.target.files[0];
    const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    if (!_.includes(allowedFileTypes, file.type)) {
      this.toastrService.info('Only PNG, JPG and JPEG files are allowed');
      return;
    }
    if (file.size > MAX_SIZE) {
      this.toastrService.info('Image size should be less than 2mb');
      return;
    }

    // Image upload
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event: any) => {
      this.image.file = _event.target.result;
      this.image.img = reader?.result?.toString();
    };
  }

  submit() {
    if (
      !this.checkAccessModule(
        this.FEATURES.USERS_CMS_USERS_LISTING.name,
        this.PERMISSION.WRITE
      ) &&
      !this.checkAccessModule(
        this.FEATURES.USERS_CMS_USERS_ADD.name,
        this.PERMISSION.WRITE
      )
    ) {
      this.toastrService.warning(
        'You do not have enough privilege to access this functionality',
        'Access Denied'
      );
      return;
    }

    const body: any = {
      image: this.image.file,
      active: this.form?.controls.active.value,
      first_name: this.form?.controls.firstName.value,
      last_name: this.form?.controls.lastName.value,
      email: this.form?.controls.email.value,
      position: this.form?.controls.role.value,
      brand_id: this.form?.controls.brand.value,
      branch_id: null,
      partner_id: this.form?.controls.partner.value,
    };

    if (this.form?.controls.password.value) {
      body.password = this.form?.controls.password.value;
    }

    console.log('body.position: ', body.position);
    console.log('body.partner_id: ', body.partner_id);

    if (body.position === 'purpl_admin') {
      body.brand_id = null;
      body.branch_id = null;
      body.partner_id = null;
    } else if (body.position === 'partner') {
      if (body.partner_id === '' || body.partner_id === undefined) {
        this.toastrService.warning('Please select a partner', 'Access Denied');
        return;
      } else {
        if (!this.loading) {
          this.loading = true;
          if (this.isEditMode() == null) {
            this.dataService
              .fetchData({
                apiUrl: this.apiList.USER_ADD,
                method: 'POST',
                contentType: 'application/json',
                params: null,
                body,
              })
              .pipe(
                map((response) => {
                  if (response.status === 'failed') {
                    this.toastrService.error(
                      response.errors[0].error,
                      'Failed'
                    );
                    return { status: 'failed' };
                  } else {
                    return response && response.data ? response : null;
                  }
                }),
                finalize(() => {})
              )
              .subscribe((response) => {
                if (response.status === 'success') {
                  setTimeout(() => {
                    this.loading = false;
                    this.router.navigateByUrl('/users/cms-users');
                  }, 1300);
                }
              });
          } else {
            body.id = this.editDetails.id;
            this.dataService
              .fetchData({
                apiUrl: `${this.apiList.USER_UPDATE}`.replace(
                  '{id}',
                  this.editDetails.id
                ),
                method: 'PUT',
                contentType: 'application/json',
                params: null,
                body,
              })
              .pipe(
                map((response) =>
                  response && response.data ? response.data : null
                ),
                finalize(() => {})
              )
              .subscribe(() => {
                setTimeout(() => {
                  this.loading = false;
                  this.router.navigateByUrl('/users/cms-users');
                }, 1300);
              });
            if (this.userService?.user?.email == body.email) {
              if (this.userService.user) {
                this.userService.user = {
                  ...this.userService.user,
                  image: this.image.img,
                  first_name: this.form?.controls.firstName.value,
                };
              }
              this.userService.localService.setEncryptData(
                { key: 'user', value: this.userService.user },
                this.constantList.ENCRYPTION_SECRET
              );
            }
          }
        }
      }
    }
  }

  getBrandList() {
    this.dataService
      .fetchData({
        apiUrl: this.apiList.BRAND_BASE_URL,
        method: 'GET',
        contentType: 'application/json',
        params: null,
        body: null,
      })
      .pipe(
        map((response) => (response && response.data ? response.data : null))
      )
      .subscribe((response) => {
        this.brands = response;
      });
  }

  getAllPartners() {
    this.dataService
      .fetchData({
        apiUrl: APP_PARTNERS,
        method: 'GET',
        contentType: 'application/json',
        params: null,
        body: null,
      })
      .subscribe((response) => {
        this.partners = response?.results;
      });
  }

  getPositionList() {
    this.dataService
      .fetchData({
        apiUrl: this.apiList.POSITIONS_BASE_URL,
        method: 'GET',
        contentType: 'application/json',
        params: null,
        body: null,
      })
      .subscribe((response) => {
        const newArr = response.map((val: any) => {
          const word = val.replace(/_/g, ' ');
          return {
            label: HelperService.capitalizeTheFirstLetterOfEachWord(word),
            value: val,
          };
        });
        this.positions = orderBy(newArr, ['value'], ['asc']);
      });
  }

  backArrowClicked() {
    this._location.back();
  }

  /**
   * The following method is used
   * @returns {string}
   * @param formElementName
   */
  getErrorMessage(formElementName: string): string {
    const formElement = this.form?.get(formElementName);

    // check if any error exists or not
    if (formElement?.errors) {
      // detect the required & email validation being passed on the respective form control i.e. email in this case
      if (
        formElementName === 'email' &&
        (formElement.errors.required ||
          formElement.errors.email ||
          formElement.errors.pattern)
      ) {
        return "It's required";
      }
      // detect the required validation being passed on the respective form control i.e. password in this case
      if (formElementName === 'password' && formElement.errors.required) {
        return 'Password is required';
      }
      // detect the minLength validation being passed on the respective form control i.e. password in this case
      if (formElement.errors.minlength) {
        return 'Password must be 8 - 20 characters long';
      }
    }
    return '';
  }

  getPasswordErrorMessage(formElementName: string): string[] {
    const formElement = this.form?.get(formElementName);
    const errors: any[] = [];
    // check if any error exists or not
    if (formElement?.errors) {
      // detect the required validation being passed on the respective form control i.e. password in this case
      if (formElementName === 'password' && formElement.errors.required) {
        errors.push('Password is required');
        // return 'Password is required';
      }
      // detect the minLength validation being passed on the respective form control i.e. password in this case
      if (formElement.errors.minlength) {
        errors.push('Password must be 8 - 20 characters long');
        // return 'Password must be 8 - 20 characters long';
      }

      if (formElement.errors.hasUpper) {
        errors.push('At least 1 upper case');
        // return 'At least 1 upper case';
      }

      if (formElement.errors.hasLower) {
        errors.push('At least 1 lower case');
        // return 'At least 1 lower case';
      }

      if (formElement.errors.hasNumber) {
        errors.push('At least 1 number');
        // return 'At least 1 number';
      }

      if (formElement.errors.hasChars) {
        errors.push('At least 1 special character');
        // return 'At least 1 special character';
      }

      if (formElement.errors.hasWhiteSpace) {
        errors.push('Password cannot contain space(s)');
        // return 'At least 1 special character';
      }
    }

    return errors;
  }
}
