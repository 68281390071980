<div class="custom-datatable">
  <div class="table-responsive scrollable-container">
    <table class="table table-hover listing-table">
      <thead>
        <tr>
          <th
            *ngFor="let col of tableColumns"
            [ngClass]="col.headingClass || ''"
            class="text-uppercase"
            scope="col"
          >
            {{ col.value }}
          </th>
        </tr>
      </thead>
      <tbody class="datatable-body">
        <tr>
          <td *ngFor="let col of tableColumns">
            <ng-container *ngIf="col.searchable">
              <ng-container *ngIf="col.type == 'toggle'">
                <!-- (selectionChange)="
                    onFilterSearchText(col, $event.value, col.filter)
                  " -->
                <mat-select
                  class="form-control cursor"
                  placeholder="All"
                  (selectionChange)="
                    onFilterSearchText.emit({
                      elem: col,
                      value: $any($event).value,
                      filter: col.filter
                    })
                  "
                >
                  <mat-option value="all"> All </mat-option>
                  <mat-option
                    *ngFor="let option of col.options || []"
                    [value]="option.value"
                  >
                    {{ option.name }}
                  </mat-option>
                </mat-select>
              </ng-container>
              <ng-container *ngIf="col.type != 'toggle'">
                <input
                  name="{{ col.key }}"
                  type="text"
                  class="form-control mw-100"
                  id="{{ col.key }}"
                  placeholder="{{ col.placeholder || col.value }}"
                  (input)="
                    onFilterSearchText.emit({
                      elem: col,
                      value: $any($event).target.value,
                      filter: col.filter
                    })
                  "
                  [(ngModel)]="col.optionValue"
                />
              </ng-container>
            </ng-container>
          </td>
        </tr>
        <tr *ngIf="tableDataListItems.length == 0">
          <td [attr.colspan]="tableColumns.length" class="text-center">
            No Records ...
          </td>
        </tr>
        <tr
          *ngFor="let data of tableDataListItems; let i = index"
          class="datatable-body-row"
          [ngClass]="{
            'datatable-row-odd': i % 2 !== 0,
            'datatable-row-even': i % 2 === 0
          }"
        >
          <td *ngFor="let col of tableColumns" [ngClass]="col.class || ''">
            <div [ngSwitch]="col.type">
              <ng-container *ngSwitchCase="'text'">
                <app-purpl-table-text
                  [label]="constructNestedObject(data, col)"
                  [status]="data?.transaction_status?.status"
                  [key]="col.key"
                ></app-purpl-table-text>
              </ng-container>

              <ng-container *ngSwitchCase="'hyper_link'">
                <app-purpl-table-hyperlink
                  [label]="
                    col.callback
                      ? constructNestedObject(data, col)
                      : data[col.key]
                  "
                  (onPress)="emitHyperLinkEvent(data)"
                ></app-purpl-table-hyperlink>
              </ng-container>

              <ng-container *ngSwitchCase="'toggle'">
                <div *ngIf="col.key == 'position'" class="text-center">
                  <app-purpl-table-text
                    [label]="constructNestedObject(data, col)"
                    [key]="col.key"
                  ></app-purpl-table-text>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="'toggle'">
                <div
                  *ngIf="col.key == 'active'"
                  [title]="
                    data[col.key]
                      ? 'Deactivate the record'
                      : 'Activate the record'
                  "
                  class="custom-control custom-switch"
                  (click)="$event.stopPropagation()"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="{{ 'switch' + i }}"
                    [disabled]="col.disabled"
                    [checked]="data[col.key] || false"
                  />
                  <label class="custom-control-label" for="{{ 'switch' + i }}">
                    {{
                      col.map
                        ? col.map[data[col.key]]
                        : constructNestedObject(data, col)
                    }}
                  </label>
                </div>
              </ng-container>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-between m-t-10 w-100">
    <app-page-limiter
      (onPageLimitChanged)="onPageLimitChanged.emit($event)"
      [pageLimit]="pageLimit"
    ></app-page-limiter>
    <ngb-pagination
      *ngIf="tableDataListItems?.length > 0"
      [collectionSize]="collectionSize"
      [(page)]="page"
      [pageSize]="pageLimit"
      [boundaryLinks]="true"
      [disabled]="!hasMore"
      [boundaryLinks]="true"
      [ellipses]="true"
      (pageChange)="onPageChanged.emit($event)"
      [maxSize]="10"
    >
    </ngb-pagination>
  </div>
</div>
