import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseNetworkService } from './base-network.service';
import { URLQueryParams } from '../interfaces/query-params.interface';
import { ApiResponseInterface } from '../interfaces/api-response.interface';
import { map } from 'rxjs/operators';
import {
  DEFAULT_PAGE_INDEX,
  NUMBER_RECORDS_PER_PAGE,
} from '../constants/constant-list';
import {
  getAllUsersVisitsPending,
  getAllUsersVisitsFullfilled,
  getAllUsersVisitsRejected,
} from '../stores/slices/DashboardBMUsersVisits';

@Injectable({
  providedIn: 'root',
})
export class DashboardBmService extends BaseNetworkService {
  constructor(private injector: Injector, private store: Store) {
    super(injector);
  }

  getDashboardBMAllUsersVisits(query?: Partial<URLQueryParams> | any) {
    const requestBody: any = {
      ...query,
      from_dropdown: true,
      page: query?.page || DEFAULT_PAGE_INDEX,
      size: query?.size || NUMBER_RECORDS_PER_PAGE,
      search: query?.search || '',
    };

    this.store.dispatch(getAllUsersVisitsPending());
    this.dataService
      .fetchData({
        apiUrl: this.apiList.TRANSACTION_LISTING,
        body: requestBody,
      })
      .pipe(
        map((response) => {
          return response && response.data ? response.data : null;
        })
      )
      .subscribe(
        (resource: ApiResponseInterface) =>
          this.store.dispatch(getAllUsersVisitsFullfilled(resource)),
        (error) =>
          this.store.dispatch(
            getAllUsersVisitsRejected({
              error: error?.response?.data || error,
            })
          )
      );
  }
}
