import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-purpl-table-rating',
  templateUrl: './purpl-table-rating.component.html',
  styleUrls: ['./purpl-table-rating.component.scss'],
})
export class PurplTableRatingComponent implements OnInit {
  @Input() rate_type?: string;
  @Input() label?: string;
  @Input() feedback_status?: string;

  @Output() onResolveRating: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}
}
