<ng-container>
  <div class="table-responsive scrollable-container">
    <!-- <div
    [ngClass]="{ 'table-responsive scrollable-container': responsiveTable }"
  > -->
    <table class="table table-hover listing-table">
      <thead>
        <tr>
          <th
            *ngFor="let col of tableColumns"
            [ngClass]="col.headingClass || ''"
            class="text-uppercase"
            scope="col"
          >
            <span *ngIf="!col.sorting">{{ col.value }}</span>

            <div
              class="has-sorter"
              (click)="
                onSortChanged.emit({
                  column: col.sortColumn
                })
              "
              *ngIf="col.sorting"
            >
              {{ col.value }}
              <div class="table-column-sorter">
                <i class="fa fa-long-arrow-down" *ngIf="!isSortedDesc"></i>
                <i class="fa fa-long-arrow-up" *ngIf="isSortedDesc"></i>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="datatable-body">
        <tr *ngIf="!hideFilter" class="datatable-body-row">
          <td *ngFor="let col of tableColumns">
            <div class="form-group mb-0">
              <ng-container *ngIf="col.searchable">
                <ng-container *ngIf="col.type == 'toggle'">
                  <mat-select
                    *ngIf="!showStarIcon"
                    class="form-control cursor"
                    placeholder="All"
                    (change)="onSearchInputChanged(col, $event.target.value)"
                  >
                    <mat-option value="all">
                      All
                    </mat-option>
                    <mat-option
                      *ngFor="let option of col.options || []"
                      [value]="option.value"
                    >
                      {{ option.name }}
                    </mat-option>
                  </mat-select>
                </ng-container>
                <ng-container *ngIf="col.type != 'toggle'">
                  <input
                    name="{{ col.key }}"
                    type="text"
                    class="form-control mw-100"
                    id="{{ col.key }}"
                    placeholder="{{ col.placeholder || col.value }}"
                    (input)="
                      filterSearchText.next({
                        elem: col,
                        value: $any($event).target.value,
                        filter: col.filter
                      })
                    "
                  />
                </ng-container>
              </ng-container>
            </div>
          </td>
        </tr>
        <tr *ngIf="tableDataListItems.length == 0">
          <td [attr.colspan]="tableColumns.length" class="text-center">
            No Records ...
          </td>
        </tr>
        <tr
          *ngFor="let data of tableDataList | async; let i = index"
          class="datatable-body-row"
          [ngClass]="{
            'datatable-row-odd': i % 2 !== 0,
            'datatable-row-even': i % 2 === 0
          }"
        >
          <td
            *ngFor="let col of tableColumns"
            [ngClass]="col.class || ''"
            (click)="col?.type == 'comment' ? open(comment, data, true) : null"
          >
            <div [ngSwitch]="col.type">
              <ng-container *ngSwitchCase="'avatar'">
                <app-purpl-table-avatar
                  [avatarSrc]="data[col.key]"
                  [status_id]="data?.status_id"
                  [compliance]="data?.compliance"
                  [did_jumio_and_failed]="
                    data?.bounced_jumio === true
                      ? false
                      : data?.did_jumio_and_failed
                  "
                  [bounced_jumio]="
                    data?.did_jumio_and_failed === true
                      ? data?.bounced_jumio
                      : data?.bounced_jumio
                  "
                  (onPress)="emitHyperLinkEvent(data)"
                ></app-purpl-table-avatar>
              </ng-container>

              <ng-container *ngSwitchCase="'popup_avatar'">
                <app-purpl-popup-avatar
                  [avatarSrc]="data[col.key]"
                  [status_id]="data?.status_id"
                  [compliance]="data?.compliance"
                  [did_jumio_and_failed]="
                    data?.bounced_jumio === true
                      ? false
                      : data?.did_jumio_and_failed
                  "
                  [bounced_jumio]="
                    data?.did_jumio_and_failed === true
                      ? data?.bounced_jumio
                      : data?.bounced_jumio
                  "
                  [data]="data"
                  [popContent]="popUpContent"
                  (onPress)="
                    onTogglePopover.emit({
                      p1: $event.p1,
                      data: $event.data
                    })
                  "
                ></app-purpl-popup-avatar>
              </ng-container>

              <ng-container *ngSwitchCase="'text'">
                <app-purpl-table-text
                  [label]="constructNestedObject(data, col)"
                  [status]="data?.transaction_status?.status"
                  [key]="col.key"
                ></app-purpl-table-text>
              </ng-container>

              <ng-container *ngSwitchCase="'open_new_tab'">
                <app-purpl-table-open-new-tab
                  [label]="constructNestedObject(data, col)"
                  [un_registered_user_name]="data?.un_registered_user_name"
                  [receiver_phone_number]="data?.receiver_phone_number"
                  [registered_user_name]="data?.registered_user_name"
                  [user_id]="data?.user_id"
                  [user_dto_id]="data?.user_dto?.id"
                ></app-purpl-table-open-new-tab>
              </ng-container>

              <ng-container *ngSwitchCase="'comment'">
                <app-purpl-table-comment
                  [label]="constructNestedObject(data, col)"
                ></app-purpl-table-comment>
              </ng-container>

              <ng-container *ngSwitchCase="'platform'">
                <app-purpl-table-platform
                  [platform]="constructNestedObject(data, col)"
                ></app-purpl-table-platform>
              </ng-container>

              <ng-container *ngSwitchCase="'full-name'">
                <app-purpl-table-full-name
                  [imgSrc]="data?.user?.image"
                  [full_name]="data?.user?.full_name"
                ></app-purpl-table-full-name>
              </ng-container>

              <ng-container *ngSwitchCase="'rating'">
                <app-purpl-table-rating
                  [rate_type]="data?.feeling"
                  [label]="constructNestedObject(data, col).replace('_', ' ')"
                  [feedback_status]="data?.feedback_status_dto?.feedback_status"
                  (onResolveRating)="resolveRating(data, 'rating')"
                ></app-purpl-table-rating>
              </ng-container>

              <ng-container *ngSwitchCase="'video'">
                <app-purpl-table-video
                  [videoSrc]="constructNestedObject(data, col)"
                ></app-purpl-table-video>
              </ng-container>

              <ng-container *ngSwitchCase="'feedback'">
                <app-purpl-table-feedback
                  [label]="constructNestedObject(data, col)"
                  [feedback_status]="data?.feedback_status_dto?.feedback_status"
                  (onResolveRating)="resolveRating(data, 'feedback')"
                ></app-purpl-table-feedback>
              </ng-container>

              <ng-container *ngSwitchCase="'currency'">
                {{ constructNestedObject(data, col) | number : "2.2-2" }}
                {{ col.currency_code ? col.currency_code.toUpperCase() : "" }}
              </ng-container>

              <ng-container *ngSwitchCase="'hyper_link'">
                <app-purpl-table-hyperlink
                  [label]="constructNestedObject(data, col)"
                  (onPress)="emitHyperLinkEvent(data)"
                ></app-purpl-table-hyperlink>
              </ng-container>

              <ng-container *ngSwitchCase="'popup_link'">
                <app-purpl-table-popup-link
                  [label]="constructNestedObject(data, col)"
                  [data]="data"
                  [popContent]="popUpContent"
                  (onTogglePopover)="
                    onTogglePopover.emit({
                      p1: $event.p1,
                      data: $event.data
                    })
                  "
                ></app-purpl-table-popup-link>
              </ng-container>

              <ng-container *ngSwitchCase="'html'">
                <app-purpl-table-html
                  [htmlContent]="constructNestedObject(data, col)"
                ></app-purpl-table-html>
              </ng-container>

              <ng-container *ngSwitchCase="'more-details'">
                <app-purpl-table-more-details
                  (onPress)="moreDetails(data)"
                ></app-purpl-table-more-details>
              </ng-container>

              <ng-container *ngSwitchCase="'date'">
                {{ constructNestedObject(data, col) | date }}
              </ng-container>

              <ng-container *ngSwitchCase="'status'">
                <app-purple-table-status
                  [label]="constructNestedObject(data, col)"
                  (onPressVerify)="emitVerifyStatusClick(data)"
                ></app-purple-table-status>
              </ng-container>

              <ng-container *ngSwitchCase="'button'">
                <app-purpl-table-button
                  *ngIf="showDelete"
                  (onPress)="onDeleteButtonClicked(data)"
                ></app-purpl-table-button>
              </ng-container>

              <ng-container *ngSwitchCase="'toggle'">
                <div
                  *ngIf="
                    col.map &&
                    col.map[data[col.key]] != 'Pending' &&
                    col.map[data[col.key]] != 'Accept' &&
                    !showStarIcon &&
                    col.key != 'position' &&
                    col.key != 'transaction_status' &&
                    col?.remittance != 'input' &&
                    col.key != 'user_status' &&
                    col.key != 'remittance_platform_name' &&
                    col.key != 'code' &&
                    col.key != 'branch_dto'
                  "
                  [title]="
                    data[col.key]
                      ? 'Deactivate the record'
                      : 'Activate the record'
                  "
                  class="custom-control custom-switch"
                  (click)="$event.stopPropagation()"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="{{ 'switch' + i }}"
                    [disabled]="col.disabled"
                    (change)="
                      onCheckboxChanged(data, col, $any($event.target).checked)
                    "
                    [checked]="data[col.key] || false"
                  />
                  <label class="custom-control-label" for="{{ 'switch' + i }}">
                    {{
                      col.map
                        ? col.map[data[col.key]]
                        : constructNestedObject(data, col)
                    }}
                  </label>
                </div>
                <div *ngIf="col.remittance == 'input'">
                  <span *ngIf="col.map[data[col.key]] == 'Active'">
                    {{ constructNestedObject(data, col) }}
                  </span>
                  <span
                    *ngIf="col.map[data[col.key]] == 'Suspended'"
                    class="font-color-danger"
                  >
                    {{ constructNestedObject(data, col) }}
                  </span>
                </div>
                <!-- <div *ngIf="col.key == 'user_status'">
                  {{ col.map[data[col.key]] }}
                </div>
                <div *ngIf="col.key == 'remittance_platform_name'">
                  {{ col.map[data[col.key]] }}
                </div>
                <div *ngIf="col.key == 'code'">
                  {{ col.map[data[col.key]] }}
                </div>
                <div *ngIf="col.key == 'branch_dto'">
                  {{ col.map[data[col.key]] }}
                </div> -->
                <div
                  *ngIf="
                    col.key == 'user_status' ||
                    col.key == 'remittance_platform_name' ||
                    col.key == 'code' ||
                    col.key == 'branch_dto'
                  "
                >
                  {{ col.map[data[col.key]] }}
                </div>

                <div *ngIf="col.key == 'position'">
                  {{ formatUserPosition(data?.position) }}
                </div>
                <div
                  *ngIf="
                    col.map[data[col.key]] == 'Pending' ||
                    col.key == 'transaction_status'
                  "
                  class="text-center"
                >
                  {{ col.map[data[col.key]] }}
                </div>
                <div
                  *ngIf="col.map[data[col.key]] == 'Accept'"
                  class="text-center"
                >
                  <button
                    class="btn btn-success accept-btn"
                    (click)="onAcceptBtnClicked(data)"
                  >
                    {{ col.map[data[col.key]] }}
                  </button>
                </div>
                <div
                  *ngIf="showStarIcon"
                  class="text-center"
                  (click)="$event.stopPropagation()"
                  [title]="
                    col.map[data[col.key]] == 'Blocked'
                      ? 'Unblock the user.'
                      : 'Block the user.'
                  "
                >
                  <img
                    class="cursor"
                    *ngIf="col.map[data[col.key]] != 'Blocked'"
                    src="assets/images/purpl/block.png"
                    alt="block-icon"
                    (click)="open(block, data, false)"
                  />
                  <span
                    *ngIf="col.map[data[col.key]] == 'Blocked'"
                    class="unblock-btn cursor"
                    (click)="open(unblock, data, true)"
                  >
                    UNBLOCK
                  </span>
                </div>
              </ng-container>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-between m-t-10 w-100">
    <app-page-limiter
      [pageLimit]="pageLimit"
      (onPageLimitChanged)="onPageLimitChanged($event)"
    ></app-page-limiter>
    <ngb-pagination
      *ngIf="tableDataListItems?.length > 0"
      [collectionSize]="totalNumberOfRecords"
      [page]="page"
      [pageSize]="pageLimit"
      [boundaryLinks]="true"
      [disabled]="!hasMore"
      [boundaryLinks]="true"
      [ellipses]="true"
      [maxSize]="10"
      (pageChange)="
        page = $event; onPageChange.emit($event); loadResourcesPage(page)
      "
    >
    </ngb-pagination>
  </div>
</ng-container>

<ng-template #unblock let-modal>
  <div class="modal-header">
    <h5 class="modal-title f-w-600" id="exampleModalLabel">
      Confirmation Message
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body dialog-msg">
    Are you sure you want to unblock the user?
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-danger px-4"
      type="button"
      data-dismiss="modal"
      (click)="modal.dismiss('Cross click')"
    >
      No
    </button>
    <button
      type="button"
      class="btn btn-primary px-4"
      (click)="modal.close('Yes')"
    >
      Yes
    </button>
  </div>
</ng-template>

<ng-template #block let-modal>
  <div class="modal-header">
    <h5 class="modal-title f-w-600" id="exampleModalLabel">
      Confirmation Message
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body dialog-msg">
    Are you sure you want to block the user?
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-danger px-4"
      type="button"
      data-dismiss="modal"
      (click)="modal.dismiss('Cross click')"
    >
      No
    </button>
    <button
      type="button"
      class="btn btn-primary px-4"
      (click)="modal.close('Yes')"
    >
      Yes
    </button>
  </div>
</ng-template>

<ng-template #comment let-modal>
  <div class="modal-header">
    <h5 class="modal-title f-w-600" id="exampleModalLabel">
      Write Your Comments
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body dialog-msg my-3">
    <div class="col-12 d-flex p-0">
      <div class="col-xl-4 col-md-4 my-auto p-0">
        <label for="comment"> Enter Comment </label>
      </div>
      <div class="col-xl-8 col-md-8 d-flex p-0">
        <input
          type="text"
          name="comment"
          class="form-control mw-100 comment-field"
          [value]="commentText"
          (keyup)="commentChange($any($event).target.value)"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-danger px-4"
      type="button"
      data-dismiss="modal"
      (click)="modal.dismiss('Cross click')"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary px-4"
      (click)="modal.close('Save Comment')"
    >
      Save
    </button>
  </div>
</ng-template>
