import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'sanitizeHtml' })

export class SanitizeHtmlPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) { }

    transform(value: any): SafeHtml {
        // const element = document.createElement('div');
        // value = value.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
        // value = value.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
        // element.innerHTML = value;
        // value = element.textContent;
        // element.textContent = '';
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
}
