export enum FormSchemaTypes {
  Text = 'text',
  TextInput = 'text_input',
  Checkbox = 'checkbox',
  RadioButton = 'radio_button',
  RadioButtonGroup = 'radio_button_group',
  Dropwdown = 'dropdown',
  DatePicker = 'date_picker',
  TimePicker = 'time_picker',
  PasswordInput = 'password_input',
}

export enum FormSchemaTypesV2 {
  String = 'string',
  Boolean = 'boolean',
  Choice = 'choice',
  Date = 'date',
  Time = 'time',
}

export type FormCommonValue = string | number | boolean | undefined;

export type PickerValue = {
  text: string;
  value: string;
  icon?: string;
  disabled?: boolean;
};

export type RadioGroups = {
  text: string;
  value: string;
  disabled?: boolean;
};

export type Choices = {
  display_name: string;
  value: string;
  toLowerCase: any;
};

export interface OnyIfFieldProps {
  field: string;
  operator: string;
  value: string;
}

export interface FormSchema {
  type: FormSchemaTypes;
  label: string;
  name: string;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  choices?: Partial<PickerValue>[] &
    Partial<Choices>[] &
    Partial<RadioGroups>[];
  dateFormat?: string;
  timeFormat?: string;
  maxLength?: number;
  required?: boolean;
  passwordMask?: string;
}

export interface FormSchemaV2 {
  [key: string]: FormSchemaProps;
}

export interface FormFieldsSchema {
  fields: {
    [key: string]: FormSchemaProps;
  };
  method: string;
}

export interface FormSchemaProps {
  type: FormSchemaTypesV2 | string;
  label: string;
  name?: string;
  value?: string;
  read_only?: boolean;
  max_length?: number;
  min_value?: number;
  required?: boolean;
  placeholder?: string;
  choices?: Choices[];
  effective_from?: string;
  category?: string;
  only_if?: [];
}

export interface APIResponse {
  body: any;
  code: number;
  error_code?: string;
  message?: string;
  status?: string;
  validation_errors: [
    {
      error: 'string';
      field_name: 'string';
    }
  ];
}

export interface APIPaginationResponse {
  items: any[] | any;
  page: number;
  size: number;
  total: number;
  total_pages: number;
}
