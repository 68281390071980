<div class="purpl-table-rating">
  <img
    class="mr-2 rating-img"
    *ngIf="rate_type == 'AWESOME'"
    src="/assets/images/purpl/awesome.png"
    alt="rating"
  />
  <img
    class="mr-2 rating-img"
    *ngIf="rate_type == 'HAPPY'"
    src="/assets/images/purpl/happy.png"
    alt="rating"
  />
  <img
    class="mr-2 rating-img"
    *ngIf="rate_type == 'BAD'"
    src="/assets/images/purpl/bad.png"
    alt="rating"
  />
  <img
    class="mr-2 rating-img"
    *ngIf="rate_type == 'NOT_SURE'"
    src="/assets/images/purpl/not_sure.png"
    alt="rating"
  />
  <img
    class="mr-2 rating-img"
    *ngIf="rate_type == 'MUTE'"
    src="/assets/images/purpl/mute.png"
    alt="rating"
  />
  <span class="text-capitalize">{{ label }}</span>

  <span *ngIf="!!feedback_status">
    <button
      type="button"
      class="resolve-btn resolve-btn-rating"
      [ngClass]="{
        'resolved-btn': feedback_status == 'Resolved'
      }"
      (click)="onResolveRating.emit($event)"
    >
      {{ feedback_status }}
    </button>
  </span>
</div>
