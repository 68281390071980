import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-page-limiter',
  templateUrl: './page-limiter.component.html',
  styleUrls: ['./page-limiter.component.scss'],
})
export class PageLimiterComponent implements OnInit {
  @Input() pageLimit?: number;
  @Output() onPageLimitChanged: EventEmitter<any> = new EventEmitter<any>();

  pageLimitData: number[] = [10, 25, 50, 100];

  constructor() {}

  ngOnInit(): void {}
}
