<div
  class="btn-group btn-group-toggle"
  role="group"
  (click)="onPageLimitChanged.emit($event?.target?.value)"
>
  <label
    class="btn btn-outline-primary"
    [ngClass]="{ active: item === pageLimit }"
    *ngFor="let item of pageLimitData"
  >
    <input type="radio" [value]="item" /> {{ item }}
  </label>
</div>
