import {
  Component,
  ViewEncapsulation,
  Injector,
  HostBinding,
  OnDestroy,
} from '@angular/core';

import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { BaseComponent } from './base.component';
import FEATURES from '../../constants/navigation';
import { PERMISSION } from '../../constants/constant-list';

/*
 * Base Component
 * Top Level Component
 */
@Component({
  selector: 'app-form-base-component',
  encapsulation: ViewEncapsulation.None,
  template: '',
})
export class FormBaseComponent extends BaseComponent implements OnDestroy {
  public PERMISSION: any = PERMISSION;
  public FEATURES: any = FEATURES;
  /**
   * The following is the baseModel instance to the rendered form on the UI
   * @type {FormGroup}
   */
  public formGroup: UntypedFormGroup | any = new UntypedFormGroup({});
  public formData: FormData = new FormData();

  /**
   * the following is used to keep the page title & button text based on whether its add or edit form
   */
  public pageTitle = '';
  public addEditButtonText = 'Add';

  private formSubmitSubscription$: Subscription;
  protected disabledControls: string[] = [];

  /**
   * the following serves as the phone validation
   */
  protected phoneOneValidations: ValidatorFn[] = [
    Validators.required,
    Validators.minLength(5),
    Validators.maxLength(15),
  ];

  protected phoneSecondValidations: ValidatorFn[] = [
    Validators.minLength(5),
    Validators.maxLength(15),
  ];

  /*@HostBinding('class.h-100-p') fullHeightClass: Boolean = true;*/
  @HostBinding('class.overflow-scroll') overFlowScrollClass = true;

  constructor(injector: Injector) {
    super(injector);
    /**
     * the following method is used to monitor the status for toggle submit button
     */
    this.formSubmitSubscription$ = this.sharedDataService.loadingBarStatus
      .pipe(distinctUntilChanged())
      .subscribe((bool: boolean) => {
        setTimeout(() => {
          if (typeof bool !== null) {
            // putting timer incase of rapid API response
            this.isFormSubmitted = bool;
            // if not destroyed update the view
            if (!this.cd.destroyed) {
              this.cd.detectChanges();
            }
          }
        }, 0);
      });
  }

  ngOnDestroy() {
    if (this.formSubmitSubscription$) {
      this.formSubmitSubscription$.unsubscribe();
    }
  }

  protected setPopupTitle(
    module: any,
    field: string = 'name',
    model: any = this.baseModel
  ): void {
    this.translationSubscription$ = this.translate
      .get('GENERAL.TEXT')
      .subscribe((trans: any) => {
        this.pageTitle =
          model && model.id
            ? `${trans.EDIT} : ${model[field] || '#' + model.id}`
            : `${trans.ADD} ${module}`;
        this.addEditButtonText =
          model && model.id ? `${trans.SAVE}` : `${trans.ADD}`;
      });
  }

  /**
   * The following adds the respective control with its respective form validation
   * @param formElement
   * @param validations
   * @param disabled
   * @param formGroup
   * @param value
   */
  protected addFormControlWithValidations(
    formElement: string,
    validations: ValidatorFn[] = [],
    disabled = false,
    formGroup?: UntypedFormGroup,
    value?: any
  ): void {
    if (formGroup) {
      formGroup.addControl(
        formElement,
        new UntypedFormControl({ value: value || '', disabled }, validations)
      );
    } else {
      this.formGroup.addControl(
        formElement,
        new UntypedFormControl({ value: '', disabled }, validations)
      );
    }
  }

  /**
   * The following adds the respective control with its respective form validation
   * @param formElement
   * @param formGroup
   */
  protected removeFormControl(
    formElement: string,
    formGroup?: UntypedFormGroup
  ): void {
    if (formGroup) {
      if (formGroup.get(formElement)) {
        formGroup.removeControl(formElement);
        formGroup.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      }
    } else if (this.formGroup.get(formElement)) {
      this.formGroup.removeControl(formElement);
      this.formGroup.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
    }
  }

  /**
   * The following method is used to disable the respective form groups
   * @param {FormGroup[]} formGroupArray
   */
  protected disableForm(formGroupArray: UntypedFormGroup[]): void {
    // stopping emitting event
    for (const formGroup of formGroupArray) {
      formGroup.disable({ onlySelf: true, emitEvent: false });
    }
  }

  /**
   * The following method is used to enable the respective form group
   * @param {FormGroup[]} formGroupArray
   */
  protected enableForm(formGroupArray: UntypedFormGroup[]): void {
    // stopping emitting event
    for (const formGroup of formGroupArray) {
      formGroup.enable({ onlySelf: true, emitEvent: false });
    }
  }

  /**
   * The following method is used to disable the respective form control
   * @param {FormGroup} formGroup
   * @param controlName
   */
  protected disableFormControl(
    formGroup: UntypedFormGroup,
    controlName: string = ''
  ): void {
    if (controlName && formGroup.controls[controlName]) {
      if (!formGroup.controls[controlName].disabled) {
        // stopping emitting event
        formGroup.controls[controlName].disable({
          onlySelf: true,
          emitEvent: false,
        });
      }
    }
  }

  /**
   * The following method is used to enable the respective form control
   * @param {FormGroup} formGroup
   * @param controlName
   */
  protected enableFormControl(
    formGroup: UntypedFormGroup,
    controlName: string = ''
  ): void {
    // stopping emitting event
    if (controlName && formGroup.controls[controlName]) {
      if (!formGroup.controls[controlName].enabled) {
        // stopping emitting event
        formGroup.controls[controlName].enable({
          onlySelf: true,
          emitEvent: false,
        });
      }
    }
  }

  isFormDisable(): boolean {
    return this.isFormSubmitted || this.formGroup.invalid;
  }

  validateArabicTextarea(
    value: string | null,
    controlName: string,
    formGroup: any = this.formGroup
  ) {
    if (value && controlName) {
      if (this.constantList.PATTERN_ARABIC_CHAR.test(value) === false) {
        formGroup.controls[controlName].setErrors({ pattern: true });
      } else {
        formGroup.controls[controlName].setErrors({ pattern: false });
      }

      formGroup.controls[controlName].updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
      formGroup.updateValueAndValidity({ emitEvent: false });
    }
  }

  protected recheckAllDisabledControls(
    formGroup: UntypedFormGroup = this.formGroup
  ): void {
    if (formGroup) {
      this.disabledControls.forEach((control) => {
        setTimeout(() => {
          this.disableFormControl(formGroup, control);
        }, this.constantList.DEFAULT_DEBOUNCE_TIME);
      });
    }
  }

  public hasControlValidation(
    abstractControl: AbstractControl | any,
    validation: string
  ): boolean {
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator[validation]) {
        return true;
      }
    } else if (abstractControl.controls) {
      for (const controlName in abstractControl.controls) {
        if (abstractControl.controls[controlName]) {
          if (
            this.hasControlValidation(
              abstractControl.controls[controlName],
              validation
            )
          ) {
            return true;
          }
        }
      }
    }
    return false;
  }

  getPlaceholder(controlName: string, formGroup = this.formGroup): string {
    const control: any = formGroup[controlName];
    let placeholder = `INPUT.${controlName.toUpperCase()}.PLACEHOLDER`;
    if (!control) {
      return placeholder;
    }
    if (this.hasControlValidation(control, 'required')) {
      placeholder += '_REQUIRED';
    }
    return placeholder;
  }

  public checkControlValidity(
    formElement: string,
    validity: string = 'invalid',
    formGroup: UntypedFormGroup = this.formGroup,
    formSubmitted: any = false
  ): boolean {
    const control = formGroup.get(formElement);
    // @ts-ignore
    return control && (control.touched || formSubmitted) && control[validity];
  }

  public PasswordValidatorStrong(control: UntypedFormControl): {
    [key: string]: boolean;
  } | null {
    let hasNumber = /\d/.test(control.value);
    let hasUpper = /[A-Z]/.test(control.value);
    let hasLower = /[a-z]/.test(control.value);
    // console.log('Num, Upp, Low', hasNumber, hasUpper, hasLower);
    const valid = hasNumber && hasUpper && hasLower;
    if (!valid) {
      // return what´s not valid
      return { strong: true };
    }
    return null;
  }

  public PasswordHasUpperCase(control: UntypedFormControl): {
    [key: string]: boolean;
  } | null {
    let hasUpper = /[A-Z]/.test(control.value);
    if (!hasUpper) {
      // return what´s not valid
      return { hasUpper: true };
    }
    return null;
  }

  public PasswordHasLowerCase(control: UntypedFormControl): {
    [key: string]: boolean;
  } | null {
    let hasLower = /[a-z]/.test(control.value);
    if (!hasLower) {
      // return what´s not valid
      return { hasLower: true };
    }
    return null;
  }

  public PasswordHasNumber(control: UntypedFormControl): {
    [key: string]: boolean;
  } | null {
    let hasNumber = /\d/.test(control.value);
    if (!hasNumber) {
      // return what´s not valid
      return { hasNumber: true };
    }
    return null;
  }

  public PasswordHasSpecialChars(control: UntypedFormControl): {
    [key: string]: boolean;
  } | null {
    const nameRegexp: RegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    let hasChars = nameRegexp.test(control.value);
    if (!hasChars) {
      // return what´s not valid
      return { hasChars: true };
    }
    return null;
  }

  public PasswordHasNoWhiteSpace(control: UntypedFormControl): {
    [key: string]: boolean;
  } | null {
    if ((control.value as string).indexOf(' ') >= 0) {
      // return what´s not valid
      return { hasWhiteSpace: true };
    }
    return null;
  }

  public PasswordHasAlphaNumeric(control: UntypedFormControl): {
    [key: string]: boolean;
  } | null {
    const ALPHA_NUMERIC_REGEX = /^[a-zA-Z0-9_]*$/;
    let hasAlphaNum = ALPHA_NUMERIC_REGEX.test(control.value);
    if (!hasAlphaNum) {
      // return what´s not valid
      return { hasAlphaNum: true };
    }
    return null;
  }
}
