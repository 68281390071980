import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppExportButtonComponent } from './app-export-button.component';

@NgModule({
  declarations: [AppExportButtonComponent],
  exports: [AppExportButtonComponent],
  imports: [CommonModule],
})
export class AppExportButtonModule {}
