<div
  class="d-flex flex-row align-items-center justify-content-center purple-table-status"
>
  <div class="verification_status">
    {{ label }}
  </div>

  <ng-container
    *ngIf="checkAccessModule(FEATURES.COMPLIANCE.name, PERMISSION.WRITE)"
  >
    <ng-container *ngIf="label === 'Pending'">
      <div
        (click)="$event.stopPropagation(); onPressVerify.emit($event)"
        class="view-details-btn"
      >
        Verify
      </div>
    </ng-container>
    <ng-container *ngIf="label === 'Verified'">
      <div
        (click)="$event.stopPropagation(); onPressVerify.emit($event)"
        class="view-details-btn"
      >
        Add Info
      </div>
    </ng-container>
  </ng-container>
</div>
