import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { finalize, tap } from 'rxjs/operators';
import {
  PRICING_MODEL_SUSPEND,
  SUSPEND_TRANSACTION,
} from 'src/app/shared/constants/apis-list';
import { DataService } from 'src/app/shared/services';

@Component({
  selector: 'app-suspend-transaction-prompt-confirmation',
  templateUrl: './suspend-transaction-prompt-confirmation.component.html',
  styleUrls: ['./suspend-transaction-prompt-confirmation.component.scss'],
})
export class SuspendTransactionPromptConfirmationComponent implements OnInit {
  isLoading: boolean = false;

  transaction: any;
  isSuspend?: boolean;
  suspend?: string;

  constructor(
    private dataService: DataService,
    private toastrService: ToastrService,
    public modal: NgbActiveModal,
    public dialogRef: MatDialogRef<SuspendTransactionPromptConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      transaction: any;
      isSuspend?: boolean;
      suspend?: string;
    }
  ) {
    this.transaction = data.transaction;
    this.isSuspend = data.isSuspend;
    this.suspend = data.suspend;
  }

  ngOnInit(): void {}

  onConfirm() {
    if (this.suspend === 'transaction') {
      this.onSuspendTransaction();
    } else if (this.suspend === 'remittance') {
      this.onSuspendRemittance();
    }
  }

  private onSuspendTransaction() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.dataService
      .fetchData({
        apiUrl: SUSPEND_TRANSACTION.replace('{id}', this.transaction.id),
        method: 'PUT',
        contentType: 'application/json',
        body: null,
      })
      .pipe(
        tap((response) => {
          if (response.status == 'success' || response.status === 'OK') {
            this.modal?.dismiss();
            setTimeout(() => {
              window.location.reload();
            }, 600);
          } else {
            this.toastrService.error(response.message, response.error);
          }
        }),
        finalize(() => (this.isLoading = false))
      )
      .subscribe();
  }

  private onSuspendRemittance() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    const body = {
      platform_id: this.transaction.platform_id,
      isSuspend: this.isSuspend,
    };

    this.dataService
      .fetchData({
        apiUrl: PRICING_MODEL_SUSPEND,
        method: 'POST',
        contentType: 'application/json',
        body,
      })
      .pipe(
        tap((response) => {
          if (response.status == 'success' || response.status === 'OK') {
            this.modal?.dismiss();
            setTimeout(() => {
              window.location.reload();
            }, 600);
          } else {
            this.toastrService.error(response.message, response.error);
          }
        }),
        finalize(() => (this.isLoading = false))
      )
      .subscribe();
  }

  onCancel() {
    this.dialogRef.close();
  }
}
