import { ComponentFactoryResolver, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatMomentDateModule } from "@angular/material-moment-adapter"
import { OverlayModule } from '@angular/cdk/overlay';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';

import {
  MatDatepickerModule,
} from '@angular/material/datepicker';


import { PurpleDatePickerComponent } from './purple-date-picker/purple-date-picker.component';
import { PurpleDatePickerDialogComponent } from './purple-date-picker-dialog/purple-date-picker-dialog.component';
import { PurpleCalendarComponent } from './purple-calendar/purple-calendar.component';




@NgModule({
  declarations: [PurpleDatePickerComponent, PurpleDatePickerDialogComponent, PurpleCalendarComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatDatepickerModule,
    MatMomentDateModule,
    OverlayModule,
    MatCardModule,
    MatDividerModule,
    MatListModule,
    MatDialogModule
  ],
  exports: [PurpleDatePickerComponent]
})
export class PurpleDatePickerModule {
}
