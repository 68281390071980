import { createFeatureSelector, createSelector } from '@ngrx/store';
import { createSlice } from '@reduxjs/toolkit';
import { ApiResponseBodyInterface } from '../../interfaces/api-response.interface';

export interface CMSUsersState {
  CMSUsers: ApiResponseBodyInterface | null;
  loadingCMSUsers: boolean;
}

const initialState: CMSUsersState = {
  CMSUsers: null,
  loadingCMSUsers: false,
};

const { actions, reducer, name } = createSlice({
  name: 'CMSUsers',
  initialState,
  reducers: {
    getAllCMSUsersPending: (state) => {
      state.loadingCMSUsers = true;
    },
    getAllCMSUsersFullfilled: (state, { payload }) => {
      state.loadingCMSUsers = false;
      state.CMSUsers = payload;
    },
    getAllCMSUsersRejected: (state, { payload }) => {
      state.loadingCMSUsers = true;
    },
  },
});

const selectCMSUsersFeature =
  createFeatureSelector<ReturnType<typeof reducer>>(name);

export const selectCMSUsersLoading = createSelector(
  selectCMSUsersFeature,
  (state) => state.loadingCMSUsers
);

export const selectAllCMSUsers = createSelector(
  selectCMSUsersFeature,
  (state) => state.CMSUsers
);

export { name };
export const {
  getAllCMSUsersPending,
  getAllCMSUsersFullfilled,
  getAllCMSUsersRejected,
} = actions;
export default reducer;
