import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'purpl-export-button',
  templateUrl: './app-export-button.component.html',
  styleUrls: ['./app-export-button.component.scss'],
})
export class AppExportButtonComponent implements OnInit {
  @Input() title?: string;
  @Output() exportEv: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  exportEvent(ev: any){
    this.exportEv.emit(ev);
  }
}
