<div class="modal-header justify-content-center">
  <h1 class="modal-title text-center f-w-600" id="exampleModalLabel">
    Session Expiration Warning
  </h1>
</div>
<div class="modal-body my-3">
  Your session is about to expire due to inactivity.<br />
  Orgnization's policy enforces automatic sign out after a period of inactivity

  <br /><br />
  Redirecting in <b>{{ countdown }}s</b>.
</div>
<div class="modal-footer">
  <button
    class="btn px-4 logged-in-btn"
    type="button"
    data-dismiss="modal"
    (click)="stayLoggedIn()"
  >
    Stay Logged in
  </button>
  <button
    class="btn btn-danger px-4"
    type="button"
    data-dismiss="modal"
    (click)="handleLogout()"
  >
    Logout Now
  </button>
</div>
