import { createFeatureSelector, createSelector } from '@ngrx/store';
import { createSlice } from '@reduxjs/toolkit';
import { ApiResponseBodyInterface } from '../../interfaces/api-response.interface';

export interface SendersState {
  senders: ApiResponseBodyInterface | null;
  loadingSenders: boolean;
}

const initialState: SendersState = {
  senders: null,
  loadingSenders: false,
};

const { actions, reducer, name } = createSlice({
  name: 'senders',
  initialState,
  reducers: {
    getAllSendersPending: (state) => {
      state.loadingSenders = true;
    },
    getAllSendersFullfilled: (state, { payload }) => {
      state.loadingSenders = false;
      state.senders = payload;
    },
    getAllSendersRejected: (state, { payload }) => {
      state.loadingSenders = true;
    },
  },
});

const selectSendersFeature =
  createFeatureSelector<ReturnType<typeof reducer>>(name);

export const selectSendersLoading = createSelector(
  selectSendersFeature,
  (state) => state.loadingSenders
);

export const selectAllSenders = createSelector(
  selectSendersFeature,
  (state) => state.senders
);

export { name };
export const {
  getAllSendersPending,
  getAllSendersFullfilled,
  getAllSendersRejected,
} = actions;
export default reducer;
