import { Injectable, Injector } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { PurplBaseResolver } from 'src/app/shared/resolvers/base-resolver';
import { DataService } from 'src/app/shared/services';

@Injectable({
  providedIn: 'root',
})
export class DashboardResolverService
  extends PurplBaseResolver
  implements Resolve<any>
{
  constructor(
    injector: Injector,
    public dataService: DataService,
    private toasterService: ToastrService
  ) {
    super(injector);
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    const id: any = route.paramMap.get('id');

    if (id == 'add') {
      return null;
    } else {
      console.log('id: ', id);
      return this.dataService
        .fetchData({
          apiUrl: this.apiList.USER_DETAIL.replace('{id}', id),
          method: 'GET',
          contentType: 'application/json',
          params: null,
          body: null,
        })
        .pipe(
          map((response) => {
            if (response.status == 'success') {
              return response.data;
            } else {
              this.toasterService.error(
                'Some error occured in resolver',
                'Failed'
              );
            }
          })
        );
    }
  }
}
