<div class="page-wrapper backgrund-pic">
  <div class="authentication-box">
    <div class="container">
      <div class="row">
        <div class="col-md-5 p-0 card-left">
          <div class="card bg-primary card-setting">
            <div class="logo-wrapper text-center m-auto">
              <a class="text-center">
                <img class="brand-logo blur-up lazyloaded brand-zoom" src="assets/images/dashboard/purpl-logo.png"
                  alt="">
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-7 p-0 card-right">
          <div class="card tab2-card">
            <div class="card-body body-height">
              <div class="reset-text mb-5">
                Reset Password
              </div>
              <form [formGroup]="formGroup" class="form-horizontal auth-form" novalidate
                (keyup.enter)="formGroup.valid ? onSubmit() : null;">
                <div class="form-group d-flex flex-column">
                  <div class="input-password-group">
                    <input required name="password" [type]="hide ? 'text' : 'password'" class="form-control"
                      placeholder="Password" [formControlName]="newPasswordString">
                    <span class="eye-icon" (click)="hide = !hide"
                      [title]="hide ? 'Hide the password' : 'Show the password'">
                      <img [src]="hide ? '/assets/images/svg/eye_hide.svg' : '/assets/images/svg/eye_visible.svg'"
                        alt="password-field">
                    </span>
                  </div>
                  <ng-container *ngIf="checkControlValidity(newPasswordString,'invalid', formGroup, formSubmitted)">
                    <small class="text-danger col">
                      {{getErrorMessage(newPasswordString)}}
                    </small>
                  </ng-container>
                </div>
                <div class="form-group d-flex flex-column">
                  <div class="input-password-group">
                    <input required name="confirm-password" [type]="confirmHide ? 'text' : 'password'"
                      class="form-control" placeholder="Confirm Password" [formControlName]="confirmPasswordString">
                    <span class="eye-icon" (click)="confirmHide = !confirmHide"
                      [title]="confirmHide ? 'Hide the password' : 'Show the password'">
                      <img
                        [src]="confirmHide ? '/assets/images/svg/eye_hide.svg' : '/assets/images/svg/eye_visible.svg'"
                        alt="confirm-password-field">
                    </span>
                  </div>
                  <ng-container *ngIf="checkControlValidity(confirmPasswordString,'invalid', formGroup, formSubmitted)">
                    <small class="text-danger col">
                      {{getErrorMessage(confirmPasswordString)}}
                    </small>
                  </ng-container>
                </div>
                <div class="form-terms">
                  <div class="custom-control custom-checkbox mr-sm-2">
                    <a href="javascript:void(0)" class="btn btn-default forgot-pass"
                      [routerLink]="routeList.FORGET_PASSWORD">
                      Forget Password
                    </a>
                  </div>
                </div>
                <div class="form-button">
                  <button class="btn btn-primary" type="button" (click)="onSubmit();"
                    [disabled]="(sharedDataService.loadingBarStatus | async) || formGroup.invalid">
                    <span *ngIf="!isLoading">Reset</span>
                    <i class="fa fa-spin fa-spinner" aria-hidden="true" *ngIf="isLoading"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
