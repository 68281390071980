export const totalUsersData = [
  {
    name: 'Jan',
    series: [],
  },
  {
    name: 'Feb',
    series: [],
  },
  {
    name: 'Mar',
    series: [],
  },
  {
    name: 'Apr',
    series: [],
  },
  {
    name: 'May',
    series: [],
  },
  {
    name: 'Jun',
    series: [],
  },
  {
    name: 'Jul',
    series: [],
  },
  {
    name: 'Aug',
    series: [],
  },
  {
    name: 'Sep',
    series: [],
  },
  {
    name: 'Oct',
    series: [],
  },
  {
    name: 'Nov',
    series: [],
  },
  {
    name: 'Dec',
    series: [],
  },
];

export const DASHBOARD_FILTERS = {
  ALL_TIME: 'all_time',
  DAYS: 'days',
  MONTHS: 'months',
};

export const DASHBOARD_USERS = {
  NEW: 'New users',
  REGISTERED: 'Registered users',
  UNREGISTERED: 'Unregistered beneficiaries',
};

export const DASHBOARD_VERIFIED_USERS = {
  JUMIO: 'Verified Jumio',
  FULLY: 'Verified Fully',
  UNVERIFIED: 'Unverified',
  PENDING: 'Pending',
  FAILED: 'Failed Jumio',
  SOFT_DELETED: 'Deleted',
  BLOCKED: 'Blocked',
  BOUNCED: 'Bounced Jumio',
};

export const DASHBOARD_VERIFIED_USERS_FILTER = {
  VERIFIED_JUMIO: 'VERIFIED_JUMIO',
  VERIFIED_FULLY: 'VERIFIED_FULLY',
  UNVERIFIED: 'UNVERIFIED',
  PENDING: 'PENDING',
  FAILED: 'FAILED_JUMIO',
  BOUNCED_JUMIO: 'BOUNCED_JUMIO',
};

export const DATE_FILTERS = [
  {
    label: 'Day',
    value: 'D',
  },
  {
    label: 'Week',
    value: 'W',
  },
  {
    label: 'Month',
    value: 'M',
  },
  {
    label: 'Year',
    value: 'Y',
  },
];
