import { Component, OnInit } from '@angular/core';
import { IFloatingFilterAngularComp } from 'ag-grid-angular';
import { IFloatingFilterParams, ISimpleFilter } from 'ag-grid-community';
import { CustomParams } from '../transaction-status-search/transaction-status-search.component';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';

@Component({
  selector: 'app-partners-filter-renderer',
  templateUrl: './partners-filter-renderer.component.html',
  styleUrls: ['./partners-filter-renderer.component.scss'],
})
export class PartnersFilterRendererComponent
  implements IFloatingFilterAngularComp
{
  filterSearchText = new Subject<any>();
  filterText: string | null = null;
  eFilterText: any;
  params!: IFloatingFilterParams<ISimpleFilter> & CustomParams;

  constructor() {}

  agInit(params: IFloatingFilterParams<ISimpleFilter> & CustomParams) {
    this.filterText = null;
    this.params = params;

    this.filterSearchText
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe({
        next: (event: any) => {
          this.params?.onSearchEvent({
            value: event?.target?.value,
            filter: params?.filter,
          });
        },
      });
  }

  onParentModelChanged(parentModel: any) {
    // When the filter is empty we will receive a null value here
    if (!parentModel) {
      this.filterText = null;
    } else {
      this.filterText = parentModel.filter;
    }
  }
}
