import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  PERMISSION,
  PERMISSION_READ,
} from 'src/app/shared/constants/constant-list';
import FEATURES from 'src/app/shared/constants/navigation';
import { PermissionService } from 'src/app/shared/services';

@Component({
  selector: 'app-purple-table-status',
  templateUrl: './purple-table-status.component.html',
  styleUrls: ['./purple-table-status.component.scss'],
})
export class PurpleTableStatusComponent implements OnInit {
  public PERMISSION: any = PERMISSION;
  public FEATURES: any = FEATURES;

  @Input() label?: string;

  @Output() onPressVerify: EventEmitter<any> = new EventEmitter<any>();

  constructor(private permissionService: PermissionService) {}

  ngOnInit(): void {}

  checkAccessModule(
    moduleName: string,
    permission: string = PERMISSION_READ
  ): boolean {
    return this.permissionService.canAccessModule(moduleName, permission);
  }
}
