import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { upperCase } from 'lodash';

@Component({
  selector: 'app-transaction-detail',
  templateUrl: './transaction-detail.component.html',
  styleUrls: ['./transaction-detail.component.scss'],
})
export class TransactionDetailComponent implements OnInit {
  details?: any;
  status?: string;
  sendDate?: any;
  statusDate?: any;

  constructor(
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<TransactionDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { selectedUser: string; data: any }
  ) {}

  ngOnInit(): void {
    if (!!this.data) {
      this.details = this.data?.data;
      this.status = upperCase(this.details.status);
      this.sendDate = this.datePipe.transform(
        this.details?.datetime_created,
        'MMM d, y'
      );
      this.statusDate = this.datePipe.transform(
        this.details?.datetime_updated,
        'MMM d, y'
      );
    }
  }

  close() {
    this.dialogRef.close();
  }
}
