import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Brand } from '../../interfaces/Brands';
import { createSlice } from '@reduxjs/toolkit';

export interface BrandState {
  brands: Brand[];
  size: number;
  page: number;
  totalItems: number;
  loadingBrands: boolean;
}

const initialState: BrandState = {
  brands: [],
  size: 0,
  page: 0,
  totalItems: 0,
  loadingBrands: false,
};

const { actions, reducer, name } = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    getAllBrandsPending: (state) => {
      state.loadingBrands = true;
    },
    getAllBrandsFullfilled: (state, { payload }) => {
      state.loadingBrands = false;
      state.brands = payload.items;
      state.page = payload.number;
      state.size = payload.size;
      state.totalItems = payload.total_elements;
    },
    getAllBrandsRejected: (state, { payload }) => {
      state.loadingBrands = false;
    },
  },
});

const selectBrandFeature =
  createFeatureSelector<ReturnType<typeof reducer>>(name);

export const selectBrandsLoading = createSelector(
  selectBrandFeature,
  (state) => state.loadingBrands
);

export const selectAllBrands = createSelector(
  selectBrandFeature,
  (state) => state.brands
);

export { name };
export const {
  getAllBrandsPending,
  getAllBrandsFullfilled,
  getAllBrandsRejected,
} = actions;
export default reducer;
