import { createFeatureSelector, createSelector } from '@ngrx/store';
import { createSlice } from '@reduxjs/toolkit';
import { ApiResponseBodyInterface } from '../../interfaces/api-response.interface';

export interface ComplianceState {
  compliances: ApiResponseBodyInterface | null;
  compliance_status: any[];
  compliance_status_filter: any | null;
  loadingCompliance: boolean;
}

const initialState: ComplianceState = {
  compliances: null,
  compliance_status: [],
  compliance_status_filter: null,
  loadingCompliance: false,
};

const { actions, reducer, name } = createSlice({
  name: 'compliances',
  initialState,
  reducers: {
    setComplianceStatus: (state, { payload }) => {
      state.compliance_status = payload;
    },
    setComplianceStatusFilter: (state, { payload }) => {
      state.compliance_status_filter = payload;
    },
    getAllCompliancePending: (state) => {
      state.loadingCompliance = true;
    },
    getAllComplianceFullfilled: (state, { payload }) => {
      state.loadingCompliance = false;
      state.compliances = payload;
    },
    getAllComplianceRejected: (state, { payload }) => {
      state.loadingCompliance = true;
    },
  },
});

const selectComplianceFeature =
  createFeatureSelector<ReturnType<typeof reducer>>(name);

export const selectComplianceLoading = createSelector(
  selectComplianceFeature,
  (state) => state.loadingCompliance
);

export const selectAllCompliances = createSelector(
  selectComplianceFeature,
  (state) => state.compliances
);

export const selectComplianceStatus = createSelector(
  selectComplianceFeature,
  (state) => state.compliance_status
);

export const selectComplianceStatusFilter = createSelector(
  selectComplianceFeature,
  (state) => state.compliance_status_filter
);

export { name };
export const {
  getAllCompliancePending,
  getAllComplianceFullfilled,
  getAllComplianceRejected,
  setComplianceStatus,
  setComplianceStatusFilter,
} = actions;
export default reducer;
