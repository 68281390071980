import {
  Component,
  ViewEncapsulation,
  Injector,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnDestroy,
  ElementRef,
  NgZone,
  HostListener,
} from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription, timer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {
  NgbModalOptions,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import * as CONST_LIST from '../../constants/constant-list';
import * as ROUTE_LIST from '../../constants/routes-list';
import * as API_LIST from '../../constants/apis-list';
import {
  UserService,
  DataListingService,
  HelperService,
  SharedDataService,
  DataService,
  PurplTranslationLoaderService,
  PermissionService,
} from '../../services';
import { BaseModel } from '../../interfaces/BaseModel';
import { environment } from '../../../../environments/environment';
import FEATURES from '../../constants/navigation';

/*
 * Base Component
 * Top Level Component
 */
@Component({
  selector: 'app-base-component',
  encapsulation: ViewEncapsulation.None,
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseComponent implements OnDestroy {
  public FAILED_STATUS = true;
  public SUCCESS_STATUS = false;
  public layoutDirection: any = 'ltr';
  public ERROR_MSG: any;
  public constantList = CONST_LIST;
  public isAllowView = false;
  public isAllowEdit = false;
  // kept public as its used in templates as well
  public routeList = ROUTE_LIST;
  public apiList = API_LIST;
  public router: Router;
  public route: ActivatedRoute;
  public userService: UserService;
  public dataService: DataService;
  public dataListService: DataListingService;
  public location: Location;
  public ngZone: NgZone;
  public helperService: HelperService;
  public permissionService: PermissionService;
  public cd: ChangeDetectorRef | any;
  public sharedDataService: SharedDataService;
  public translationLoader: PurplTranslationLoaderService;
  public translate: TranslateService;
  public toastrService: ToastrService;
  public modalService: NgbModal;
  public openedModalRef!: NgbModalRef;
  
  public postBody: any = {};
  public showItemLimit = 3;
  public appEnvironment = environment;
  public sanitizer: DomSanitizer;
  public PERMISSION: any = CONST_LIST.PERMISSION;
  public FEATURES: any = FEATURES;

  /**
   * the following boolean make sure to disable the button once the form is submitted
   * @type {boolean}
   */
  private _isFormSubmitted = false;
  /**
   * the following variable will allow us to downcast this instance to respective child instance whenever required
   */
  public baseModel: any = null;

  /**
   * the following holds reference to the subscriptions,
   * which needs to be unsubscribe when respective component is destroyed from view
   */
  protected dataSubscription$!: Subscription;
  protected tableDataSubscription$!: Subscription;
  protected translationSubscription$!: Subscription;
  protected dropdownListSubscriptions$: Subscription[] = [];
  protected formControlValuesSubscriptions$: Subscription[] = [];

  /**
   * the following is used to keep the page id based on for edit/detail/view screen
   */
  public pageIdParam: any = 'id';

  /**
   * the following is used to keep the soring & searching details for table
   */
  public searchText: null | string = null;
  public sortColumn = '';
  public sortOrder = '';
  public sortColumnType = 'string';

  constructor(injector: Injector) {
    this.router = injector.get(Router);
    this.cd = injector.get(ChangeDetectorRef);
    this.location = injector.get(Location);
    this.route = injector.get(ActivatedRoute);
    this.ngZone = injector.get(NgZone);
    this.userService = injector.get(UserService);
    this.dataService = injector.get(DataService);
    this.dataListService = injector.get(DataListingService);
    this.baseModel = new BaseModel();
    this.helperService = injector.get(HelperService);
    this.permissionService = injector.get(PermissionService);
    this.sharedDataService = injector.get(SharedDataService);
    this.translate = injector.get(TranslateService);
    this.toastrService = injector.get(ToastrService);
    this.modalService = injector.get(NgbModal);
    this.translationLoader = injector.get(PurplTranslationLoaderService);
    this.sanitizer = injector.get(DomSanitizer);
  }

  ngOnDestroy() {
    // this.cd.detach();
    if (this.dataSubscription$) {
      this.dataSubscription$.unsubscribe();
    }
    if (this.tableDataSubscription$) {
      this.tableDataSubscription$.unsubscribe();
    }
    if (this.translationSubscription$) {
      this.translationSubscription$.unsubscribe();
    }
    if (this.dropdownListSubscriptions$.length > 0) {
      this.dropdownListSubscriptions$.forEach((s) => {
        s.unsubscribe();
      });
      this.dropdownListSubscriptions$ = [];
    }
    if (this.formControlValuesSubscriptions$.length > 0) {
      this.formControlValuesSubscriptions$.forEach((s) => {
        s.unsubscribe();
      });
      this.formControlValuesSubscriptions$ = [];
    }
  }

  /**
   * To get the FormSubmitted Value
   * @returns {boolean}
   */
  get isFormSubmitted(): boolean {
    return this._isFormSubmitted;
  }

  /**
   * To set the FormSubmitted Value
   * @param {boolean} value
   */
  set isFormSubmitted(value: boolean) {
    // updating the shared variable that is shared across the app, even by those which do not inherit from Foodak Base Component
    if (value !== this._isFormSubmitted) {
      setTimeout(() => {
        this.helperService.isFormSubmittedSharedVariable = value;
        this._isFormSubmitted = value;
      }, 500);
    }
  }

  /**
   * The following method is used to take the user to a link, with delay if given
   * @param {string} link
   * @param {number} delay
   */
  goToWithoutConfirmation(link: string, delay?: number): void {
    if (delay) {
      timer(delay).subscribe(() => {
        this.router.navigateByUrl(link).then();
      });
    } else {
      this.router.navigateByUrl(link).then();
    }
  }

  /**
   * The following method is used to take the user to a link, with delay if given
   * @param {string} link
   * @param {number} showDialog
   */
  goTo(link: string, showDialog: boolean = true): void {
    if (showDialog) {
      /*this.openConfirmationDialog({ message: 'COMPONENTS.CONFIRMATION_DIALOG.TEXT.MSG5' })
        .result.then((bool: boolean) => {
          if (bool) {
            this.router.navigateByUrl(link)
              .then()
              .catch();
          }
        }).catch(reason => { });*/
      /*const bool: boolean = confirm('COMPONENTS.CONFIRMATION_DIALOG.TEXT.MSG5');
      if (bool) {
        this.router.navigateByUrl(link)
          .then()
          .catch();
      }*/
    } else {
      this.router.navigateByUrl(link).then().catch();
    }
  }

  /**
   * TODO: This should be implemented from angular bootstrap toast
   * The following method is used to show the toastr with respective message
   * @param message
   * @param type
   */
  showToastWithMessage(message: any, type: string = 'success'): void {
    this.toastrService.clear();
    switch (type) {
      case 'error':
        this.translate.get('GENERAL.TEXT.ERROR').subscribe((msg) => {
          this.toastrService.error(`${msg}: ` + message);
        });
        break;
      case 'warning':
        this.translate.get('GENERAL.TEXT.WARNING').subscribe((msg) => {
          this.toastrService.warning(`${msg}: ` + message);
        });
        break;
      case 'info':
        this.toastrService.info('Info: ' + message);
        break;
      default:
        this.translate.get('GENERAL.TEXT.SUCCESS').subscribe((msg) => {
          this.toastrService.success(`${msg}: ` + message);
        });
        break;
    }
  }

  /**
   * The following check if its edit mode
   * @returns {string | null}
   */
  isEditMode(param: string = this.pageIdParam): string | null {
    return this.route.snapshot.paramMap.get(param);
  }

  statusToggleChanged(event: any, field: string = 'active'): void {
    this.baseModel[field] = event.checked;
    // getting div > span > strong element to set the updated text
    event.source._elementRef.nativeElement.parentElement.children[0].children[0].innerText =
      event.checked ? 'Active' : 'Inactive';
  }

  /**
   *  the following method is used to update the success response
   * @param {string} message
   * @param {string} redirectUrl
   */
  protected isSuccessful(message?: string, redirectUrl?: string): void {
    this.FAILED_STATUS = false;
    this.SUCCESS_STATUS = true;
    this.ERROR_MSG = [];
    this.isFormSubmitted = false;
    // if message given show the success bar
    if (message) {
      this.showToastWithMessage(message);
    }

    // if redirection URL given
    if (redirectUrl) {
      this.goToWithoutConfirmation(
        redirectUrl,
        this.constantList.DEFAULT_REDIRECTION_WAIT_TIME
      );
    }
  }

  /**
   * The following method is used to update the failure response
   * @param {any[]} errorMessage
   */
  protected isFailure(errorMessage: string[]): void {
    this.FAILED_STATUS = true;
    this.SUCCESS_STATUS = false;
    this.ERROR_MSG = errorMessage;
    this.isFormSubmitted = false;
  }

  cancel() {
    this.location.back();
  }

  canAccessModule(
    moduleName: string,
    permission: string = this.constantList.PERMISSION_READ
  ): void {
    if (!this.permissionService.canAccessModule(moduleName, permission)) {
      this.redirectToLandingPageByRole();
    }
  }

  checkAccessModule(
    moduleName: string,
    permission: string = this.constantList.PERMISSION_READ
  ): boolean {
    return this.permissionService.canAccessModule(moduleName, permission);
  }

  redirectToLandingPageByRole(roleName?: string | undefined) {
    this.sharedDataService.changeFormSubmitStatus(false);
    this.goToWithoutConfirmation(this.routeList.DASHBOARD);
  }

  /**
   * the following method is used to get the translated text from
   * the respective object of the given key
   * @param data
   * @param key
   */
  getTranslatedText(data: any, key: string) {
    if (!data) {
      return '';
    }
    const text = data[`${key}_${this.translate.currentLang}`];
    return text || data[key] || '';
  }

  getChunkArray(array: any[], chunkSize: number = 2): any[] {
    if (!array) {
      return array;
    }

    const chunkedMaps = [];
    const tmpArray = Array.from(array);
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunked = tmpArray.slice(i, i + chunkSize);
      chunkedMaps.push(chunked);
    }

    return chunkedMaps;
  }

  public get currentTimestamp() {
    return new Date().getTime();
  }

  public getFileName(fieldName: string): string {
    let name = '';
    if (this.baseModel[`${fieldName}_data`]) {
      const image = this.baseModel[`${fieldName}_data`];
      name = image && image.file ? image.file.name : '';
    } else {
      name = this.baseModel[`${fieldName}_name`] || '';
    }
    return name;
  }

  truncateString(str: any, num: number, concatenat: string = '...'): string {
    // If the length of str is less than or equal to num
    // just return str--don't truncate it.
    if (!str || str.length <= num) {
      return str || '';
    }
    // Return str truncated with concatenated to the end of str.
    return str.slice(0, num) + concatenat;
  }

  public getFirstLetterFromWord(
    str: string | null,
    count: number | null = null
  ): string {
    return HelperService.getFirstLetterFromWord(str, count);
  }

  public showModal(content: any, options?: NgbModalOptions) {
    const defaultConfig: NgbModalOptions = {
      centered: true,
      scrollable: true,
      backdrop: 'static',
      keyboard: false,
    };
    if (options && Object.keys(options).length > 0) {
      Object.assign(defaultConfig, options);
    }
    this.openedModalRef = this.modalService.open(content, defaultConfig);
    return this.openedModalRef;
  }

  public openConfirmationDialog(
    options: {
      data?: any;
      title?: string | null;
      message?: string | null;
      noButtonText?: string;
      yesButtonText?: string;
    } = {
      data: null,
      title: 'COMPONENTS.CONFIRMATION_DIALOG.TEXT.CONFIRMATION',
      noButtonText: 'COMPONENTS.CONFIRMATION_DIALOG.TEXT.NO',
      yesButtonText: 'COMPONENTS.CONFIRMATION_DIALOG.TEXT.YES',
      message: 'COMPONENTS.CONFIRMATION_DIALOG.TEXT.MSG',
    }
  ) {
    /*const modalRef = this.showModal(ConfirmationDialogComponent, { windowClass: 'popup-40' });
    if (options) {
      Object.assign(modalRef.componentInstance.options, options);
    }
    return modalRef;*/
  }

  public openImageCroperDialog(
    options: {
      file_size_error?: string | null;
      title?: string | null;
      sub_title?: string | null;
      file_size_limit?: number | null;
      aspect_ratio?: number | null;
    } = {
      file_size_error: null,
      title: null,
      sub_title: null,
      file_size_limit: null,
      aspect_ratio: null,
    }
  ) {
    /*const modalRef = this.showModal(ImageCroperDialogComponent, { windowClass: 'popup-40' });
    if (options) {
      Object.assign(modalRef.componentInstance.options, options);
    }
    return modalRef;*/
  }

  public closeOpenedModal(
    modalRef: NgbModalRef | undefined = this.openedModalRef
  ): void {
    if (modalRef) {
      modalRef.dismiss(null);
    }
  }

  public setPictureInModel(model: any, file: File, field: string): void {
    HelperService.imageFileToDataURI(file, (base64Image: any) => {
      model[field] = {
        file,
        base64Image,
        formattedBase64Image: HelperService.formatBase64Image(base64Image),
      };
    });
  }

  public clearFileInput(fileElementRef: ElementRef | undefined): void {
    if (
      fileElementRef &&
      fileElementRef.nativeElement.files &&
      Array.isArray(fileElementRef.nativeElement.files) &&
      fileElementRef.nativeElement.files.length > 0
    ) {
      fileElementRef.nativeElement.value = null;
    }
  }

  public toggleTableColumnSorting(
    column: string = '',
    columnDataType: string = 'string',
    reset: boolean = false
  ): void {
    this.sortColumn = column;
    this.sortColumnType = columnDataType;
    this.sortOrder = reset ? '' : this.sortOrder == 'asc' ? 'desc' : 'asc';
    if (reset) {
      this.sortColumnType = '';
    }
  }

  public onSearchTextChanged(value: any): void {
    this.searchText = value || null;
  }

  public resetTableFilters(): void {
    this.onSearchTextChanged(null);
    this.toggleTableColumnSorting('', undefined, true);
  }

  public getFormattedText(field: any, defaultValue: string = ' - '): string {
    return field || defaultValue;
  }

  public setInputValueInModel(model: any, field: string, value: any): void {
    if (model && field) {
      model[field] = value;
    }
  }

  public getFormattedDateTime(
    value: string | Date,
    format: string = this.constantList.DEFAULT_DATETIME_FORMAT
  ): any {
    if (value) {
      return moment(value).format(format.toUpperCase());
    }
    return value;
  }

  refresh(): void {
    window.location.reload();
  }

  getEnLanguage(): boolean {
    return this.translate.currentLang === 'en' ? true : false;
  }
}
