import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HelperService } from 'src/app/shared/services';

@Component({
  selector: 'app-brand-manager-dashboard-all-users',
  templateUrl: './brand-manager-dashboard-all-users.component.html',
  styleUrls: ['./brand-manager-dashboard-all-users.component.scss'],
})
export class BrandManagerDashboardAllUsersComponent implements OnInit {
  @Input() tableColumns: any[] = [];
  @Input() tableDataListItems: any[] = [];
  @Input() pageLimit?: number;
  @Input() page?: number;
  @Input() collectionSize?: number;
  @Input() hasMore?: boolean;
  @Input() recentlyVisited = false;
  @Input() filters = false;
  @Input() hideFilter = false;

  @Output() onPageLimitChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPageChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() bookedSpotsCount: EventEmitter<any> = new EventEmitter<any>();
  @Output() cashoutBalance: EventEmitter<any> = new EventEmitter<any>();
  @Output() exportDetails: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickedOnRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() moreDetailsButtonClicked: EventEmitter<any> =
    new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  constructNestedObject(
    element: any,
    column: {
      key: string;
      value: string;
      type: string;
      map: any;
      callback: Function;
    }
  ) {
    return HelperService.constructNestedObject(element, column);
  }

  moreDetails(data: any) {
    this.clickedOnRow.emit(data);
    this.moreDetailsButtonClicked.emit(data);
  }
}
