import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  filter,
  find,
  includes,
  isEmpty,
  map as lodashMap,
  toUpper,
  upperCase,
} from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs';
import { ListingBaseComponent } from 'src/app/shared/components/base/listing-base.component';
import {
  USER_DETAIL,
  VERIFICATION_IMAGES,
} from 'src/app/shared/constants/apis-list';
import {
  ComplianceCredentials,
  ComplianceVerifications,
  CredentialsSubType,
  CredentialsType,
  KYCType,
} from 'src/app/shared/interfaces/Compliance';
import { IUser } from 'src/app/shared/interfaces/User';

@Component({
  selector: 'app-brand-manager-dashboard-user-details',
  templateUrl: './brand-manager-dashboard-user-details.component.html',
  styleUrls: ['./brand-manager-dashboard-user-details.component.scss'],
})
export class BrandManagerDashboardUserDetailsComponent
  extends ListingBaseComponent
  implements OnInit
{
  transaction: any;
  using_jumio_v2?: boolean;
  action: string;

  jumio: any;

  @Input() public verificationImages: any = {
    front: null,
    back: null,
    face: null,
  };
  @Input() public lgImage: any = { img: null, class: null };

  public jumioUser: any;

  public kycImages: ComplianceCredentials[] = [];
  public livenessImages: ComplianceCredentials[] = [];

  public identity: ComplianceVerifications | null = null;
  public identification?: ComplianceVerifications | null = null;

  user_id?: string;
  userCode?: string = '';

  constructor(
    injector: Injector,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      transaction: any;
      using_jumio_v2: boolean;
      jumio: any;
      action: string;
      userCode: string;
    }
  ) {
    super(injector);

    this.transaction = data.transaction;
    this.action = data.action;
  }

  ngOnInit(): void {
    if (this.action === 'accepted') {
      this.transaction = this.data.transaction;
    }

    if (this.transaction) {
      this.dataService
        .fetchData({
          apiUrl: USER_DETAIL.replace('{id}', this.transaction?.user?.id),
          method: 'GET',
          contentType: 'application/json',
          params: null,
          body: null,
        })
        .pipe(map((response) => response.data))
        .subscribe((response: IUser) => {
          if (response && response?.device) {
            this.userCode = response?.device?.user_code;
            this.loadVerification(response?.device?.user_code);
          }
        });
    }
  }

  copyText(copyText: string): void {
    try {
      navigator.clipboard.writeText(copyText);
      this.toastr.success('Copied to clipboard');
    } catch (error) {
      this.toastr.error('Error while copying text to clipboard');
    }
  }

  private loadVerification(userCode: string) {
    this.dataService
      .fetchData({
        apiUrl: VERIFICATION_IMAGES.replace('{userCode}', userCode),
        method: 'GET',
        contentType: 'application/json',
      })
      .pipe(map((response) => (response && response ? response : null)))
      .subscribe((response: any) => {
        if (response && !isEmpty(response?.results)) {
          const identification = find(response?.results, {
            type: KYCType.IDENTIFICATION,
          }) as ComplianceVerifications;
          const identity = find(response?.results, {
            type: KYCType.IDENTITY,
          }) as ComplianceVerifications;

          if (identification && identification?.verified_data) {
            this.identification = identification;

            this.jumioUser = identification?.verified_data.extracted_data;

            if (this.jumioUser) {
              const result = identification?.decision?.result;

              if (result) {
                this.jumio = {
                  jumio_verification_status:
                    result === 'PASSED' ? 'Pass' : result,
                };
              }
            }

            const credentials = identification.verified_data?.credentials;
            this.kycImages = [...(!isEmpty(credentials) ? credentials : [])];
          }

          if (identity && identity.verified_data) {
            this.identity = identity;
            const credentials = identity.verified_data?.credentials;
            this.kycImages = [...(!isEmpty(credentials) ? credentials : [])];
          }

          if (!isEmpty(this.kycImages)) {
            const face = find(this.kycImages, {
              subtype: CredentialsSubType.FACE,
              type: CredentialsType.SELFIE,
            }) as ComplianceCredentials;
            const idFront = find(this.kycImages, {
              subtype: CredentialsSubType.FRONT,
              type: CredentialsType.ID,
            }) as ComplianceCredentials;
            const original = find(this.kycImages, {
              subtype: CredentialsSubType.ORIGINAL_FRONT,
              type: CredentialsType.ID,
            }) as ComplianceCredentials;
            const photo = find(this.kycImages, {
              subtype: CredentialsSubType.PHOTO,
              type: CredentialsType.ID,
            }) as ComplianceCredentials;
            const idBack = find(this.kycImages, {
              subtype: CredentialsSubType?.BACK,
              type: CredentialsType?.ID,
            }) as ComplianceCredentials;
            this.livenessImages = filter(this.kycImages, (i) => {
              return includes(i.subtype, CredentialsSubType.LIVENESS);
            }) as ComplianceCredentials[];

            if (face) {
              this.mapVerificationImage(face?.image, CredentialsSubType.FACE);
            }

            if (idFront) {
              this.mapVerificationImage(
                idFront?.image,
                CredentialsSubType.FRONT
              );
            }

            if (original) {
              this.mapVerificationImage(
                original?.image,
                CredentialsSubType.ORIGINAL_FRONT
              );
            }

            if (photo) {
              this.mapVerificationImage(photo?.image, CredentialsSubType.PHOTO);
            }

            if (idBack) {
              this.mapVerificationImage(
                idBack?.image,
                CredentialsSubType?.BACK
              );
            }
          }
        }
      });
  }

  private mapVerificationImage(url: string, type: string) {
    if (type === CredentialsSubType.FRONT) {
      this.verificationImages.front = url;
      this.lgImage.img = this.verificationImages.front;
      this.lgImage.class = 'lg-verification-img-cover';
    } else if (type === CredentialsSubType.BACK) {
      this.verificationImages.back = url;
    } else if (type === CredentialsSubType.FACE) {
      this.verificationImages.face = url;
    } else if (type === CredentialsSubType.ORIGINAL_FRONT) {
      this.verificationImages.original = url;
    } else if (type === CredentialsSubType.PHOTO) {
      this.verificationImages.photo = url;
    }
  }

  getVerificationStatus(): boolean {
    if (this.identification && this.identity) {
      const identification = this.identification?.decision?.result ?? '';
      const identity = this.identity?.decision?.result ?? '';

      return identification === 'passed' && identity === 'passed';
    }

    return false;
  }

  getIDStatus(details: any) {
    return upperCase(this.identification?.decision?.result) ?? '---';
  }

  getIdentityStatus(details: any) {
    return this.identity?.decision?.result === 'passed' ? 'Verified' : '---';
  }

  getFirstName(details: any) {
    return toUpper(details?.first_name) ?? '---';
  }

  getLastName(details: any) {
    return toUpper(details?.last_name) ?? '---';
  }

  getDateOfBirth(details: any) {
    return toUpper(details?.date_of_birth) ?? '---';
  }

  getIssuingCountry(details: any) {
    return toUpper(details?.issuing_country) ?? '---';
  }

  getIDType(details: any) {
    return toUpper(details?.type) ?? '---';
  }

  getIDSubType(details: any) {
    return toUpper(details?.sub_type) ?? '---';
  }

  getDocumentNumber(details: any) {
    return toUpper(details?.document_number) ?? '---';
  }

  getExpiryDate(details: any) {
    return toUpper(details?.expiry_date) ?? '---';
  }

  getScanReference() {
    if (this.using_jumio_v2) {
      return this.jumio?.workflow_id;
    } else {
      return this.jumio?.scan_reference;
    }
  }

  // private loadJumioDetails(id: number) {
  //   this.dataService
  //     .fetchData({
  //       apiUrl: this.apiList.JUMIO_USER_DETAILS.replace('{id}', String(id)),
  //       method: 'GET',
  //       contentType: 'application/json',
  //       params: null,
  //       body: null,
  //     })
  //     .pipe(
  //       map((response) => {
  //         if (response.status == 'success') {
  //           return response?.data ?? null;
  //         } else if (response.status == 'failed') {
  //           return response;
  //         }
  //       }),
  //       finalize(() => {})
  //     )
  //     .subscribe((response) => {
  //       if (response && response.response) {
  //         response.response = JSON.parse(response.response);
  //         if (response.response.livenessImages) {
  //           response.response.livenessImages = JSON.parse(
  //             response?.response?.livenessImages
  //           );
  //         }
  //         if (response.response.idSubtype) {
  //           response.response.idSubtype =
  //             response?.response?.idSubtype.replaceAll('_', ' ');
  //         }
  //         if (response.response.verificationStatus == 'APPROVED_VERIFIED') {
  //           response.response.identityVerification = JSON.parse(
  //             response?.response?.identityVerification
  //           );
  //           if (!response.response.identityVerification.validity) {
  //             response.response.identityVerification.reason =
  //               response.response.identityVerification.reason.replaceAll(
  //                 '_',
  //                 ' '
  //               );
  //           }
  //         }
  //         this.jumio = response;
  //         if (this.jumio.response.idScanImage) {
  //           this.loadImage(this.jumio.response.idScanImage, 'front');
  //         }
  //         if (this.jumio.response.idScanImageBackside) {
  //           this.loadImage(this.jumio.response.idScanImageBackside, 'back');
  //         }
  //         if (this.jumio.response.idScanImageFace) {
  //           this.loadImage(this.jumio.response.idScanImageFace, 'face');
  //         }
  //         if (this.jumio.response.livenessImages) {
  //           this.loadImage(this.jumio.response.livenessImages[0], 'liveness');
  //         }
  //       } else if (response.message == 'invalid.user.jumio.verfication') {
  //         this.toastrService.error(
  //           'No data from JUMIO against this user.',
  //           'Failed'
  //         );
  //       }
  //     });
  // }

  // private loadJumioDetailsV2(id: number) {
  //   this.dataService
  //     .fetchData({
  //       apiUrl: JUMIO_USER_DETAILS_V2.replace('{id}', String(id)),
  //       method: 'GET',
  //       contentType: 'application/json',
  //       params: null,
  //       body: null,
  //     })
  //     .pipe(map((response) => response?.data ?? null))
  //     .subscribe((response: any) => {
  //       if (response) {
  //         this.jumio = response;

  //         if (response.data && !isUndefined(response.data[0])) {
  //           this.jumioUser = response.data[0];

  //           if (this.jumioUser && this.using_jumio_v2) {
  //             const result = find(this.mapJumioV2Usabilities(), {
  //               category: 'ID',
  //             });
  //             if (result) {
  //               this.jumio = {
  //                 ...this.jumio,
  //                 jumio_verification_status:
  //                   result.type === 'PASSED' ? 'Pass' : result.type,
  //               };
  //             }
  //           }
  //         }

  //         if (!isEmpty(response?.images)) {
  //           const images = response?.images;
  //           const liveness = images?.liveness;

  //           if (images?.id_front) {
  //             this.loadJumioV2Image(images?.id_front, 'front');
  //           }

  //           if (images?.id_back) {
  //             this.loadJumioV2Image(images?.id_back, 'back');
  //           }
  //           if (images?.face) {
  //             this.loadJumioV2Image(images?.face, 'face');
  //           }

  //           if (liveness[0]) {
  //             this.loadJumioV2Image(liveness[0], 'liveness');
  //           }

  //           if (liveness[1]) {
  //             this.loadJumioV2Image(liveness[1], 'one');
  //           }
  //           if (liveness[2]) {
  //             this.loadJumioV2Image(liveness[2], 'one');
  //           }
  //           if (liveness[3]) {
  //             this.loadJumioV2Image(liveness[3], 'two');
  //           }
  //           if (liveness[4]) {
  //             this.loadJumioV2Image(liveness[4], 'three');
  //           }
  //         }
  //       } else if (response.message === 'invalid.user.jumio.verfication') {
  //         this.jumio = {};
  //         this.verificationImages = { front: null, back: null, face: null };
  //         this.lgImage = { img: null, class: null };
  //         this.toastrService.error(
  //           'No data from JUMIO against this user.',
  //           'Failed'
  //         );
  //       } else {
  //         this.jumio = {};
  //         this.verificationImages = { front: null, back: null, face: null };
  //         this.lgImage = { img: null, class: null };
  //         this.toastrService.error(
  //           'No data from JUMIO against this user.',
  //           'Failed'
  //         );
  //       }
  //     });
  // }

  // private mapJumioV2Usabilities() {
  //   const usabilities = this.jumio?.usabilities;
  //   if (!isEmpty(usabilities)) {
  //     const result = lodashMap(usabilities, (item: any) => {
  //       return {
  //         ...item.credentials[0],
  //         ...item.decision,
  //       };
  //     });
  //     return result;
  //   }
  //   return [];
  // }

  // loadImage(url: string, type: string) {
  //   this.dataService
  //     .getImage(this.apiList.JUMIO_USER_IMAGE, url)
  //     .subscribe((response) => {
  //       const objectURL = URL.createObjectURL(response.body);
  //       const img = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  //       if (type == 'front') {
  //         this.verificationImages.front = img;
  //         this.lgImage.img = this.verificationImages.front;
  //         this.lgImage.class = 'lg-verification-img-cover';
  //       } else if (type == 'back') {
  //         this.verificationImages.back = img;
  //       } else if (type == 'face') {
  //         this.verificationImages.face = img;
  //       } else if (type == 'liveness') {
  //         this.verificationImages.liveness = img;
  //       }
  //     });
  // }

  // private loadJumioV2Image(url: string, type: string) {
  //   this.dataService
  //     .getImageV2(JUMIO_USER_DETAILS_IMAGE_V2.replace('{encodedUrl}', url))
  //     .subscribe((response: any) => {
  //       const objectURL = URL.createObjectURL(response.body);
  //       const img = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  //       if (type === 'front') {
  //         this.verificationImages.front = img;
  //         this.lgImage.img = this.verificationImages.front;
  //         this.lgImage.class = 'lg-verification-img-cover';
  //       } else if (type === 'back') {
  //         this.verificationImages.back = img;
  //       } else if (type === 'face') {
  //         this.verificationImages.face = img;
  //       } else if (type === 'liveness') {
  //         this.verificationImages.liveness = img;
  //       } else if (type === 'one') {
  //         this.verificationImages.one = img;
  //       } else if (type === 'two') {
  //         this.verificationImages.two = img;
  //       } else if (type === 'three') {
  //         this.verificationImages.three = img;
  //       } else if (type === 'four') {
  //         this.verificationImages.four = img;
  //       }
  //     });
  // }

  // if (this.using_jumio_v2) {
  //   this.loadJumioDetailsV2(this.transaction.user.id);
  // } else {
  //   if (this.action == 'accepted') {
  //     this.transaction = this.data.transaction;
  //   } else {
  //     this.loadJumioDetails(this.transaction.user.id);
  //   }
  // }
}
