<app-breadcrumb hasBackButton="true"></app-breadcrumb>
<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row m-t-20">
    <div class="col-12">
      <div class="card tab2-card">
        <div class="card-body tab2-card">
          <form
            [formGroup]="form"
            class="needs-validation user-add"
            novalidate=""
            autocomplete="off"
            [ngClass]="{ 'pointer-events': loading }"
          >
            <div class="pb-5">
              <h5 class="float-left">User Details</h5>
              <div
                class="custom-control custom-switch float-right"
                [title]="
                  form?.get('active')?.value
                    ? 'Deactivate the record'
                    : 'Activate the record'
                "
              >
                <input
                  type="checkbox"
                  fromControlName="active"
                  class="custom-control-input"
                  id="toggle"
                  (change)="onStatusChange()"
                  [checked]="form?.get('active')?.value"
                />
                <label class="custom-control-label" for="toggle">
                  {{ form?.get("active")?.value ? "Active" : "Inactive" }}
                </label>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-12 d-flex p-0">
                <label for="image" class="col-xl-2 col-md-2 my-auto">
                  Profile Picture
                </label>
                <div class="col-xl-7 col-md-7 p-l-0">
                  <div class="box-input-file">
                    <img
                      class="imgUpload img-placeholder-width"
                      alt=""
                      [src]="image.img"
                    />
                    <button
                      type="button"
                      class="btn btn-primary m-l-20"
                      [title]="'Upload the picture'"
                      (click)="fileElement.click()"
                    >
                      Upload
                    </button>
                    <input
                      class="form-control d-none"
                      id="image"
                      type="file"
                      #fileElement
                      (change)="onImageSelected($event)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-5 d-flex p-0">
                <label for="firstName" class="col-xl-5 col-md-5 my-auto"
                  ><span>*</span> First Name</label
                >
                <input
                  (input)="onFirstNameChange($event.target.value)"
                  fromControlName="firstName"
                  [value]="editDetails?.first_name"
                  class="form-control col-xl-7 col-md-7"
                  id="firstName"
                  type="text"
                  required
                />
              </div>
              <div class="col-5 d-flex p-0">
                <label for="lastName" class="pl-3 col-xl-5 col-md-5 my-auto">
                  <span class="pl-4">*</span> Last Name
                </label>
                <input
                  (input)="onLastNameChange($event.target.value)"
                  fromControlName="lastName"
                  [value]="editDetails?.last_name"
                  class="form-control col-xl-7 col-md-7"
                  id="lastName"
                  type="text"
                  required
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-5 d-flex p-0">
                <div class="col-xl-5 col-md-5 my-auto">
                  <label for="email"><span>*</span> Email</label>
                </div>
                <div class="col-xl-7 col-md-7 d-flex p-0">
                  <input
                    (input)="onEmailChange($event.target.value)"
                    fromControlName="email"
                    autocomplete="off"
                    [value]="editDetails?.email"
                    class="form-control"
                    id="email"
                    type="email"
                    required
                  />
                </div>
              </div>

              <div class="col-5 d-flex p-0">
                <div class="col-xl-5 col-md-5 p-0 my-auto">
                  <label for="password" class="pl-3">
                    <span class="pl-4">*</span> Password
                  </label>
                </div>
                <div class="col-xl-7 col-md-7 d-flex p-0">
                  <div class="input-group has-validation">
                    <input
                      (input)="onPasswordChange($event.target.value)"
                      autocomplete="off"
                      fromControlName="password"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid': checkControlValidity(
                          'password',
                          'invalid',
                          form,
                          true
                        )
                      }"
                      id="password"
                      [type]="hide ? 'text' : 'password'"
                      required
                      [placeholder]="
                        editDetails ? 'Leave empty if unchanged' : ''
                      "
                    />
                    <div class="input-group-append">
                      <span
                        class="input-group-text"
                        (click)="hide = !hide"
                        [title]="
                          hide ? 'Hide the password' : 'Show the password'
                        "
                      >
                        <img
                          [src]="
                            hide
                              ? '/assets/images/svg/eye_hide.svg'
                              : '/assets/images/svg/eye_visible.svg'
                          "
                          alt="password-field"
                        />
                      </span>
                    </div>
                    <ng-container
                      *ngIf="
                        checkControlValidity('password', 'invalid', form, true)
                      "
                    >
                      <div
                        class="ml-2 invalid-feedback"
                        *ngFor="let msg of getPasswordErrorMessage('password')"
                      >
                        {{ msg }}
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row custom-datatable">
              <div class="col-5 d-flex p-0">
                <label class="col-xl-5 col-md-5 my-auto" for="role"
                  ><span>*</span> Role</label
                >
                <select
                  class="form-control cursor custom-user-select col-xl-7 col-md-7"
                  formControlName="role"
                  (change)="roleChanged($event.target.value)"
                  [value]="editDetails?.position"
                >
                  <!-- <option selected value="null">Select role</option> -->
                  <option
                    *ngFor="let position of positions"
                    [value]="position.value"
                  >
                    {{ position.label }}
                  </option>
                </select>
              </div>

              <div class="col-5 d-flex p-0" *ngIf="selectedRole !== 'partner'">
                <label class="col-xl-5 col-md-5 my-auto" for="brand"
                  ><span class="pl-4">*</span> Brand</label
                >
                <select
                  [value]="editDetails?.brand_id"
                  formControlName="brand"
                  class="form-control cursor custom-user-select col-xl-7 col-md-7"
                  (change)="brandChanged($event.target.value)"
                >
                  <option selected value="null">Select Brand</option>
                  <option *ngFor="let brand of brands" [value]="brand.id">
                    {{ brand.name }}
                  </option>
                </select>
              </div>

              <div class="col-5 d-flex p-0" *ngIf="selectedRole === 'partner'">
                <label class="col-xl-5 col-md-5 my-auto" for="brand"
                  ><span class="pl-4">*</span> Partners</label
                >
                <select
                  [value]="editDetails?.id"
                  formControlName="partner"
                  class="form-control cursor custom-user-select col-xl-7 col-md-7"
                  (change)="partnerChanged($event.target.value)"
                >
                  <option selected value="null">Select Partner</option>
                  <option *ngFor="let partner of partners" [value]="partner.id">
                    {{ partner.name }}
                  </option>
                </select>
              </div>
            </div>

            <div
              class="d-flex flex-row justify-content-between align-items-center"
            >
              <div></div>
              <div class="pull-right">
                <button
                  type="button"
                  class="btn btn-primary"
                  [disabled]="!form?.valid"
                  (click)="submit()"
                  *ngIf="
                    checkAccessModule(
                      FEATURES.USERS_CMS_USERS_LISTING.name,
                      PERMISSION.WRITE
                    ) &&
                    checkAccessModule(
                      FEATURES.USERS_CMS_USERS_ADD.name,
                      PERMISSION.WRITE
                    )
                  "
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
