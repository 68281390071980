import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HelperService } from 'src/app/shared/services';

@Component({
  selector: 'app-all-users',
  templateUrl: './all-users.component.html',
  styleUrls: ['./all-users.component.scss'],
})
export class AllUsersComponent implements OnInit {
  @Input() tableDataListItems: any[] = [];
  @Input() pageLimit?: number;
  @Input() page?: number;
  @Input() collectionSize?: number;
  @Input() hasMore?: boolean;
  @Input() tableColumns: any[] = [];
  @Input() filterSearchText?: any[] = [];

  @Output() clickedOnLink: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPageChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPageLimitChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSearchInputChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFilterSearchText: EventEmitter<any> = new EventEmitter<any>();
  @Output() checkboxCheckedUpdated: EventEmitter<any> = new EventEmitter<any>();

  isSelectedArr: any = [];

  constructor() {}

  ngOnInit(): void {}

  emitHyperLinkEvent(item: any): void {
    this.clickedOnLink.emit(item);
  }

  constructNestedObject(
    element: any,
    column: {
      key: string;
      value: string;
      type: string;
      map: any;
      callback: Function;
    }
  ) {
    return HelperService.constructNestedObject(element, column);
  }

  onSearchInputChanged(elem: any, value: string, filter?: string): void {
    console.log('Elem: ', elem);
    console.log('value: ', value);
    console.log('filter: ', filter);

    value = elem.optionValue.toLowerCase();
    this.onSearchInputChange.emit({
      elem,
      value,
      filter,
    });
    this.isSelectedArr.push(filter);
  }
}
