import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-purpl-table-platform',
  templateUrl: './purpl-table-platform.component.html',
  styleUrls: ['./purpl-table-platform.component.scss'],
})
export class PurplTablePlatformComponent implements OnInit {
  @Input() platform?: 'android' | 'ios' | 'ANDROID' | 'IOS';

  constructor() {}

  ngOnInit(): void {}

  isAndroid() {
    return this.platform === 'android' || this.platform === 'ANDROID';
  }

  isIOS() {
    return this.platform === 'ios' || this.platform === 'IOS';
  }
}
