<div class="purpl-table-video">
  <img
    class="img-thumbnail"
    width="150px"
    height="150px"
    [src]="'http://img.youtube.com/vi/' + videoSrc + '/0.jpg'"
    onError="this.src='assets/images/dashboard/designer.jpg'"
    alt="rating"
  />
</div>
