import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-purpl-table-html',
  templateUrl: './purpl-table-html.component.html',
  styleUrls: ['./purpl-table-html.component.scss'],
})
export class PurplTableHtmlComponent implements OnInit {
  @Input() htmlContent: any;

  constructor() {}

  ngOnInit(): void {}
}
