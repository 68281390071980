import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PageLimiterComponent } from './page-limiter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [CommonModule, FormsModule, NgbModule, ReactiveFormsModule],
  declarations: [PageLimiterComponent],
  exports: [PageLimiterComponent],
})
export class PageLimiterModule {}
