import {Component, Injector, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';

import {FormBaseComponent} from '../../../shared/components/base/form-base.component';
import {FormInterface} from '../../../shared/interfaces/form.interface';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends FormBaseComponent implements OnInit, FormInterface {
  public formSubmitted: any = false;

  constructor(injector: Injector) {
    super(injector);
    // if logged in simple redirect to the dashboard
    if (this.userService.isLoggedIn()) {
      this.goToWithoutConfirmation(this.routeList.DASHBOARD);
    }
    // Set the default language
    this.translate.setDefaultLang('en');
    // Use a language
    this.translate.use('en');
  }

  ngOnInit(): void {
    this.addFormValidations();
  }

  /**
   * The following method is used
   * @returns {string}
   * @param formElementName
   */
  getErrorMessage(formElementName: string): string {
    const formElement = this.formGroup.get(formElementName);
    // check if any error exists or not
    if (formElement.errors) {
      // detect the required & email validation being passed on the respective form control i.e. email in this case
      if (formElement.errors.required) {
        return `It's required`;
      } else if (formElement.errors.email || formElement.errors.pattern) {
        return 'Invalid email address';
      }
    }
    return '';
  }

  addFormValidations(): void {
    // adding validations to the form
    this.addFormControlWithValidations(
      'email',
      [
        Validators.email,
        Validators.pattern(this.constantList.REGEX_EMAIL_ADDRESS),
      ]
    );
  }

  onSubmit(): void {
    this.formSubmitted = true;
    if (this.formGroup.status === this.constantList.VALID_FORM_STATE) {
      const values = this.formGroup.getRawValue();
      this.userService.forgetPassword(values.email)
        .then((result: any) => {
          this.toastrService.success(result.message);
          if (result && result.body && result.body.token) {
            this.sharedDataService.changeFormSubmitStatus(false);
            this.goToWithoutConfirmation(this.routeList.FORGET_PASSWORD + '?token=' + result.body.token);
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  }
}
