import { DatePipe } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { map, Subject } from 'rxjs';
import { ListingBaseComponent } from 'src/app/shared/components/base/listing-base.component';
import { ApiResponseBodyInterface } from 'src/app/shared/interfaces/api-response.interface';
import {
  selectDashboardBMAllUsersVisits,
  selectDashboardBMLoading,
} from 'src/app/shared/stores/slices/DashboardBMUsersVisits';
import {
  DEFAULT_PAGE_INDEX,
  DEFAULT_TIMEZONE,
  NUMBER_RECORDS_PER_PAGE,
} from 'src/app/shared/constants/constant-list';
import { HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';
import { DashboardBmService } from 'src/app/shared/services/dashboard-bm.service';
import momentTZ from 'moment-timezone';
import { BrandManagerDashboardUserDetailsComponent } from './components/brand-manager-dashboard-user-details/brand-manager-dashboard-user-details.component';
import { MatDialog } from '@angular/material/dialog';
import { BrandManagerDashboardDateFilterComponent } from './components/brand-manager-dashboard-date-filter/brand-manager-dashboard-date-filter.component';
@Component({
  selector: 'brand-manager-dashboard',
  templateUrl: './brand-manager-dashboard.component.html',
  styleUrls: ['./brand-manager-dashboard.component.scss'],
})
export class BrandManagerDashboardComponent
  extends ListingBaseComponent
  implements OnInit
{
  lgImage: any = { img: null, class: null };
  verificationImages: any = {
    front: null,
    back: null,
    face: null,
    liveness: null,
  };

  public recentlyVisited?: boolean;

  activeId: string = '';
  searchTexts: string = '';
  bookedSpots: any;
  cashoutBalance = '0';
  selectedFilter: number | null = 1;
  selectedStatus?: number | null;
  activeParams: string = '';
  value: any;
  loading = false;
  currentPage: any = {};
  pendingFilters: {
    id: number;
    statusText: string;
    dateFrom: any;
    dateTo: any;
  }[] = [
    { id: 0, statusText: 'Custom Date', dateFrom: '', dateTo: '' },
    {
      id: 1,
      statusText: 'Today',
      dateFrom: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      dateTo: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
    },
    {
      id: 3,
      statusText: 'Yesterday',
      dateFrom: this.datePipe.transform(
        new Date().setDate(new Date().getDate() - 1),
        'yyyy-MM-dd'
      ),
      dateTo: this.datePipe.transform(
        new Date().setDate(new Date().getDate() - 1),
        'yyyy-MM-dd'
      ),
    },
    {
      id: 4,
      statusText: 'This Week',
      dateFrom: this.datePipe.transform(
        this.getThisWeek('start'),
        'yyyy-MM-dd'
      ),
      dateTo: this.datePipe.transform(this.getThisWeek('end'), 'yyyy-MM-dd'),
    },
    {
      id: 5,
      statusText: 'Last Week',
      dateFrom: this.datePipe.transform(
        this.getLastWeek('start'),
        'yyyy-MM-dd'
      ),
      dateTo: this.datePipe.transform(this.getLastWeek('end'), 'yyyy-MM-dd'),
    },
    // { id: 2, statusText: 'Tomorrow', dateFrom: this.datePipe.transform(new Date(new Date().setDate(new Date().getDate() + 1)), 'yyyy-MM-dd'), dateTo: this.datePipe.transform(new Date(new Date().setDate(new Date().getDate() + 1)), 'yyyy-MM-dd') },
    // { id: 3, statusText: 'After Tomorrow', dateFrom: this.datePipe.transform(new Date(new Date().setDate(new Date().getDate() + 2)), 'yyyy-MM-dd'), dateTo: this.datePipe.transform(new Date(new Date().setDate(new Date().getDate() + 2)), 'yyyy-MM-dd') },
    // { id: 4, statusText: 'This Week', dateFrom: this.datePipe.transform(this.getThisWeek('start'), 'yyyy-MM-dd'), dateTo: this.datePipe.transform(this.getThisWeek('end'), 'yyyy-MM-dd') },
  ];
  recentlyVisitedFilters: {
    id: number;
    statusText: string;
    dateFrom: any;
    dateTo: any;
  }[] = [
    {
      id: 0,
      statusText: 'Custom Date',
      dateFrom: momentTZ('').format('YYYY-MM-DD'),
      dateTo: momentTZ('').format('YYYY-MM-DD'),
    },
    {
      id: 1,
      statusText: 'Today',
      dateFrom: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      dateTo: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
    },
    {
      id: 3,
      statusText: 'Yesterday',
      dateFrom: this.datePipe.transform(
        new Date().setDate(new Date().getDate() - 1),
        'yyyy-MM-dd'
      ),
      dateTo: this.datePipe.transform(
        new Date().setDate(new Date().getDate() - 1),
        'yyyy-MM-dd'
      ),
    },
    {
      id: 4,
      statusText: 'This Week',
      dateFrom: this.datePipe.transform(
        this.getThisWeek('start'),
        'yyyy-MM-dd'
      ),
      dateTo: this.datePipe.transform(this.getThisWeek('end'), 'yyyy-MM-dd'),
    },
    {
      id: 5,
      statusText: 'Last Week',
      dateFrom: this.datePipe.transform(
        this.getLastWeek('start'),
        'yyyy-MM-dd'
      ),
      dateTo: this.datePipe.transform(this.getLastWeek('end'), 'yyyy-MM-dd'),
    },
  ];

  recentlyVisitedStatus: { id: number; statusText: string }[] = [
    { id: 1, statusText: 'Successful' },
    { id: 6, statusText: 'Expired' },
    { id: 2, statusText: 'Cancelled' },
    { id: 8, statusText: 'Failed' },
  ];

  exportDetails: any;
  eventsSubject: Subject<any> = new Subject<any>();
  dateFilterData: any;
  statusFilterData: any = [1, 6, 2, 8];

  tableDataListItems: any[] = [];
  tableColumns: any[] = [];

  hasMore = false;
  page: number = DEFAULT_PAGE_INDEX;
  pageLimit: number = NUMBER_RECORDS_PER_PAGE;
  lastPage = 0;
  totalNumberOfPages = 0;
  totalNumberOfRecords = 0;

  params: HttpParams = new HttpParams();
  queryParams: Params = {};
  pendingOrRecently: any;
  visitedStatus: any;

  constructor(
    injector: Injector,
    private datePipe: DatePipe,
    private store: Store,
    private dashboardBMService: DashboardBmService,
    public dialog: MatDialog
  ) {
    super(injector);
    this.requestBody.transaction_status_ids = [3];
    this.requestBody.transaction_type_ids = [1];
    this.requestBody.page = DEFAULT_PAGE_INDEX;
    this.requestBody.date_from = this.datePipe.transform(
      new Date(),
      'yyyy-MM-dd'
    );
    this.requestBody.date_to = this.datePipe.transform(
      new Date(),
      'yyyy-MM-dd'
    );

    this.store
      .select(selectDashboardBMLoading)
      .subscribe((resp) => (this.loading = resp));

    this.store
      .select(selectDashboardBMAllUsersVisits)
      .subscribe(
        (resource: Partial<ApiResponseBodyInterface | null | undefined>) => {
          if (resource?.items && Array.isArray(resource.items)) {
            this.tableDataListItems = [];
            this.tableDataListItems = resource?.items?.map((item) => ({
              ...item,
              loading: false,
            }));

            this.bookedSpots = resource?.total_elements;
            this.cashoutBalance =
              resource.cashout_balance === null ? 0 : resource.cashout_balance;
          }

          const totalPages = Math.ceil(
            Number(resource?.total_elements) / Number(resource?.size)
          );
          this.lastPage = totalPages;
          this.totalNumberOfPages = totalPages;
          this.totalNumberOfRecords = Number(resource?.total_elements);
          this.hasMore = 0 <= totalPages - this.page;
        }
      );

    this.setTableConfigurationByLanguage();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.activeId = params && params?.action ? params.action : 'pending';

      this.initData();
      this.setTableConfigurationByLanguage();
    });
  }

  searchInput(value: string) {
    this.searchTexts = value;
    this.initData();
  }

  recentlyVisitedUsersFilter(id: string) {
    this.pendingOrRecently = {};

    if (id === 'recent') {
      this.value = this.recentlyVisitedFilters.filter(
        (filter) => filter.id === this.selectedFilter
      );
    } else {
      this.value = this.pendingFilters.filter(
        (filter) => filter.id == this.selectedFilter
      );
    }

    this.pendingOrRecently = {
      selected: false,
      key1: 'date_from',
      key2: 'date_to',
      value1:
        this.value[0]?.dateFrom === undefined
          ? this.datePipe.transform(new Date(), 'yyyy-MM-dd')
          : momentTZ(this.value[0].dateFrom).format('YYYY-MM-DD'),
      value2:
        this.value[0]?.dateTo === undefined
          ? this.datePipe.transform(new Date(), 'yyyy-MM-dd')
          : momentTZ(this.value[0].dateTo).format('YYYY-MM-DD'),
      key3: 'transaction_status_ids',
      value3: this.statusFilterData,
    };
  }

  filterApplied(id: number) {
    this.requestBody = {};

    this.selectedFilter = this.selectedFilter == id && id != 0 ? null : id;

    if (this.activeId === 'recent') {
      if (this.selectedFilter == null) {
        this.selectedFilter = 1;
        this.dateFilterData = [this.recentlyVisitedFilters[1]];
        this.eventsSubject.next({
          origin: 'recently-visited',
          value: [this.recentlyVisitedFilters[1]],
          filter: this.statusFilterData,
        });
      }
    } else {
      if (this.selectedFilter == null) {
        this.selectedFilter = 1;
        this.dateFilterData = this.pendingFilters[1];
        this.eventsSubject.next({
          origin: 'pending-visits',
          value: [this.pendingFilters[1]],
        });
      }
    }

    this.page = 1;
    this.recentlyVisitedUsersFilter(this.activeId);
    this.initData();
  }

  statusApplied(id: number) {
    this.dateFilterData = !this.dateFilterData
      ? [this.recentlyVisitedFilters[1]]
      : this.dateFilterData;

    this.selectedStatus = this.selectedStatus === id ? null : id;

    if (this.selectedStatus == null) {
      this.statusFilterData = [1, 6, 2, 8];

      const value = this.dateFilterData.filter(
        (filter: any) => filter.id == id
      );
      this.visitedStatus = {
        selected: false,
        key1: 'date_from',
        key2: 'date_to',
        value1:
          this.value[0]?.dateFrom === undefined
            ? this.datePipe.transform(new Date(), 'yyyy-MM-dd')
            : this.value[0].dateFrom,
        value2:
          this.value[0]?.dateTo === undefined
            ? this.datePipe.transform(new Date(), 'yyyy-MM-dd')
            : this.value[0].dateTo,
        key3: 'transaction_status_ids',
        value3: this.statusFilterData,
      };
    } else {
      this.statusFilterData = [id];

      this.visitedStatus = {
        selected: false,
        key1: 'date_from',
        key2: 'date_to',
        value1:
          this.value[0]?.dateFrom === undefined
            ? this.datePipe.transform(new Date(), 'yyyy-MM-dd')
            : this.value[0].dateFrom,
        value2:
          this.value[0]?.dateTo === undefined
            ? this.datePipe.transform(new Date(), 'yyyy-MM-dd')
            : this.value[0].dateTo,
        key3: 'transaction_status_ids',
        value3: this.statusFilterData,
      };
    }

    this.initData();
  }

  getThisWeek(startOrEnd: string) {
    const curr = new Date();
    const day = curr.getDay();
    const first = curr.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    const last = first + 6; // last day is the first day + 6
    if (startOrEnd == 'start') {
      const firstDay = new Date(curr.setDate(first));
      return firstDay;
    } else if (startOrEnd == 'end') {
      const lastDay = new Date(curr.setDate(last));
      return lastDay;
    }
  }

  getLastWeek(startOrEnd: string) {
    // 1st day of week Monday
    const currentDate = new Date();
    if (startOrEnd == 'start') {
      // Get the last week date since the date today
      const lastDay = currentDate.setTime(
        currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
      );

      // Get the monday date of the last week date
      const first =
        new Date(lastDay).getDate() - new Date(lastDay).getDay() + 1;
      // set it is the 1st date of the last week
      const firstDay = new Date(new Date(lastDay).setDate(first));

      return firstDay;
    } else if (startOrEnd == 'end') {
      // Get the last week date since the date today
      let lastDay = currentDate.setTime(
        currentDate.getTime() -
          (currentDate.getDay() ? currentDate.getDay() : 7) *
            24 *
            60 *
            60 *
            1000
      );

      return lastDay;
    }
  }

  setTableConfigurationByLanguage(): void {
    this.tableColumns = [
      {
        key: 'local_created_at',
        value: 'Issuance Date & Time',
        type: 'text',
        class: 'table-min-width-150',
        callback: (obj: any) => {
          if (obj) {
            return (
              momentTZ(obj.created_at)
                .tz(DEFAULT_TIMEZONE)
                .format('ddd, MMM D, YYYY') +
              ' at ' +
              momentTZ(obj.created_at).tz(DEFAULT_TIMEZONE).format('hh:mm A')
            );
          } else {
            return '';
          }
        },
      },
      {
        key: 'branch.name',
        value: 'Cashout Point',
        type: 'text',
        searchable: false,
        headingClass: 'table-min-width-130',
      },
      {
        key: 'branch',
        value: 'Type',
        type: 'text',
        searchable: false,
        callback: (branch: any) => {
          if (branch?.type?.name === 'ATM') {
            return 'ATM';
          } else if (branch?.type?.name === 'Branch') {
            return 'OTC';
          }
          return '';
        },
      },
      {
        key: 'user',
        value: 'Full Name',
        type: 'full-name',
        searchable: false,
        headingClass: 'table-fixed-width-230',
      },
      {
        key: 'transaction_code',
        value: 'Reference Code',
        type: 'text',
        searchable: false,
      },
      {
        key: 'amount',
        value: 'Amount',
        type: 'text',
        searchable: false,
        class: 'font-weight-bold',
        headingClass: 'table-min-width-120',
      },
      // {
      //   key: 'sub_transaction_list.remittance_transaction.transfer_purpose',
      //   value: 'Transfer Reason',
      //   type: 'text',
      //   searchable: false,
      // },
      // {
      //   key: 'sub_transaction_list.remittance_transaction.relationship_to_receiver',
      //   value: 'Sender Relation',
      //   type: 'text',
      //   searchable: false,
      // },
      {
        key: '',
        value: '',
        type: 'more-details',
        searchable: false,
        class: 'text-center',
        headingClass: 'table-min-width-110',
      },
    ];
  }

  onFilterCustomDate() {
    const dialogRef = this.dialog.open(
      BrandManagerDashboardDateFilterComponent,
      {
        width: '400px',
      }
    );

    dialogRef
      .afterClosed()
      .subscribe(
        (resp: { fromDate: NgbDate | null; toDate: NgbDate | null }) => {
          if (resp?.fromDate) {
            const dateFrom = `${resp?.fromDate?.year}-${resp?.fromDate?.month}-${resp?.fromDate?.day}`;
            if (this.activeId === 'recent' && this.recentlyVisitedFilters) {
              this.recentlyVisitedFilters[0].dateFrom = dateFrom;
            } else {
              if (this.pendingFilters) {
                this.pendingFilters[0].dateFrom = dateFrom;
              }
            }
          }

          if (resp?.toDate) {
            const dateTo = `${resp?.toDate?.year}-${resp?.toDate?.month}-${resp?.toDate?.day}`;
            if (this.activeId === 'recent' && this.recentlyVisitedFilters) {
              this.recentlyVisitedFilters[0].dateTo = dateTo;
            } else {
              if (this.pendingFilters) {
                this.pendingFilters[0].dateTo = dateTo;
              }
            }
          }

          // 0 -> for custom date filtering
          this.filterApplied(0);
        }
      );
  }

  onRowClicked(event: any): void {
    this.dialog.open(BrandManagerDashboardUserDetailsComponent, {
      data: {
        using_jumio_v2: event.user.using_jumio_v2,
        transaction: event,
        action: 'cashout',
      },
      width: '1000px',
    });
  }

  tabChange(id: string) {
    this.activeId = id;
    this.selectedFilter = 1;

    this.tableDataListItems = [];
    this.searchTexts = '';

    if (id == 'pending') {
      this.activeId = 'pending';
      this.requestBody.transaction_status_ids = [3];
    } else {
      this.activeId = 'recent';
      this.requestBody.transaction_status_ids = [1, 6, 2, 8];
    }

    this.page = 1;
    this.generateURLParams();
  }

  exportItems() {
    this.exportDetails = this.requestBody;

    this.dataService
      .fetchData({
        apiUrl: this.apiList.TRANSACTION_EXPORT,
        method: 'POST',
        contentType: 'application/json',
        params: null,
        body: this.exportDetails,
      })
      .pipe(
        map((response) => {
          if (response.status == 'success') {
            this.toastrService.success(
              'Congratulations! The report is generated and will be sent to your email address in the next few minutes.',
              'Success'
            );
            return response && response.data ? response.data : null;
          }
        })
      )
      .subscribe(() => {});
  }

  onPageLimitChange(event: number) {
    this.pageLimit = Number(event);
    this.page = DEFAULT_PAGE_INDEX;

    this.initData();
  }

  onPageChange(event: number) {
    this.page = Number(event);

    this.initData();
  }

  private initData() {
    if (this.pendingOrRecently) {
      if (this.pendingOrRecently?.selected) {
        this.requestBody[this.pendingOrRecently?.key1] =
          this.pendingOrRecently?.value1;
        this.requestBody[this.pendingOrRecently?.key2] =
          this.pendingOrRecently?.value2;
      } else {
        this.requestBody[this.pendingOrRecently?.key1] =
          this.pendingOrRecently?.value1;
        this.requestBody[this.pendingOrRecently?.key2] =
          this.pendingOrRecently?.value2;
        this.requestBody[this.pendingOrRecently?.key3] = this.statusFilterData;
      }
    }

    this.requestBody.page = this.searchTexts ? 1 : Number(this.page);

    this.requestBody.search = this.searchTexts;
    this.requestBody.size = Number(this.pageLimit);
    this.requestBody.activeId = this.activeId;

    this.dashboardBMService.getDashboardBMAllUsersVisits({
      ...this.requestBody,
    });
  }

  private generateURLParams() {
    let queryParams: any = {};

    if (this.activeId) {
      queryParams = {
        ...queryParams,
        action: this.activeId,
      };
    }

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/listing'], {
        queryParams,
        queryParamsHandling: 'merge',
      })
    );
  }
}
