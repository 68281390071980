import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { TransactionDetailComponent } from '../../components/transaction-detail/transaction-detail.component';

@Component({
  selector: 'app-transaction-detail-renderer',
  templateUrl: './transaction-detail-renderer.component.html',
  styleUrls: ['./transaction-detail-renderer.component.scss'],
})
export class TransactionDetailRendererComponent
  implements ICellRendererAngularComp
{
  value: string | undefined;
  params: HttpParams | undefined;
  data: any;

  constructor(public dialog: MatDialog) {}

  agInit(params: ICellRendererParams): void {
    this.value = params.value;
    this.data = params.data;

    const httpParams = (params as any)?.httpParams;
    if (httpParams) {
      this.params = httpParams;
    }
  }

  refresh(params: ICellRendererParams) {
    this.value = params.value;
    this.data = params.data;

    const httpParams = (params as any)?.httpParams;
    if (httpParams) {
      this.params = httpParams;
    }
    return true;
  }

  onViewDetail(event: any): void {
    const dialogRef = this.dialog.open(TransactionDetailComponent, {
      data: {
        selectedUser: event,
        data: this.data,
      },
      width: '500px',
    });

    dialogRef.afterClosed().subscribe({
      next: (resp: any) => {
        if (resp === 'save') {
          // this.complianceService.getAllCompliances(this.params);
        }
      },
    });
  }
}
