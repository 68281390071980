import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Brand } from '../../interfaces/Brands';
import { createSlice } from '@reduxjs/toolkit';

export interface AppStates {
  inactivity_countdown: number | null;
  isPromptShown: boolean;
}

const initialState: AppStates = {
  inactivity_countdown: null,
  isPromptShown: false,
};

const { actions, reducer, name } = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setCountDownTimer: (state, { payload }) => {
      state.inactivity_countdown = payload;
    },
    setShowPrompt: (state, { payload }) => {
      state.isPromptShown = payload;
    },
  },
});

const selectFeature = createFeatureSelector<ReturnType<typeof reducer>>(name);

export const selectCountdownTimer = createSelector(
  selectFeature,
  (state) => state.inactivity_countdown
);

export const selectShowPrompt = createSelector(
  selectFeature,
  (state) => state.isPromptShown
);

export { name };
export const { setCountDownTimer, setShowPrompt } = actions;
export default reducer;
