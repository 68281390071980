import { createFeatureSelector, createSelector } from '@ngrx/store';
import { createSlice } from '@reduxjs/toolkit';
import { ApiResponseBodyInterface } from '../../interfaces/api-response.interface';

export interface ReceivedTransactionState {
  received: ApiResponseBodyInterface | null;
  loadingReceived: boolean;
}

const initialState: ReceivedTransactionState = {
  received: null,
  loadingReceived: false,
};

const { actions, reducer, name } = createSlice({
  name: 'receivedTransaction',
  initialState,
  reducers: {
    getAllReceivedTransactionPending: (state) => {
      state.loadingReceived = true;
    },
    getAllReceivedTransactionFullfilled: (state, { payload }) => {
      state.loadingReceived = false;
      state.received = payload;
    },
    getAllReceivedTransactionRejected: (state, { payload }) => {
      state.loadingReceived = true;
    },
  },
});

const selectReceivedTransactionFeature =
  createFeatureSelector<ReturnType<typeof reducer>>(name);

export const selectReceivedTransactionLoading = createSelector(
  selectReceivedTransactionFeature,
  (state) => state.loadingReceived
);

export const selectAllReceivedTransactions = createSelector(
  selectReceivedTransactionFeature,
  (state) => state.received
);

export { name };
export const {
  getAllReceivedTransactionPending,
  getAllReceivedTransactionFullfilled,
  getAllReceivedTransactionRejected,
} = actions;
export default reducer;
