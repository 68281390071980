import { createFeatureSelector, createSelector } from '@ngrx/store';
import { createSlice } from '@reduxjs/toolkit';
import { ApiResponseBodyInterface } from '../../interfaces/api-response.interface';

export interface TellersBMState {
  tellers: ApiResponseBodyInterface | null;
  loadingTellers: boolean;
}

const initialState: TellersBMState = {
  tellers: null,
  loadingTellers: false,
};

const { actions, reducer, name } = createSlice({
  name: 'tellers',
  initialState,
  reducers: {
    getAllTellersPending: (state) => {
      state.loadingTellers = true;
    },
    getAllTellersFullfilled: (state, { payload }) => {
      state.loadingTellers = false;
      state.tellers = payload;
    },
    getAllTellersRejected: (state, { payload }) => {
      state.loadingTellers = true;
    },
  },
});

const selectTellersFeature =
  createFeatureSelector<ReturnType<typeof reducer>>(name);

export const selectTellersLoading = createSelector(
  selectTellersFeature,
  (state) => state.loadingTellers
);

export const selectAllTellers = createSelector(
  selectTellersFeature,
  (state) => state.tellers
);

export { name };
export const {
  getAllTellersPending,
  getAllTellersFullfilled,
  getAllTellersRejected,
} = actions;
export default reducer;
