<a
  #p1="ngbPopover"
  triggers="manual"
  [ngbPopover]="popContent"
  placement="bottom"
  class="link-bold-underline"
  (click)="onTogglePopover.emit({
  p1, data
})"
>
  {{ label }}
</a>
