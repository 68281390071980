'use strict';
import { AppConfig } from '../../app.config';

const apiConfig = new AppConfig();

export const SERVER_URL: string = apiConfig.config.apiUrl.backendUrl;

// General
export const DOWNLOAD_FILE_API: string = SERVER_URL + 'download';

// Authentication
export const USER_BASE_URL = `${SERVER_URL}users`;
export const LOGIN_API = `${USER_BASE_URL}/try-login`;
export const TWO_FACTOR_API = `${USER_BASE_URL}/login`;
export const FORGOT_PASSWORD = `${USER_BASE_URL}/forgot-password`;
export const RESET_PASSWORD = `${USER_BASE_URL}/reset-password`;
export const VALIDATE_PASSWORD = `${USER_BASE_URL}/validate-password`;
export const UPDATE_PASSWORD = `${USER_BASE_URL}/update-password`;

// Refresh Token
export const OATH_URL = `${SERVER_URL}oauth`;
export const REFRESH_TOKEN_URL = `${SERVER_URL}oauth/refresh`;

// User
export const USER_LISTING = `${USER_BASE_URL}/listing`;
export const UNREGISTERED_USER_LISTING = `${USER_BASE_URL}/unregistered-user-listing`;
export const USER_STATUS = `${USER_BASE_URL}/`;
export const USER_ADD = `${USER_BASE_URL}`;
export const USER_PROFILE_UPDATE = `${USER_BASE_URL}/{id}/profile`;
export const USER_DETAIL = `${USER_BASE_URL}/{id}`;
export const USER_REPORT = `${USER_BASE_URL}/report`;
export const USER_EXPORT_REPORT = `${USER_BASE_URL}/report-export`;
export const USER_EXPORT_RECORDS = `${USER_BASE_URL}/records`;
export const USER_EXPORT_RECORDS_UNREGISTERED = `${USER_EXPORT_RECORDS}/unregistered`;

export const USER_UPDATE = `${USER_BASE_URL}/{id}`;
export const USER_DELETE = `${USER_BASE_URL}/{id}`;
export const USER_ATTACHMENTS = `${USER_BASE_URL}/{id}/attachments`;
export const USER_ATTACHMENT = `${USER_BASE_URL}/{id}/attachments/{docId}`;
export const USER_FEATURES = `${USER_BASE_URL}/{userCode}/features`;
export const USER_SUSPEND = `${USER_BASE_URL}/{userCode}/features/{feature}`;
export const USER_TRANSACTIONS = `${USER_BASE_URL}/{userCode}/transactions/`;

// User Profile for extended KYC
export const USER_BASIC_INFORMATION = `${USER_BASE_URL}/{userCode}/info`;
export const USER_VERIFICATION_DETAILS = `${USER_BASE_URL}/{userCode}/verifications`;
export const USER_ADDITIONAL_INFO = `${USER_BASE_URL}/{userCode}/users`;
export const USER_WALLET_BALANCE = `${USER_BASE_URL}/{userCode}/wallets`;

// Users Feedback
// export const FEEDBACK_BASE_URL = `${SERVER_URL}users-feedback`;
export const FEEDBACK_BASE_URL = `${SERVER_URL}ratings`;
export const FEEDBACK_LISTING = `${SERVER_URL}users-feedback`;
export const RATINGS_LISTING = `${FEEDBACK_BASE_URL}`;
export const FEEDBACK_RESOLVE = `${FEEDBACK_BASE_URL}/feedbacks/{id}/resolve`;
export const RATING_RESOLVE = `${FEEDBACK_BASE_URL}/rate/{id}/resolve`;
export const COMMENT_ADD_UPDATE = `${FEEDBACK_BASE_URL}/update`;
export const USER_FEEDBACK = `${SERVER_URL}app-users/{userCode}/ratings`;

// Brand
export const BRAND_BASE_URL = `${SERVER_URL}brands`;
export const BRAND_LISTING = `${BRAND_BASE_URL}/listing`;
export const BRAND_STATUS = `${BRAND_BASE_URL}/`;
export const BRAND_ADD = `${BRAND_BASE_URL}`;
export const BRAND_UPDATE = `${BRAND_BASE_URL}/{id}`;
export const BRAND_DETAIL = `${BRAND_BASE_URL}/{id}`;

export const BRAND_STATS = `${SERVER_URL}brand-stats`;
export const BRAND_STATS_BY_BRAND = `${BRAND_STATS}/{id}`;
export const BRAND_STATS_BY_BRANCES = `${BRAND_STATS}/{id}/branches`;
export const BRAND_STATS_BY_BRANCES_BY_ID = `${BRAND_STATS}/{brandId}/branches/{branchId}`;
export const BRAND_STATS_BY_BRANCES_BY_ID_CHART = `${BRAND_STATS}/{brandId}/branches/{branchId}/bar-chart`;

export const BRAND_GET_ALL = `${BRAND_BASE_URL}/all`;
export const BRAND_DELETE = `${BRAND_BASE_URL}/{id}`;

// Home Page
export const HOME_PAGE = `${SERVER_URL}home-pages`;
export const HOME_PAGE_STATUS = `${HOME_PAGE}/`;
export const HOME_PAGE_DETAIL = `${HOME_PAGE}/{id}`;

// Privacy Policy
export const PRIVACY_POLICY = `${SERVER_URL}privacy-policy`;
export const PRIVACY_POLICY_DETAIL = `${PRIVACY_POLICY}/{id}`;

// Privacy Policy
export const TERMS_CONDITIONS = `${SERVER_URL}terms-and-conditions`;
export const TERMS_CONDITIONS_DETAIL = `${TERMS_CONDITIONS}/{id}`;

// Help Center FAQ
export const HELP_CENTER = `${SERVER_URL}help-center`;
export const DELETE_FAQ = `${HELP_CENTER}/{id}`;
export const FAQ_TOPICS = `${HELP_CENTER}/topics`;
export const FAQ_TOPIC_DETAIL = `${FAQ_TOPICS}/{id}`;

// Remittance Platforms
export const REMITTANCE_PLATFORMS_BASE_URL = `${SERVER_URL}remittance-platforms`;
export const REMITTANCE_PLATFORMS_LISTING = `${REMITTANCE_PLATFORMS_BASE_URL}/listing`;

// Pricing Model Controller
export const PRICING_MODEL_BASE_URL = `${SERVER_URL}pricing-model`;
export const PRICING_MODEL_LISTING = `${PRICING_MODEL_BASE_URL}/listing`;
export const PRICING_MODEL_GET = `${PRICING_MODEL_BASE_URL}/{id}/platform`;
export const PRICING_MODEL_EXPORT = `${PRICING_MODEL_BASE_URL}/export`;
export const PRICING_MODEL_LOGO = `${PRICING_MODEL_BASE_URL}/update-logo`;
export const PRICING_MODEL_SUSPEND = `${PRICING_MODEL_BASE_URL}/suspend`;

export const PRICING_MODEL_TRANSACTION_LISTING = `${PRICING_MODEL_BASE_URL}/transaction-listing`;
export const PRICING_MODEL_TRANSACTION_EXPORT = `${PRICING_MODEL_BASE_URL}/transaction-export`;

// Brand Profile
export const BRANCH_BASE_URL = `${SERVER_URL}branches`;
export const BRANCH_LISTING = `${BRANCH_BASE_URL}/listing`;
export const BRANCH_STATUS = `${BRANCH_BASE_URL}/`;
export const BRANCH_DETAIL = `${BRANCH_BASE_URL}/{id}`;
export const BRANCH_PENDING_COUNT = `${BRANCH_BASE_URL}/pendingBranches/{id}`;

// Transaction
export const TRANSACTION_BASE_URL = `${SERVER_URL}transactions`;
export const UNREGISTERED_USER_REMITTANCE = `${TRANSACTION_BASE_URL}/unregistered-user-remittance`;
export const UNREGISTERED_USER_DETAIL = `${TRANSACTION_BASE_URL}/{id}/unregistered-user-detail`;
export const TRANSACTION_USER_BALANCE = `${TRANSACTION_BASE_URL}/{id}/balance`;
export const TRANSACTION_LISTING = `${TRANSACTION_BASE_URL}/listing`;
export const TRANSACTION_USER_DETAIL = `${TRANSACTION_BASE_URL}/{id}/user`;
export const TRANSACTION_CASHEDOUT = `${TRANSACTION_BASE_URL}/{id}/cash-out-transactions`;
export const TRANSACTION_DETAIL = `${TRANSACTION_BASE_URL}/{id}`;
export const TRANSACTION_EXPORT = `${TRANSACTION_BASE_URL}/export`;
export const TRANSACTION_REPORT = `${TRANSACTION_BASE_URL}/report`;
export const TRANSACTION_EXPORT_REPORT = `${TRANSACTION_BASE_URL}/report-export`;
export const REVENUE_CHART = `${TRANSACTION_BASE_URL}/revenue-chart`;
export const TOP_CASH_OUT = `${TRANSACTION_BASE_URL}/top-cash-out-report`;

// Remittance Transactions
export const REMITTANCE_BASE_URL = `${SERVER_URL}remittance-transactions`;
export const REMITTANCE_RECEIVED = `${REMITTANCE_BASE_URL}/{id}/received-transactions`;
export const SUSPEND_TRANSACTION = `${REMITTANCE_BASE_URL}/suspend-transaction/{id}`;
export const SUSPEND_REMITTANCE_PLATFORM = `${REMITTANCE_BASE_URL}/suspend-remittance-platform/{id}`;

// Jumio Verification
export const JUMIO_BASE_URL = `${SERVER_URL}jumio-verification`;
export const JUMIO_USER_DETAILS = `${JUMIO_BASE_URL}/{id}/user`;
export const JUMIO_USER_IMAGE = `${JUMIO_BASE_URL}/image`;

// Jumio V2
export const JUMIO_BASE_URL_V2 = `${SERVER_URL}users/{id}`;
export const JUMIO_USER_DETAILS_V2 = `${USER_BASE_URL}/{id}/jumio-details`;
export const JUMIO_USER_DETAILS_IMAGE_V2 = `${USER_BASE_URL}/jumio-images/{encodedUrl}`;

export const VERIFICATION_IMAGES = `${USER_BASE_URL}/{userCode}/verifications`;

export const BLF_COMPLIANCE = `${SERVER_URL}registered-users`;
export const BLF_COMPLIANCE_COMMENT = `${BLF_COMPLIANCE}/{id}/purpl-comments`;
export const BLF_COMPLIANCE_BLF_COMMENT = `${BLF_COMPLIANCE}/{id}/blf-comments`;
export const BLF_COMPLIANCE_EXPORTS = `${BLF_COMPLIANCE}/export`;

export const BLF_COMPLIANCE2 = `${SERVER_URL}blf/verify`;
export const BLF_COMPLIANCE_VERIFY = `${BLF_COMPLIANCE2}/registered-user/{uuid}`;

// SENDERS
export const SENDERS = `${SERVER_URL}senders`;
export const SENDERS_DETAIL = `${SENDERS}/{email}`;
export const SENDERS_EXPORT = `${SENDERS}/export`;
export const SENDERS_DETAIL_REMITTANCE = `${SENDERS_DETAIL}/remittances`;

// STATS
export const DASHBOARD_STATS = `${USER_BASE_URL}/stats`;
export const DASHBOARD_STATS_CHART = `${DASHBOARD_STATS}/chart`;
export const DASHBOARD_STATS_DEVICES = `${DASHBOARD_STATS}/devices`;
export const DASHBOARD_STATS_VERIFICATION = `${DASHBOARD_STATS}/verification`;

// POSITIONS
export const POSITIONS_BASE_URL = `${SERVER_URL}positions`;
export const USER_POSITIONS_BASE_URL = `${SERVER_URL}user-positions`;

// VIDEOS CMS
export const VIDEOS = `${SERVER_URL}videos`;
export const VIDEOS_DETAIL = `${VIDEOS}/{id}`;

export const MODULES_FEATURES = `${SERVER_URL}modules-features`;

// ACCESS PRIVILEGES
export const ACCESS_PRIVILEGES = `${SERVER_URL}position-privileges`;

// SENDER STATS
export const SENDER_STATS = `${SERVER_URL}sender-stats`;
export const SENDER_STATS_COUNTRIES = `${SENDER_STATS}/countries`;
export const SENDER_STATS_REGION = `${SENDER_STATS}/regions`;
export const SENDER_STATS_AVERAGE = `${SENDER_STATS}/average`;

export const SENDER_STATS_AVERAGE_TICKET_PERCENTAGE = `${SENDER_STATS_AVERAGE}/ticket-percentage`;
export const SENDER_STATS_AVERAGE_TICKET_SIZE = `${SENDER_STATS_AVERAGE}/ticket-size`;
export const SENDER_STATS_AVERAGE_TICKET_TRANSACTION = `${SENDER_STATS_AVERAGE}/ticket-transaction-count`;

export const SENDER_STATS_COUNTRIES_BREAKDOWN_VOLUME = `${SENDER_STATS_COUNTRIES}/breakdown-volume`;
export const SENDER_STATS_COUNTRIES_TRANSACTIONS_COUNT = `${SENDER_STATS_COUNTRIES}/transactions-count`;
export const SENDER_STATS_COUNTRIES_TRANSACTIONS_VOLUME = `${SENDER_STATS_COUNTRIES}/transactions-volume`;

export const SENDER_STATS_REGION_BREAKDOWN_AMOUNT = `${SENDER_STATS_REGION}/breakdown-amount`;
export const SENDER_STATS_REGION_BREAKDOWN_PERCENTAGE = `${SENDER_STATS_REGION}/breakdown-percentage`;

// LANGUAGES
export const LANGUAGES = `${SERVER_URL}languages`;
export const LANGUAGES_DETAIL = `${SERVER_URL}languages/{id}`;

// TRANSLATION_KEYS
export const TRANSLATION_KEYS = `${SERVER_URL}translation-keys`;
export const TRANSLATION_KEYS_DETAIL = `${TRANSLATION_KEYS}/{id}`;
export const TRANSLATION_KEYS_EXPORTS = `${TRANSLATION_KEYS}/export`;
export const TRANSLATION_KEYS_IMPORT = `${TRANSLATION_KEYS}/import`;

// ACCEPTED TRANSACTIONS
export const ACCEPTED_REMITTANCE_BASE_URL = `${SERVER_URL}remittances`;

// MERCHANTS
export const MERCHANTS = `${SERVER_URL}merchants`;
export const MERCHANT = `${MERCHANTS}/{id}`;
export const UPDATE_MERCHANT = `${MERCHANTS}/{id}`;

// CMS PARTNERS
export const APP_PARTNERS = `${SERVER_URL}partners`;
export const ADD_PARTNER = `${SERVER_URL}partners`;
export const PARTNER = `${SERVER_URL}partners/{id}`;
export const TRANSFERS = `${SERVER_URL}transfers`;
export const SAVE_IMAGES = `${APP_PARTNERS}/{partnerId}/media-files`;
export const UPDATE_IMAGES = `${APP_PARTNERS}/{partnerId}/media-files/{id}`;

// CMS BENEFICIARIES
export const BENEFICIARIES = `${SERVER_URL}app-users`;

// OPTIONS
export const FIELD_OPTIONS = `${SERVER_URL}registered-users/{id}`;
