import { throwError } from 'rxjs';
import { timeout, catchError, map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { BaseNetworkService } from './base-network.service';
import { ApiResponseInterface } from '../interfaces/api-response.interface';

@Injectable({
  providedIn: 'root'
})
export class DataListingService extends BaseNetworkService {
  constructor(private injector: Injector) {
    super(injector);
  }

  /**
   * The following method is used to get the listing data for all the listing with pagination
   * @param {string} apiLink
   * @param {any} params
   * @param body
   * @returns {Observable<Object>}
   */
  public getDataListing(apiLink: string, params: any = {}, body: any = {}) {
    return this.http
      .post<ApiResponseInterface>(apiLink, body,
        {
          headers: this.formDataHeaders, params
        });
  }

  /**
   * The following method is used to get the listing data for all the listing with pagination
   * @param {string} apiLink
   * @param {number} pageNo
   * @param extraParams
   * @returns {Observable<Object>}
   */
  public getDataListingByGetMethod(apiLink: string, pageNo: number = this.constantList.DEFAULT_PAGE_INDEX, extraParams: any = {}) {
    return this.http
      .get<ApiResponseInterface>(apiLink,
        {
          headers: this.formDataHeaders, params: extraParams
        });
  }

  public addEntity(apiLink: string, body: any = {}, headers: HttpHeaders = this.formDataHeaders, showMessage: boolean = true) {
    return this.http
      .post(apiLink, body,
        { headers })
      .pipe(
        timeout(this.constantList.DEFAULT_HTTP_TIME_OUT),
        map((response: any) => {
          const isSuccess = response.status === this.constantList.SUCCESS_STATUS;
          if (response.message && showMessage) {
            this.showMessage(response.message, isSuccess ? 'success' : 'error');
          }
          if (isSuccess) {
            return response;
          } else {
            this.handleErrorMessages(response);
            return null;
          }
        }),
        catchError((e: any) => throwError(this.handleErrorMessages(e.error))),
      );
  }

  public getEntityDetail(apiLink: string, body: any = {}) {
    return this.http
      .post(apiLink, body,
        { headers: this.headers })
      .pipe(
        map((response: any) => {
          const isSuccess = response.status === this.constantList.SUCCESS_STATUS;
          if (response.message) {
            this.showMessage(response.message, isSuccess ? 'success' : 'error');
          }
          if (isSuccess) {
            return response.body;
          } else {
            this.handleErrorMessages(response);
            return null;
          }
        }),
        catchError((e: any) => throwError(this.handleErrorMessages(e.error))),
      );
  }

  deleteEntity(apiLink: string) {
    return this.http
      .post(apiLink, {}, { headers: this.formDataHeaders })
      .pipe(
        map((response: any) => {
          const isSuccess = response.status === this.constantList.SUCCESS_STATUS;
          if (response.message) {
            this.showMessage(response.message, isSuccess ? 'success' : 'error');
          }
          if (isSuccess) {
            return true;
          } else {
            this.handleErrorMessages(response);
            return null;
          }
        }),
        catchError((e: any) => throwError(this.handleErrorMessages(e.error))),
      );
  }

  public filterListApiResponse(response: any): any {
    if (response.status === this.constantList.SUCCESS_STATUS) {
      return response.body || [];
    } else {
      this.handleErrorMessages(response);
    }
    return [];
  }
}
