import { Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { FormInterface } from '../../../shared/interfaces/form.interface';
import { FormBaseComponent } from '../../../shared/components/base/form-base.component';
import {
  LocalStorageService,
  SessionStorageService,
} from 'src/app/shared/services';
import { ENCRYPTION_SECRET } from 'src/app/shared/constants/constant-list';

@Component({
  selector: 'app-login',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent
  extends FormBaseComponent
  implements OnInit, FormInterface
{
  private user: any = null;
  public formSubmitted = false;
  public isLoading = false;
  hide = false;

  constructor(
    injector: Injector,
    private sessionService: SessionStorageService,
    private localService: LocalStorageService
  ) {
    super(injector);
    this.user = this.userService.user;
    // if logged in simple redirect to the dashboard
    if (this.userService.isLoggedIn()) {
      this.handleRedirection();
    }
    // Set the default language
    this.translate.setDefaultLang('en');
    // Use a language
    this.translate.use('en');
  }

  ngOnInit(): void {
    this.addFormValidations();
  }

  onSubmit(): void {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.formSubmitted = true;

    if (this.formGroup.status === this.constantList.VALID_FORM_STATE) {
      const values = this.formGroup.getRawValue();
      const body = { email: values.email, password: values.password };

      this.dataSubscription$ = this.userService.login(body).subscribe({
        next: (user) => {
          if (user) {
            this.sessionService.setEncryptData(
              { key: '2FAuth', value: body },
              ENCRYPTION_SECRET
            );
            this.handleRedirection(true);
          }
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
      });
    } else {
      this.isLoading = false;
    }
  }

  /**
   * The following method is used
   * @returns {string}
   * @param formElementName
   */
  getErrorMessage(formElementName: string): string {
    const formElement = this.formGroup.get(formElementName);

    // check if any error exists or not
    if (formElement.errors) {
      // detect the required & email validation being passed on the respective form control i.e. email in this case
      if (
        formElementName === 'email' &&
        (formElement.errors.required ||
          formElement.errors.email ||
          formElement.errors.pattern)
      ) {
        return "It's required";
      }
      // detect the required validation being passed on the respective form control i.e. password in this case
      if (formElementName === 'password' && formElement.errors.required) {
        return 'Password is required';
      }
      // detect the minLength validation being passed on the respective form control i.e. password in this case
      if (formElement.errors.minlength) {
        return 'Password must be 8 - 20 characters long';
      }
    }
    return '';
  }

  addFormValidations(): void {
    const emailValidations = [
      Validators.email,
      Validators.pattern(this.constantList.REGEX_EMAIL_ADDRESS),
    ];
    const passwordValidations: ValidatorFn[] = [
      Validators.required,
      Validators.minLength(this.constantList.PASSWORD_MIN_LENGTH),
    ];
    // adding validations to the login form
    this.addFormControlWithValidations('email', emailValidations);
    this.addFormControlWithValidations('password', passwordValidations);
  }

  handleRedirection(reload: boolean = false): void {
    const redirectUri = this.localService.getDataInLocalStorage('redirect');
    if (!reload) {
      this.sharedDataService.changeFormSubmitStatus(false);
    }

    if (reload) {
      if (redirectUri) {
        window.location.replace(
          `${this.routeList.TWO_FACTOR}?redirect=${redirectUri}`
        );
        return;
      }
      window.location.replace(this.routeList.TWO_FACTOR);
    } else {
      this.goToWithoutConfirmation(this.routeList.DASHBOARD);
    }
  }
}