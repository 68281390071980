import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

type FilterProps = {
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
};

@Component({
  selector: 'app-brand-manager-dashboard-date-filter',
  templateUrl: './brand-manager-dashboard-date-filter.component.html',
  styleUrls: ['./brand-manager-dashboard-date-filter.component.scss'],
})
export class BrandManagerDashboardDateFilterComponent implements OnInit {
  @Output() onApply: EventEmitter<any> = new EventEmitter<any>();

  filterCallback: FilterProps = {
    fromDate: null,
    toDate: null,
  };

  constructor(
    public dialogRef: MatDialogRef<BrandManagerDashboardDateFilterComponent>
  ) {}

  ngOnInit(): void {}

  onSelect(which: 'from' | 'to', date: NgbDate) {
    if (which === 'from') {
      this.filterCallback.fromDate = date;
    } else if (which === 'to') {
      this.filterCallback.toDate = date;
    }
  }

  onApplyFilterDate() {
    this.onApply.emit(0);
    this.dialogRef.close(this.filterCallback);
  }

  onCancel() {
    this.dialogRef.close(null);
  }
}
