import { Injectable } from '@angular/core';
import { UserService } from './user.service';

import * as CONSTANT_LIST from '../constants/constant-list';
import { LocalStorageService } from './local-storage.service';
import { IFormattedModules } from '../interfaces/RolesAccess';
import { Store } from '@ngrx/store';
import { selectAllPermissions } from '../stores/slices/Permissions';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private constantList = CONSTANT_LIST;
  private permissions: IFormattedModules[] = [];

  constructor(
    private userService: UserService,
    private localService: LocalStorageService,
    private store: Store
  ) {
    // this.permissions = this.localService.getEncryptedData('permissions', CONSTANT_LIST.ENCRYPTION_SECRET);
    this.store.select(selectAllPermissions).subscribe((response) => {
      this.permissions = response;
    });
  }

  private validRole(): boolean {
    if (!this.userService.user || !this.userService.user.position) {
      return false;
    }

    const role: string | null = this.userService.user.position;
    if (!role) {
      return false;
    }

    const index: number = this.constantList.ROLES.map((r: any) => {
      return r.value;
    }).indexOf(role);

    return index !== -1;
  }

  checkModulePermission(
    moduleName: string,
    permission: string = this.constantList.PERMISSION_READ
  ): boolean {
    if (this.isSuperAdmin()) {
      return true;
    } else if (!this.permissions || !moduleName) {
      return false;
    }

    const perm = this.userService.userPrivileges.find(
      (p) => p.sub_module_name == moduleName
    );
    if (!perm) {
      return false;
    }
    return perm.privileges ? !!perm.privileges[permission] : false;
  }

  canAccessModule(
    moduleName: string,
    permission: string = this.constantList.PERMISSION_READ
  ): boolean {
    return this.checkModulePermission(moduleName, permission);
  }

  public isSuperAdmin(): boolean {
    const user: any = this.userService.user;
    return user?.position === this.constantList.ROLE_ADMIN;
  }

  public hasRole(role: string): boolean {
    if (!this.userService.user || !this.userService.user.role) {
      return false;
    }
    return this.userService.user.role === role;
  }

  public hasRoles(roles: string[] = []): boolean {
    if (!this.userService.user || !this.userService.user.role) {
      return false;
    }
    return roles.indexOf(this.userService.user.role) > -1;
  }
}