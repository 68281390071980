<a
  *ngIf="un_registered_user_name == ''"
  class="link-bold-underline purpl-table-open-new-tab"
  [routerLink]="['/users/app-users/', user_id]"
  target="_blank"
>
  {{ label }}
</a>
<a
  *ngIf="un_registered_user_name"
  target="_blank"
  [routerLink]="['/users/unregistered-app-users/', receiver_phone_number]"
  class="link-bold-underline"
>
  {{ label }}
</a>
<a
  *ngIf="registered_user_name && un_registered_user_name == null"
  class="link-bold-underline"
  [routerLink]="['/users/app-users/', user_id]"
  target="_blank"
>
  {{ label }}
</a>
<a
  *ngIf="user_dto_id"
  class="link-bold-underline"
  [routerLink]="['/users/app-users/', user_dto_id]"
  target="_blank"
>
  {{ label }}
</a>
