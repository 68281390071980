import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-purpl-table-more-details',
  templateUrl: './purpl-table-more-details.component.html',
  styleUrls: ['./purpl-table-more-details.component.scss'],
})
export class PurplTableMoreDetailsComponent implements OnInit {
  @Output() onPress: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}
}
