import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PipeTransform,
  TemplateRef,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {
  DataService,
  HelperService,
  LocalStorageService,
  PermissionService,
} from '../../services';
import * as CONSTANT from '../../constants/constant-list';
import * as apiList from '../../constants/apis-list';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../interfaces/User';
import FEATURES from '../../constants/navigation';

@Component({
  selector: 'app-table',
  templateUrl: './purpl-table.component.html',
  styleUrls: ['./purpl-table.component.scss'],
})
export class GenericTableComponent implements OnInit {
  @Input() tableColumns: any[] = [];
  @Input() hideFilter = false;
  @Input() responsiveTable = false;
  @Input() showStarIcon = false;
  @Input() showDelete = false;
  @Input() filters = false;
  @Input() pendingVisits = false;
  @Input() recentlyVisited = false;
  @Input() hideLoader = false;
  @Input() stopLoadUp = false;
  @Input() pageView: string | null = null;
  @Input() page?: number;
  @Input() pageLimit?: number;
  @Input() user: User | null = null;
  private eventsSubscription?: Subscription;
  @Input() eventsSub?: Observable<any>;
  @Input() sortingKey?: string;
  @Input() isSortedDesc?: boolean;
  @Input() popUpContent?: TemplateRef<any>;
  /**
   * Optional Request body
   * @type {{}}
   */
  @Input() requestBody: any = {};

  /**
   * API configuration object
   * @type {url: string; endpoint: string; method: string; contentType: string}
   */
  @Input()
  endPointConfiguration: {
    url: string;
    endpoint: string;
    method: string;
    contentType: string;
  } = {
    url: '',
    endpoint: '',
    method: 'POST',
    contentType: 'application/json',
  };

  public sortKey: string = CONSTANT.DEFAULT_SORT_KEY;
  public sortOrder: string = CONSTANT.DEFAULT_SORT_ORDER;
  public ROLE_ADMIN: string = CONSTANT.ROLE_ADMIN;
  public BLF_BRAND_MANAGER: string = CONSTANT.BLF_BRAND_MANAGER;
  public ROLE_BRAND_MANAGER: string = CONSTANT.ROLE_BRAND_MANAGER;
  public USER_STATUS_ID: any = CONSTANT.USER_STATUS_ID;

  params: any = {};
  hasMore = false;
  isLoading = false;
  lastPage: any;
  totalNumberOfPages = 1;
  totalNumberOfRecords = 0;

  brandForm: any;
  appUserList: any = {};
  pendingOrRecently: any;
  searchValue: any;
  tableSearching: any;
  tableFilters: any[] = [];
  comment: any = '';
  commentText: any = '';
  defaultPageLimit = 10;

  @Output() onButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() checkboxCheckedUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickedOnRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickedOnLink: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickedOnStatus: EventEmitter<any> = new EventEmitter<any>();
  @Output() moreDetailsButtonClicked: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() pendingFilterCount: EventEmitter<any> = new EventEmitter<any>();
  @Output() bookedSpotsCount: EventEmitter<any> = new EventEmitter<any>();
  @Output() cashoutBalance: EventEmitter<any> = new EventEmitter<any>();
  @Output() exportDetails: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPurplCommentClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPurpleViewMoreCommentClick: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() onPurpleUpdateCommentClick: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() onBLFCommentClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBLFUpdateCommentClick: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() onBLFViewMoreCommentClick: EventEmitter<any> =
    new EventEmitter<any>();

  @Output() onPageLimitChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPageChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSortChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() onTogglePopover: EventEmitter<any> = new EventEmitter<any>();

  private tableDataSubscription$?: Subscription;
  public tableDataList: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public tableDataListItems: any[] = [];
  filterSearchText = new Subject<any>();

  public PERMISSION: any = CONSTANT.PERMISSION;
  public FEATURES: any = FEATURES;

  constructor(
    private modalService: NgbModal,
    private dataService: DataService,
    private localStorage: LocalStorageService,
    public toastrService: ToastrService,
    private router: Router,
    private permissionService: PermissionService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.filterSearchText
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((value) => {
        this.onSearchInputChanged(value.elem, value.value, value.filter);
      });
  }

  ngOnInit(): void {
    const option: any = {};
    option.body = { size: CONSTANT.NUMBER_RECORDS_PER_PAGE };
    option.body[CONSTANT.SEARCH] = '';
    if (this.sortingKey) {
      option.sort_by = this.sortingKey;
    } else if (this.sortKey) {
      option.sort_by = this.sortKey;
    }

    if (this.sortOrder) {
      option.sort_order = this.sortOrder;
    }

    // if the requestBody is set
    if (this.requestBody) {
      Object.entries(this.requestBody).forEach(([key, value]) => {
        if (typeof value !== 'undefined') {
          option.body[key] = value;
        }
      });
    }

    if (!this.stopLoadUp) {
      this.loadResource(option);
    }

    if (this.filters) {
      this.eventsSubscription = this.eventsSub?.subscribe((action) => {
        if (action.origin === 'brand-form') {
          this.brandForm = { value: action.value, key: 'pending' };
        } else if (action.origin === 'app-user-list') {
          if (action.key === 'status_id') {
            if (action.value === null) {
              this.appUserList.status = {
                selected: false,
                value: action.value,
                key: 'status_id',
              };
            } else {
              this.appUserList.status = {
                selected: true,
                value: action.value,
                key: 'status_id',
              };
            }
          } else if (action.key === 'account_non_locked') {
            if (action.value === null) {
              this.appUserList.blocked = {
                selected: false,
                value: action.value,
                key: 'account_non_locked',
              };
            } else {
              this.appUserList.blocked = {
                selected: true,
                value: action.value,
                key: 'account_non_locked',
              };
            }
          }
        } else if (action.origin === 'pending-visits') {
          this.pendingOrRecently = {
            selected: true,
            key1: 'date_from',
            key2: 'date_to',
            value1: action.value[0].dateFrom,
            value2: action.value[0].dateTo,
          };
        } else if (
          action.origin === 'recently-visited' ||
          action.origin === 'recently-visited-status'
        ) {
          this.pendingOrRecently = {
            selected: false,
            key1: 'date_from',
            key2: 'date_to',
            value1: action.value[0].dateFrom,
            value2: action.value[0].dateTo,
            key3: 'transaction_status_ids',
            value3: action.filter,
          };
        } else if (action.origin === 'pending-or-recently-search') {
          this.searchValue = {
            selected: true,
            value: action.value,
            key: 'search',
          };
        } else if (action.origin === 'received-transactions-search') {
          this.searchValue = {
            selected: true,
            value: action.value,
            key: 'search',
          };
        } else if (action.origin === 'received-transactions-filter') {
          if (action?.value) {
            this.pendingOrRecently = {
              selected: false,
            };

            if (action?.value?.dateFrom) {
              this.pendingOrRecently = {
                ...this.pendingOrRecently,
                key1: 'date_from',
                value1: action?.value?.dateFrom,
              };
            }

            if (action?.value?.dateTo) {
              this.pendingOrRecently = {
                ...this.pendingOrRecently,
                key2: 'date_to',
                value2: action?.value?.dateTo,
              };
            }

            if (action?.value?.type) {
              this.pendingOrRecently = {
                ...this.pendingOrRecently,
                key3: 'date_search_type',
                value3: action?.value?.type,
              };
            }
          } else {
            this.pendingOrRecently = {
              selected: false,
            };
          }
        }
        if (action.origin === 'brand-form-count') {
          this.pendingCount(action.value);
        } else {
          this.loadResourcesPage();
        }
      });
    }
  }

  loadResourcesPage(pageIndex?: any): void {
    console.log('loadResourcesPage');
    const index = pageIndex !== 'undefined' ? pageIndex : this.page;
    if (!this.params[CONSTANT.SEARCH]) {
      this.params[CONSTANT.SEARCH] = '';
    }

    const body: any = { ...this.params };
    body.size = this.pageLimit || this.defaultPageLimit;
    if (this.sortingKey) {
      body.sort_by = this.sortingKey;
    } else if (this.sortKey) {
      body.sort_by = this.sortKey;
    }
    if (this.sortOrder) {
      body.sort_order = this.sortOrder;
    }
    if (Object.keys(this.requestBody).length > 0) {
      for (const property in this.requestBody) {
        body[property] = this.requestBody[property];
        this.params[property] = body[property];
      }
    }

    this.loadResource({ pageIndex: index, body });
  }

  loadResource({
    pageIndex,
    body = this.requestBody,
    filters = {},
  }: {
    pageIndex?: number;
    body?: any;
    filters?: object;
  }): void {
    console.log('loadResource');
    this.isLoading = true;
    const api = this.endPointConfiguration.url;
    const url = this.router.url;

    if (!pageIndex) {
      pageIndex = 1;
    }

    let params: HttpParams = new HttpParams();
    if (filters) {
      Object.entries(filters).forEach(([key, value]) => {
        if (typeof value !== 'undefined') {
          params = params.append(key, value);
        }
      });
    }

    if (!body || !body.page) {
      body.page = pageIndex;
    }

    if (this.tableDataSubscription$) {
      this.tableDataSubscription$.unsubscribe();
    }

    // Below are the checks for filters from different component
    if (this.brandForm) {
      if (body[this.brandForm.key] === this.brandForm.value) {
        body[this.brandForm.key] = null;
      } else {
        body[this.brandForm.key] = true;
      }
    }

    if (this.appUserList.status) {
      if (this.appUserList?.status?.selected) {
        body[this.appUserList.status.key] = this.appUserList.status.value;
      } else {
        delete body[this.appUserList.status.key];
      }
    }

    if (this.appUserList.blocked) {
      if (this.appUserList?.blocked?.selected) {
        body[this.appUserList.blocked.key] = this.appUserList.blocked.value;
      } else {
        delete body[this.appUserList.blocked.key];
      }
    }

    if (this.searchValue) {
      if (this.searchValue?.selected) {
        body[this.searchValue?.key] = this.searchValue?.value;
      }
    }

    if (this.pendingOrRecently) {
      if (this.pendingOrRecently?.selected) {
        body[this.pendingOrRecently?.key1] = this.pendingOrRecently?.value1;
        body[this.pendingOrRecently?.key2] = this.pendingOrRecently?.value2;
      } else {
        body[this.pendingOrRecently?.key1] = this.pendingOrRecently?.value1;
        body[this.pendingOrRecently?.key2] = this.pendingOrRecently?.value2;
        body[this.pendingOrRecently?.key3] = this.pendingOrRecently?.value3;
      }
    }

    if (this.tableSearching?.selected) {
      if (this.tableSearching?.multiple) {
        body[this.tableSearching?.key1] = this.tableSearching?.value1;
        body[this.tableSearching?.key2] = this.tableSearching?.value2;
      } else {
        body[this.tableSearching?.key] = this.tableSearching?.value;
      }
    }

    if (this.tableFilters && this.tableFilters.length) {
      if (api.includes('api/senders') && url === '/users/senders') {
        this.tableFilters.forEach((item, index) => {
          Object.entries(item).forEach(([key, value]) => {
            if (typeof value !== 'undefined') {
              console.log('key: ', key);
              console.log('value: ', value);
              params = params.append(
                String(`fields[${index}].${key}`),
                String(value)
              );
            }
          });
        });
        console.log('params: ', params.toString());
      } else {
        body['fields'] = this.tableFilters;
      }
    }

    if (this.tableSearching?.selected) {
      if (api.includes('api/users/listing') && url === '/users/cms-users') {
        body.active = this.localStorage.get('active');
        body.position = this.localStorage.get('position');
      } else if (
        (api.includes('api/pricing-model/listing') ||
          api.includes('api/pricing-model/transaction-listing')) &&
        (url === '/received-transactions' ||
          url === '/revenue-transactions' ||
          url === '/cashout-transactions')
      ) {
        body.transaction_status = this.localStorage.get('transaction_status');
        body.user_status = this.localStorage.get('user_status');
        body.remittance_platform_name = this.localStorage.get(
          'remittance_platform_name'
        );
        body.code = this.localStorage.get('code');
        body.branch_dto = this.localStorage.get('branch_dto');
      }
    }

    if (
      this.endPointConfiguration.url.includes('api/transactions/listing') ||
      this.endPointConfiguration.url.includes('api/pricing-model/listing') ||
      this.endPointConfiguration.url.includes(
        'api/pricing-model/transaction-listing'
      )
    ) {
      this.exportDetails.emit(body);
    }

    this.tableDataSubscription$ = this.dataService
      .fetchData({
        apiUrl: this.endPointConfiguration.url,
        method: this.endPointConfiguration.method,
        contentType: this.endPointConfiguration.contentType,
        params,
        body,
      })
      .pipe(
        map((response) => {
          if (response) {
            if (response.data) {
              return response.data;
            } else if (response.items) {
              return response;
            }
          }
          return null;
        }),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((resource: any) => {
        if (resource && !Array.isArray(resource)) {
          if (this.filters) {
            this.pendingFilterCount.emit({ count: resource.pending_count });
          }
          const totalPages = Math.ceil(resource.total_elements / resource.size);
          this.page = resource.number === 0 ? 1 : resource.number;
          this.lastPage = totalPages;
          this.totalNumberOfPages = totalPages;
          this.totalNumberOfRecords = resource.total_elements;

          if (this.pendingVisits || this.recentlyVisited) {
            this.cashoutBalance.emit(
              resource.cashout_balance === null ? 0 : resource.cashout_balance
            );
            this.bookedSpotsCount.emit(resource.total_elements);
          }
          this.hasMore = 0 <= totalPages - Number(this.page);

          if (resource.items && Array.isArray(resource.items)) {
            this.tableDataListItems = resource.items;
            this.tableDataList.next(this.tableDataListItems);
          } else if (resource.content && Array.isArray(resource.content)) {
            this.tableDataListItems = resource.content;
            this.tableDataList.next(this.tableDataListItems);
          } else if (resource.body && Array.isArray(resource.body)) {
            this.tableDataListItems = resource.body;
            this.tableDataList.next(this.tableDataListItems);
          }
        } else if (resource && Array.isArray(resource)) {
          this.tableDataListItems = resource;
          this.tableDataList.next(this.tableDataListItems);
        }
      });
  }

  constructNestedObject(
    element: any,
    column: {
      key: string;
      value: string;
      type: string;
      map: any;
      callback: Function;
    }
  ) {
    if (!column.key && !column.callback) {
      return '';
    } else if (!column.key && column.callback) {
      return column.callback.call(column.callback, element as any);
    }

    const key =
      column.key.split('.').length > 1
        ? this.getNestedPropertyValue(element, column.key)
        : column.key;
    if (key && key !== column.key) {
      return column.map ? (column.map[key] ? column.map[key] : key) : key;
    }

    if ((!key || !element[key]) && !column.callback && !column.map) {
      return element[key] != undefined && element[key] != null
        ? element[key]
        : '';
    }

    if ((!key || !element[key]) && column.callback) {
      return column.callback.call(column.callback, element as any);
    }

    if (column.key.indexOf('.') > -1 && !column.map) {
      return key;
    }

    if (!element[key] && !column.map) {
      return element[key] != undefined && element[key] != null
        ? element[key]
        : '';
    }

    if (column.callback) {
      return column.callback.call(column.callback, (element as any)[key]);
    }

    return column.map
      ? column.map[key]
        ? column.map[key]
        : column.map[element[key]]
      : element[key];
  }

  constructImage(element: any, column: { key: string }): any {
    return element[column.key];
  }

  getNestedPropertyValue(
    theObject: any,
    path: string,
    separator = '.'
  ): string {
    try {
      separator = separator || '.';

      return path
        .replace('[', separator)
        .replace(']', '')
        .split(separator)
        .reduce(function (obj, property) {
          return obj[property];
        }, theObject);
    } catch (err) {
      return '';
    }
  }

  sortTable(column: any) {
    if (column.sortable) {
      this.sortKey = column.sort_key || column.key;
      this.sortOrder =
        this.sortOrder === CONSTANT.DEFAULT_SORT_ORDER
          ? 'asc'
          : CONSTANT.DEFAULT_SORT_ORDER;

      this.page = CONSTANT.DEFAULT_PAGE_INDEX;
      this.loadResourcesPage();
    }
  }

  updateTableColumnTextOnLanguageChanged(): void {
    this.tableColumns.forEach((column: { key: string; value: string }) => {
      if (this.document.getElementById(column.key)) {
        this.document.getElementById(column.key).innerHtml = column.value;
        this.document.getElementById(column.key).innerText = column.value;
      }
    });
  }

  onCheckboxChanged(item: any, elem: any, checked: boolean): void {
    item.active = checked;
    this.checkboxCheckedUpdated.emit({ item, element: elem, checked });
  }

  onClickedRow(item: any): void {
    this.clickedOnRow.emit(item);
  }

  emitHyperLinkEvent(item: any): void {
    this.clickedOnLink.emit(item);
  }

  emitVerifyStatusClick(item: any): void {
    this.clickedOnStatus.emit(item);
  }

  onAcceptBtnClicked(item: any): void {
    item.active = true;
    this.checkboxCheckedUpdated.emit({ checked: true, item });
  }

  onDeleteButtonClicked(item: any): void {
    this.onDeleteClick.emit(item);
  }

  onPurplCommentClicked(item: any): void {
    this.onPurplCommentClick.emit(item);
  }

  onPurpleViewMoreCommentClicked(item: any): void {
    this.onPurpleViewMoreCommentClick.emit(item);
  }

  onPurpleUpdateCommentClicked(item: any): void {
    this.onPurpleUpdateCommentClick.emit(item);
  }

  onBLFCommentClicked(item: any): void {
    this.onBLFCommentClick.emit(item);
  }

  onBLFUpdateCommentClicked(item: any): void {
    this.onBLFUpdateCommentClick.emit(item);
  }

  onBLFViewMoreCommentClicked(item: any): void {
    this.onBLFViewMoreCommentClick.emit(item);
  }

  onSearchInputChanged(elem: any, value: any, filter?: string): void {
    value = value.toLowerCase();
    if (elem.type != 'toggle') {
      if (elem.value === 'Cashout point type') {
        value === 'a' ? (value = 2) : 1;
        value === 'at' ? (value = 2) : 1;
        value === 'atm' ? (value = 2) : 1;
        value === 'o' ? (value = 1) : 2;
        value === 'ot' ? (value = 1) : 2;
        value === 'otc' ? (value = 1) : 2;
        this.tableSearching = {
          selected: true,
          key: 'branch_type_id',
          value,
        };
      } else {
        this.tableSearching = { selected: true, key: 'search', value, filter };
        const appIndex = this.tableFilters.findIndex((i) => i.field === filter);
        if (appIndex === -1) {
          this.tableFilters.push({
            field: filter,
            search: value,
          });
        } else {
          this.tableFilters[appIndex].field = filter;
          this.tableFilters[appIndex].search = value;
          this.tableFilters = this.tableFilters.filter((i) => i.search !== '');
        }
      }
      this.loadResourcesPage();
    } else {
      if (value === 'all') {
        if (elem.key === 'user_status') {
          this.localStorage.set({ key: 'user_status', value: null });
          this.tableSearching = {
            selected: true,
            key: 'user_status',
            value: null,
          };
        } else if (elem.key === 'transaction_status') {
          this.localStorage.set({ key: 'transaction_status', value: null });
          this.tableSearching = {
            selected: true,
            key: 'transaction_status',
            value: null,
          };
        } else if (elem.key === 'remittance_platform_name') {
          this.localStorage.set({
            key: 'remittance_platform_name',
            value: null,
          });
          this.tableSearching = {
            selected: true,
            key: 'remittance_platform_name',
            value: null,
          };
        } else if (elem.key === 'code') {
          this.localStorage.set({
            key: 'code',
            value: null,
          });
          this.tableSearching = {
            selected: true,
            key: 'code',
            value: null,
          };
        } else if (elem.key === 'branch_dto') {
          this.localStorage.set({
            key: 'branch_dto',
            value: null,
          });
          this.tableSearching = {
            selected: true,
            key: 'branch_dto',
            value: null,
          };
        } else if (elem.value === 'Role') {
          this.localStorage.set({
            key: 'position',
            value: ['brand_manager', 'purpl_admin'],
          });
        } else if (elem.value === 'Status') {
          this.localStorage.set({ key: 'active', value: null });
        }
        this.tableSearching = { selected: true, key: 'active', value: null };
      } else if (elem.value === 'Role') {
        this.localStorage.set({
          key: 'position',
          value: [value],
        });
        this.tableSearching = {
          selected: true,
          key: 'position',
          value: [value],
        };
      } else if (value === 'null') {
        this.tableSearching = {
          selected: true,
          multiple: true,
          key1: 'active',
          value1: null,
          key2: 'pending',
          value2: true,
        };
      } else if (elem.key === 'user_status') {
        this.localStorage.set({ key: 'user_status', value });
        this.tableSearching = {
          selected: true,
          key: 'user_status',
          value,
        };
      } else {
        this.localStorage.set({ key: 'active', value });
        if (elem.key === 'transaction_status') {
          this.localStorage.set({ key: 'transaction_status', value });
          this.tableSearching = {
            selected: true,
            key: 'transaction_status',
            value,
          };
        } else if (elem.key === 'remittance_platform_name') {
          this.localStorage.set({ key: 'remittance_platform_name', value });
          this.tableSearching = {
            selected: true,
            key: 'remittance_platform_name',
            value,
          };
        } else if (elem.key === 'code') {
          this.localStorage.set({ key: 'code', value });
          this.tableSearching = {
            selected: true,
            key: 'code',
            value,
          };
        } else if (elem.key === 'branch_dto') {
          this.localStorage.set({ key: 'branch_dto', value });
          this.tableSearching = {
            selected: true,
            key: 'branch_dto',
            value,
          };
        } else {
          this.tableSearching = {
            selected: true,
            key: 'active',
            value,
            filter,
          };
        }
      }

      this.loadResourcesPage();
      // if (!value) {
      //   this.tableDataList.next(this.tableDataListItems);
      // } else {
      //   this.tableDataList.next(this.search(elem, value));
      // }
    }
  }

  search(elem: any, text: any = '', pipe?: PipeTransform): any[] {
    if (elem.type === 'toggle') {
      if (text === 'null') {
        return this.tableDataListItems.filter(
          (item) => item[elem.key] === null
        );
      } else {
        return this.tableDataListItems.filter((item) => {
          return text === 'all'
            ? true
            : item[elem.key] === Boolean(JSON.parse(text));
        });
      }
    }
    return this.tableDataList.value.filter((item) => {
      let value = this.constructNestedObject(item, elem);
      const term = '' + text.toLowerCase();
      if (value) {
        value = '' + value;
      }
      return value?.toLowerCase().includes(term) ||
        value === term ||
        pipe?.transform(value).includes(term)
        ? true
        : false;
    });
  }

  onBlockBtn(data: any, isBlocked: boolean) {
    this.checkboxCheckedUpdated.emit({ checked: isBlocked, item: data });
    data.account_non_locked = isBlocked;
  }

  resolveRating(data: any, type: any) {
    data.feedback_status_dto.feedback_status = 'Resolved';
    data.isRating = type;
    this.checkboxCheckedUpdated.emit(data);
  }

  moreDetails(data: any) {
    this.clickedOnRow.emit(data);
    this.moreDetailsButtonClicked.emit(data);
  }

  onPageLimitChanged($event: any) {
    this.onPageLimitChange.emit($event);

    setTimeout(() => {
      this.loadResourcesPage();
    }, 600);
  }

  pendingCount(id: any) {
    this.dataService
      .fetchData({
        apiUrl: apiList.BRANCH_PENDING_COUNT.replace('{id}', id),
        method: 'POST',
        contentType: 'application/json',
        params: null,
        body: null,
      })
      .pipe(
        map((response) => {
          return response && response.data ? response.data : null;
        })
      )
      .subscribe((response) => {
        this.pendingFilterCount.emit({ count: response?.pending_count });
      });
  }

  open(content: any, data: any, value: any) {
    if (data?.comment != null) {
      this.comment = data?.comment;
    }
    this.commentText = this.comment + '';
    this.modalService
      .open(content, { backdrop: 'static', centered: true })
      .result.then(
        (result) => {
          if (result === 'Yes') {
            this.onBlockBtn(data, value);
          } else if (result === 'Save Comment') {
            let commentData = {};
            if (data?.rate_type) {
              commentData = {
                comment: this.comment,
                id: data?.id,
                type: 'rating',
              };
            } else {
              commentData = {
                comment: this.comment,
                id: data?.id,
                type: 'feedback',
              };
            }
            this.addComment(commentData);
          }
        },
        (reason) => {}
      );
  }

  commentChange(value: string) {
    this.comment = value;
  }

  addComment(body: any) {
    this.dataService
      .fetchData({
        apiUrl: apiList.COMMENT_ADD_UPDATE,
        method: 'PUT',
        contentType: 'application/json',
        params: null,
        body,
      })
      .subscribe((response) => {
        if (response?.status === 'success') {
          this.toastrService.success(response.message, 'Success');
          this.loadResourcesPage();
        } else {
          this.toastrService.error('There is some problem', 'Failed');
        }
      });
  }

  ngOnDestroy(): void {
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
    this.localStorage.remove('active');
    this.localStorage.remove('position');
    this.localStorage.remove('user_status');
    this.localStorage.remove('transaction_status');
    this.localStorage.remove('remittance_platform_name');
    this.localStorage.remove('code');
    this.localStorage.remove('branch_dto');
  }

  checkAccessModule(
    moduleName: string,
    permission: string = CONSTANT.PERMISSION_READ
  ): boolean {
    return this.permissionService.canAccessModule(moduleName, permission);
  }

  formatUserPosition(position: string): string {
    const word = position.replace(/_/g, ' ');
    return HelperService.capitalizeTheFirstLetterOfEachWord(word);
  }
}
