<div class="text-right px-2 pt-2 cursor" mat-dialog-close>
  <img
    class="w-15"
    src="/assets/images/purpl/close-icon.png"
    alt="close-icon"
  />
</div>
<div class="col-md-12 px-4 pb-4">
  <div
    ngbNav
    #nav="ngbNav"
    class="tab-coupon profile-tabs brand-manager-dashboard"
  >
    <ng-container *ngIf="action !== 'accepted'" ngbNavItem>
      <a ngbNavLink> Personal Details </a>
      <ng-template ngbNavContent>
        <div class="col-md-12 d-flex p-0 mt-4 brand-personal-details">
          <div class="col-md-2 pl-0 right-border">
            <div class="transaction-details-heading mb-3">Personal Details</div>
            <div class="user-icon mb-3">
              <img
                class="user-image"
                [src]="
                  jumio?.user?.image
                    ? jumio?.user?.image
                    : 'assets/images/dashboard/man.png'
                "
                alt="#"
              />
            </div>

            <div class="fixed-details-height">
              <div class="sub-details-heading">Phone Number</div>
              <div class="sub-details-val">
                {{ jumio?.user?.username || "---" }}
              </div>
            </div>
          </div>
          <div class="col-md-10 mb-4">
            <div class="transaction-details-heading mb-3">
              <img
                src="assets/images/purpl/jumio-2x.png"
                alt="jumio-logo"
                class="mr-2"
              />
              Jumio Details {{ using_jumio_v2 ? "V2" : null }}
            </div>

            <div class="col-md-12 d-flex p-0">
              <div class="col-md-5 p-0">
                <div
                  class="verification"
                  [ngClass]="{
                    'not-verified': !getVerificationStatus()
                  }"
                >
                  <div class="status">
                    <span>
                      {{
                        getVerificationStatus() ? "Verified" : "Not Verified"
                      }}
                    </span>
                  </div>
                  <div class="d-flex">
                    <div class="col-6 p-0">
                      <div class="sub-details-heading">ID</div>
                      <div class="sub-details-heading-sm">
                        {{ getIDStatus(jumioUser) }}
                      </div>
                    </div>
                    <div class="col-6 p-0">
                      <div class="ml-3 sub-details-heading">Identity</div>
                      <div class="ml-3 sub-details-heading-sm">
                        {{ getIdentityStatus(jumioUser) }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="col-6 py-3 px-0">
                    <div class="sub-details-heading-sm">USER DATA</div>
                  </div>
                  <div class="col-6 py-3 px-0">
                    <div class="ml-3 sub-details-heading-sm">DOCUMENT DATA</div>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="col-6 p-0">
                    <div class="fixed-details-height">
                      <div class="sub-details-heading">First Name</div>
                      <div class="sub-details-val">
                        {{ getFirstName(jumioUser) }}
                      </div>
                    </div>
                    <div class="fixed-details-height">
                      <div class="sub-details-heading">Last Name</div>
                      <div class="sub-details-val">
                        {{ getLastName(jumioUser) }}
                      </div>
                    </div>
                    <div class="fixed-details-height">
                      <div class="sub-details-heading">DOB</div>
                      <div class="sub-details-val">
                        {{ getDateOfBirth(jumioUser) }}
                      </div>
                    </div>
                    <div class="fixed-details-height">
                      <div class="sub-details-heading">City</div>
                      <div class="sub-details-val">
                        {{ (jumioUser?.idCity | titlecase) || "---" }}
                      </div>
                    </div>
                    <div class="fixed-details-height">
                      <div class="sub-details-heading">Country</div>
                      <div class="sub-details-val">
                        {{ getIssuingCountry(jumioUser) }}
                      </div>
                    </div>
                  </div>
                  <div class="col-6 p-0">
                    <div class="fixed-details-height">
                      <div class="ml-3 sub-details-heading">ID Type</div>
                      <div class="ml-3 sub-details-val">
                        {{ getIDType(jumioUser) }}
                      </div>
                    </div>
                    <div class="fixed-details-height">
                      <div class="ml-3 sub-details-heading">ID Subtype</div>
                      <div class="ml-3 sub-details-val">
                        {{ getIDSubType(jumioUser) }}
                      </div>
                    </div>
                    <div class="fixed-details-height">
                      <div class="ml-3 sub-details-heading">
                        Issuing Country
                      </div>
                      <div class="ml-3 sub-details-val">
                        {{ getIssuingCountry(jumioUser) }}
                      </div>
                    </div>
                    <div class="fixed-details-height">
                      <div class="ml-3 sub-details-heading">Expiry Date</div>
                      <div class="ml-3 sub-details-val">
                        {{ getExpiryDate(jumioUser) }}
                      </div>
                    </div>
                    <div class="fixed-details-height">
                      <div class="ml-3 sub-details-heading">
                        Document Number
                      </div>
                      <div class="ml-3 sub-details-val">
                        {{ getDocumentNumber(jumioUser) }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex pt-4">
                  <div class="col-4 p-0">
                    <div class="fixed-details-height">
                      <div class="sub-details-heading">Scan Reference</div>
                    </div>
                  </div>
                  <div class="col-8 p-0">
                    <div class="fixed-details-height">
                      <div
                        class="d-flex flex-row align-items-center cursor"
                        (click)="copyText(getScanReference())"
                      >
                        <div class="sub-details-heading">
                          {{ getScanReference() }}
                        </div>
                        <i class="fa fa-clipboard" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="col-4 p-0">
                    <div class="fixed-details-height">
                      <div class="sub-details-heading">Purpl User ID</div>
                    </div>
                  </div>
                  <div class="col-8 p-0">
                    <div class="fixed-details-height">
                      <div class="d-flex flex-row align-items-center cursor">
                        <div class="sub-details-heading">{{ userCode }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-7 p-0">
                <div class="col-md-12 mb-2">
                  <div class="d-flex">
                    <div
                      class="img-placeholder"
                      *ngIf="verificationImages?.front"
                    >
                      <img
                        #frontImg
                        [src]="verificationImages?.front"
                        alt="front"
                        class="verification-img"
                        (error)="
                          frontImg.src = './assets/images/product-list/1.jpg'
                        "
                        (click)="
                          lgImage.img = verificationImages?.front;
                          lgImage.class = 'cover'
                        "
                      />
                    </div>
                    <div
                      class="img-placeholder"
                      *ngIf="verificationImages?.back"
                    >
                      <img
                        #backImg
                        [src]="verificationImages?.back"
                        alt="back"
                        class="verification-img"
                        (error)="
                          backImg.src = './assets/images/product-list/1.jpg'
                        "
                        (click)="
                          lgImage.img = verificationImages?.back;
                          lgImage.class = 'cover'
                        "
                      />
                    </div>
                    <div
                      class="img-placeholder"
                      *ngIf="verificationImages?.face"
                    >
                      <img
                        #faceImg
                        [src]="verificationImages?.face"
                        alt="face"
                        class="verification-img"
                        (error)="
                          faceImg.src = './assets/images/product-list/1.jpg'
                        "
                        (click)="
                          lgImage.img = verificationImages?.face;
                          lgImage.class = 'scale'
                        "
                      />
                    </div>
                    <div
                      class="img-placeholder"
                      *ngIf="verificationImages?.original"
                    >
                      <img
                        #originalImg
                        [src]="verificationImages?.original"
                        alt="original"
                        class="verification-img"
                        (error)="
                          originalImg.src = './assets/images/product-list/1.jpg'
                        "
                        (click)="
                          lgImage.img = verificationImages?.original;
                          lgImage.class = 'scale'
                        "
                      />
                    </div>
                    <div
                      class="img-placeholder"
                      *ngIf="verificationImages?.photo"
                    >
                      <img
                        #photoImg
                        [src]="verificationImages?.photo"
                        alt="photo"
                        class="verification-img"
                        (error)="
                          photoImg.src = './assets/images/product-list/1.jpg'
                        "
                        (click)="
                          lgImage.img = verificationImages?.photo;
                          lgImage.class = 'scale'
                        "
                      />
                    </div>
                    <ng-container *ngIf="livenessImages">
                      <div
                        class="img-placeholder"
                        *ngFor="let image of livenessImages"
                      >
                        <img
                          #livenessImg
                          [src]="image.image"
                          alt="liveness"
                          class="verification-img"
                          (error)="
                            livenessImg.src =
                              './assets/images/product-list/1.jpg'
                          "
                          (click)="
                            lgImage.img = image.image; lgImage.class = 'scale'
                          "
                        />
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="lg-img-placeholder">
                    <img
                      [src]="
                        lgImage?.img
                          ? lgImage?.img
                          : './assets/images/product-list/1.jpg'
                      "
                      alt="large-img"
                      class="lg-verification-img"
                      [ngClass]="{
                        cover: lgImage.class == 'cover',
                        scale: lgImage.class == 'scale'
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>

    <ng-container ngbNavItem>
      <a ngbNavLink> Transaction Details </a>
      <ng-template ngbNavContent>
        <div class="col-md-12 d-flex p-0 mt-4 brand-transaction-details">
          <div class="col-md-4 pl-0 right-border px-5">
            <div class="ref-code">Ref Code:</div>
            <div class="ref-code-val" *ngIf="action !== 'accepted'">
              {{ transaction?.transaction_code }}
            </div>
            <div class="ref-code-val" *ngIf="action === 'accepted'">
              {{ transaction?.reference_id }}
            </div>
            <div class="transaction-details text-center mt-5">
              <div class="icon">
                <div class="cashed" *ngIf="action !== 'accepted'">
                  <img
                    src="/assets/images/purpl/cashed-rect-2.png"
                    alt="rect"
                  />
                  <img
                    src="/assets/images/purpl/cashed-arrow-2.png"
                    alt="arrow"
                  />
                </div>

                <div class="cashed" *ngIf="action === 'accepted'">
                  <div class="received">
                    <img
                      src="/assets/images/purpl/received-rect-2.png"
                      alt="rect"
                    />
                    <img
                      src="/assets/images/purpl/received-arrow-2.png"
                      alt="arrow"
                    />
                  </div>
                </div>
              </div>

              <div class="transaction-amount-heading">Amount</div>
              <div class="transaction-amount">{{ transaction?.amount }}</div>
              <button
                type="button"
                class="btn btn-primary text-capitalize font-weight-normal mt-2 arrow-cursor"
                *ngIf="action !== 'accepted'"
              >
                {{
                  transaction?.branch?.type?.name == "ATM"
                    ? transaction?.branch?.type?.name
                    : transaction?.branch?.type?.name.replace("Branch", "OTC")
                }}
                - {{ transaction?.transaction_type?.transaction_type }}
              </button>
            </div>
          </div>
          <div class="col-md-8 p-0 d-flex">
            <div class="col-md-6 right-border px-5">
              <div class="transaction-details-heading mb-3">
                Transaction Details
              </div>

              <div class="fixed-details-height">
                <div class="sub-details-heading" *ngIf="action !== 'accepted'">
                  Cashout Amount
                </div>
                <div class="sub-details-heading" *ngIf="action === 'accepted'">
                  Received Amount
                </div>
                <div class="sub-details-val">{{ transaction?.amount }}</div>
              </div>
              <div class="fixed-details-height">
                <div class="sub-details-heading" *ngIf="action !== 'accepted'">
                  Cashout On
                </div>
                <div class="sub-details-heading" *ngIf="action === 'accepted'">
                  Received On
                </div>
                <div class="sub-details-val">
                  {{ transaction?.created_at | date : "d/M/yy" }}
                  at
                  {{ transaction?.created_at | date : "shortTime" }}
                </div>
              </div>
              <div class="fixed-details-height">
                <div class="sub-details-heading">Sender Name</div>
                <div class="sub-details-val" *ngIf="action !== 'accepted'">
                  {{
                    transaction?.sub_transaction_list[0]?.remittance_transaction
                      ?.sender_name
                  }}
                </div>
                <div class="sub-details-val" *ngIf="action === 'accepted'">
                  {{ transaction?.sender_first_name }}
                  {{ transaction?.sender_last_name }}
                </div>
              </div>
              <div class="fixed-details-height">
                <div class="sub-details-heading">Sender Location</div>
                <div class="sub-details-val" *ngIf="action !== 'accepted'">
                  {{
                    transaction?.sub_transaction_list[0]?.remittance_transaction
                      ?.sender_location
                  }}
                </div>
                <div class="sub-details-val" *ngIf="action === 'accepted'">
                  {{ transaction?.sender_country }}
                </div>
              </div>
              <div class="fixed-details-height">
                <div class="sub-details-heading">Remittance Platform</div>
                <div class="sub-details-val" *ngIf="action !== 'accepted'">
                  {{
                    transaction?.sub_transaction_list[0]?.remittance_transaction
                      .remittance_platform_name
                  }}
                </div>
                <div class="sub-details-val" *ngIf="action === 'accepted'">
                  {{ transaction?.platform_name }}
                </div>
              </div>
              <div class="fixed-details-height">
                <div class="sub-details-heading">Receiver Name</div>
                <div class="sub-details-val" *ngIf="action !== 'accepted'">
                  {{
                    transaction?.sub_transaction_list[0]?.remittance_transaction
                      .first_name
                  }}
                  {{
                    transaction?.sub_transaction_list[0]?.remittance_transaction
                      .last_name
                  }}
                </div>
                <div class="sub-details-val" *ngIf="action === 'accepted'">
                  {{ transaction?.receiver_first_name }}
                  {{ transaction?.receiver_last_name }}
                </div>
              </div>

              <div class="fixed-details-height">
                <div class="sub-details-heading">Relation with Sender</div>
                <div class="sub-details-val" *ngIf="action !== 'accepted'">
                  {{
                    transaction?.sub_transaction_list[0]?.remittance_transaction
                      .relationship_to_receiver
                  }}
                </div>
                <div class="sub-details-val" *ngIf="action === 'accepted'">
                  {{ transaction?.sender_relationship_to_receiver }}
                </div>
              </div>
              <div class="fixed-details-height">
                <div class="sub-details-heading">Transfer Reason</div>
                <div class="sub-details-val" *ngIf="action !== 'accepted'">
                  {{
                    transaction?.sub_transaction_list[0]?.remittance_transaction
                      .transfer_purpose
                  }}
                </div>
                <div class="sub-details-val" *ngIf="action === 'accepted'">
                  {{ transaction?.transfer_purpose }}
                </div>
              </div>
            </div>
            <div class="col-md-6 px-5" *ngIf="action === 'accepted'">
              <div class="transaction-details-heading mb-3"></div>

              <div class="fixed-details-height">
                <div class="sub-details-heading">Sender Phone Number</div>
                <div class="sub-details-val">
                  {{ transaction?.sender_phone_number }}
                </div>
              </div>
              <div class="fixed-details-height">
                <div class="sub-details-heading">Sender Email</div>
                <div class="sub-details-val">
                  {{ transaction?.sender_email }}
                </div>
              </div>
              <div class="fixed-details-height">
                <div class="sub-details-heading">Date of Birth</div>
                <div class="sub-details-val">
                  {{ transaction?.sender_date_of_birth }}
                </div>
              </div>
            </div>
            <div class="col-md-6 px-5" *ngIf="action !== 'accepted'">
              <div class="transaction-details-heading mb-3">
                Remittance Details
              </div>

              <div class="fixed-details-height">
                <div class="sub-details-heading">Cashout Partner</div>
                <div class="sub-details-val">
                  {{ transaction?.branch?.name }}
                </div>
              </div>
              <div class="fixed-details-height">
                <div class="sub-details-heading">Cashout Location</div>
                <div class="sub-details-val">
                  {{ transaction?.branch?.address }}
                </div>
              </div>
              <div class="fixed-details-height">
                <div class="sub-details-heading">Cashout Confirmation</div>
                <div class="sub-details-val">
                  {{ transaction?.cashout_confirmation }}
                </div>
              </div>
              <div class="fixed-details-height">
                <div class="sub-details-heading">Total Revenue</div>
                <div class="sub-details-val">
                  {{ transaction?.total_growth_amount }}
                </div>
              </div>
              <div class="fixed-details-height">
                <div class="sub-details-heading">Purpl Revenue</div>
                <div class="sub-details-val">
                  {{ transaction?.purpl_amount }}
                </div>
              </div>
              <div class="fixed-details-height">
                <div class="sub-details-heading">Cashout Partner Revenue</div>
                <div class="sub-details-val">
                  {{ transaction?.cash_out_partner_amount }}
                </div>
              </div>
              <div class="fixed-details-height">
                <div class="sub-details-heading">
                  Total Number of OTP's Generated
                </div>
                <div class="sub-details-val">
                  {{ transaction?.otps_generated }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </div>
  <div [ngbNavOutlet]="nav"></div>
</div>
