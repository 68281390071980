<app-breadcrumb>
  <div class="pull-right w-75">
    <app-search-bar
      [value]="searchText"
      (onSearch)="searchInput($event)"
      placeholder="Search ..."
    ></app-search-bar>
  </div>
</app-breadcrumb>

<div class="container-fluid merchants">
  <div class="card">
    <div class="card-body compliance-card-body">
      <div
        ngbNav
        #nav="ngbNav"
        [activeId]="activeId"
        class="tab-coupon profile-tabs brand-manager-dashboard"
        (navChange)="tabChange($event.nextId)"
      >
        <ng-container ngbNavItem="transactions">
          <a ngbNavLink>Transactions </a>
          <ng-template ngbNavContent>
            <div class="card-body">
              <div class="table-heading mb-2">
                <div class="table-heading-title">All Transactions</div>
              </div>
              <div class="mb-3 col-12 d-flex p-0">
                <select
                  class="form-control cursor col-4 mr-2"
                  [(ngModel)]="dateCriteria"
                >
                  <option value="">All transactions</option>
                  <option value="send">Search by Send date</option>
                  <option value="status">Search by Status date</option>
                </select>

                <div class="col-4 d-flex p-0">
                  <div class="input-group m-auto input-grp-p mr-3">
                    <input
                      class="datepicker-here form-control"
                      (dateSelect)="onSelect('from', $event)"
                      ngbDatepicker
                      #from="ngbDatepicker"
                      placeholder="Select date to filter"
                      (click)="from.toggle()"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn calendar"
                        (click)="from.toggle()"
                        type="button"
                      >
                        <i class="fa fa-calendar"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-4 text-center">
                  <a
                    class="btn btn-primary text-capitalize"
                    (click)="applyFilter()"
                    >Apply</a
                  >
                  <a
                    class="btn btn-primary text-capitalize ml-1"
                    (click)="clearFilter()"
                    >Clear Filters</a
                  >
                </div>
              </div>
              <ng-container *ngTemplateOutlet="agGridTemplate"></ng-container>
            </div>
          </ng-template>
        </ng-container>
        <ng-container ngbNavItem="beneficiaries">
          <a ngbNavLink>Beneficiaries </a>
          <ng-template ngbNavContent>
            <div class="col-12 p-0 mt-3">
              <ng-container
                *ngTemplateOutlet="agGridBeneficiaries"
              ></ng-container>
            </div>
          </ng-template>
        </ng-container>
      </div>
      <div [ngbNavOutlet]="nav"></div>
      <purpl-export-button
        (exportEv)="exportItems()"
        [title]="'Click to generate report'"
      ></purpl-export-button>
    </div>
  </div>
</div>

<ng-template #agGridTemplate>
  <app-transactions-ag-grid
    [tableDataListItems]="tableDataListItems"
    (onFilterSearchText)="
      filterSearchText.next({
        elem: $event.elem,
        value: $event.value,
        filter: $event.filter
      })
    "
    [pageLimit]="pageLimit"
    [page]="page"
    [hasMore]="hasMore"
    [totalElements]="totalNumberOfRecords"
    [params]="params"
    (onPageChanged)="onPageChange($event)"
    (onPageLimitChanged)="onPageLimitChange($event)"
    (onColumnFilterChanged)="onColumnFilterChange($event)"
  ></app-transactions-ag-grid>
</ng-template>

<ng-template #agGridBeneficiaries>
  <app-beneficiaries-ag-grid
    [tableDataListItems]="tableDataListItems"
    (onFilterSearchText)="
      filterSearchText.next({
        elem: $event.elem,
        value: $event.value,
        filter: $event.filter
      })
    "
    [pageLimit]="pageLimit"
    [page]="page"
    [hasMore]="hasMore"
    [totalElements]="totalNumberOfRecords"
    [params]="params"
    (onPageChanged)="onPageChange($event)"
    (onPageLimitChanged)="onPageLimitChange($event)"
    (onColumnFilterChanged)="onColumnFilterChange($event)"
  ></app-beneficiaries-ag-grid>
</ng-template>
