<div class="custom-datatable">
  <div class="table-responsive scrollable-container">
    <table class="table table-hover listing-table">
      <thead>
        <tr>
          <th
            *ngFor="let col of tableColumns"
            [ngClass]="col.headingClass || ''"
            class="text-uppercase"
            scope="col"
          >
            {{ col.value }}
          </th>
        </tr>
      </thead>
      <tbody class="datatable-body">
        <tr *ngIf="tableDataListItems.length == 0">
          <td [attr.colspan]="tableColumns.length" class="text-center">
            No Records ...
          </td>
        </tr>
        <tr
          *ngFor="let data of tableDataListItems; let i = index"
          class="datatable-body-row"
          [ngClass]="{
            'datatable-row-odd': i % 2 !== 0,
            'datatable-row-even': i % 2 === 0
          }"
        >
          <td *ngFor="let col of tableColumns" [ngClass]="col.class || ''">
            <div [ngSwitch]="col.type">
              <ng-container *ngSwitchCase="'text'">
                <app-purpl-table-text
                  [label]="constructNestedObject(data, col)"
                  [key]="col.key"
                ></app-purpl-table-text>
              </ng-container>
              <ng-container *ngSwitchCase="'full-name'">
                <app-purpl-table-full-name
                  [imgSrc]="data?.user?.image"
                  [full_name]="data?.user?.full_name"
                ></app-purpl-table-full-name>
              </ng-container>
              <ng-container *ngSwitchCase="'more-details'">
                <app-purpl-table-more-details
                  (onPress)="moreDetails(data)"
                ></app-purpl-table-more-details>
              </ng-container>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-between m-t-10 w-100">
    <app-page-limiter
      (onPageLimitChanged)="onPageLimitChanged.emit($event)"
      [pageLimit]="pageLimit"
    ></app-page-limiter>
    <ngb-pagination
      *ngIf="tableDataListItems?.length > 0"
      [collectionSize]="collectionSize"
      [(page)]="page"
      [pageSize]="pageLimit"
      [boundaryLinks]="true"
      [disabled]="!hasMore"
      [boundaryLinks]="true"
      [ellipses]="true"
      (pageChange)="onPageChanged.emit($event)"
      [maxSize]="10"
    >
    </ngb-pagination>
  </div>
</div>
