import { ChangeDetectorRef, Component, OnInit, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {
  LocalStorageService,
  SessionStorageService,
  SharedDataService,
  UserService,
} from './shared/services';
import { UserIdleService } from 'angular-user-idle';
import { MatDialog } from '@angular/material/dialog';
import { IdleWarningDialogComponent } from './shared/components/idle-warning-dialog/idle-warning-dialog.component';
import {
  selectShowPrompt,
  setCountDownTimer,
  setShowPrompt,
} from './shared/stores/slices/App';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import * as ROUTE_LIST from './shared/constants/routes-list';
import { storageAction } from './shared/stores';

declare const document: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'purpl';
  isLoggedIn = false;

  timedOut = false;
  isPromptShown = false;
  documentFocused = false;
  countdown: number | null = null;

  protected routeList = ROUTE_LIST;
  private user: any = null;

  eventBusSub?: Subscription;

  constructor(
    public userService: UserService,
    private store: Store<any>,
    public dialog: MatDialog,
    private userIdle: UserIdleService,
    cd: ChangeDetectorRef,
    private sessionService: SessionStorageService,
    private localService: LocalStorageService,
    private sharedDataService: SharedDataService,
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private router: Router,
    private readonly renderer: Renderer2
  ) {
    this.store.select(selectShowPrompt).subscribe({
      next: (prompt) => {
        this.isPromptShown = prompt;
      },
    });

    this.user = this.userService.user;

    if (this.userService.isLoggedIn()) {
      if (this.user) {
        this.userService.getUserPrivileges().subscribe({
          next: (response) => {
            //Start watching for user inactivity.
            this.userIdle.startWatching();

            // Start watching when user idle is starting.
            // this.onTimeStart();
            this.userIdle.onTimerStart().subscribe((count) => {
              // console.log('Hi');
              // console.log('count1: ', count);
              count = count === null ? 0 : count;
              // console.log('COUNT2: ', count);
              this.countdown = count;
              this.store.dispatch(setCountDownTimer(count));

              if (this.documentFocused === true) {
                if (!this.isPromptShown) {
                  this.openDialog();
                }
              }
            });

            // Start watch when time is up.
            this.onTimeOut();

            // Start to ping userIdle
            this.onStartToPing();
          },
        });
      }
    }
  }

  ngOnInit(): void {
    this.renderer.listen('window', 'storage', (event) => {
      this.store.dispatch(storageAction({ payload: event.key as string }));
    });
  }

  onTimeStart() {
    // console.log('onTimerStart');
    this.userIdle.onTimerStart().subscribe((count) => {
      // console.log('Hi');
      // console.log('count1: ', count);
      count = count === null ? 0 : count;
      // console.log('COUNT2: ', count);
      this.countdown = count;
      this.store.dispatch(setCountDownTimer(count));

      if (this.documentFocused === true) {
        if (!this.isPromptShown) {
          this.openDialog();
        }
      }
    });
  }

  onTimeOut() {
    this.userIdle.onTimeout().subscribe(() => {
      this.timedOut = true;
      this.stopWatching();
      this.autoLogout();
    });
  }

  onStartToPing() {
    this.userIdle.ping$.subscribe(() => {
      // console.log('PING');
      this.checkWindowFocused();
    });
  }

  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  restart() {
    // console.log('restart');
    this.userIdle.resetTimer();
  }

  openDialog() {
    this.store.dispatch(setShowPrompt(true));
    this.dialog.open(IdleWarningDialogComponent);
  }

  autoLogout() {
    this.modalService.dismissAll(null);

    this.toastrService.clear();
    this.sharedDataService.showLoadingBar(false);
    this.sharedDataService.changeFormSubmitStatus(false);
    this.sessionService.cleatDataInSessionStorage();
    this.localService.clearDataInLocalStorage();
    this.router
      .navigateByUrl(this.routeList.LOGIN)
      .then(() => window.location.reload());
  }

  checkWindowFocused() {
    // console.log('document.hasFocus(): ', document.hasFocus());
    if (document.hasFocus()) {
      this.documentFocused = true;
      // console.log('✅ window has focus');
      this.userIdle.startWatching();
      // this.onTimeStart();
    } else {
      // console.log('⛔️ window does NOT have focus');
      this.documentFocused = false;
      this.restart();
    }
  }
}
