<span *ngIf="label != null && label?.length <= 20" class="text-capitalize">
  {{ label }}
</span>
<span
  class="text-capitalize feedback-hover"
  *ngIf="label != null && label?.length > 20"
  [title]="label"
>
  {{ label | slice: 0:20 }}
  <strong> ...</strong>
</span>
