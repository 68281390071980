<div class="container-fluid">
  <div class="row page-header">
    <div class="col-7">
      <div>
        <h4 class="add-title mb-0">
          Cash out Transaction
          <img
            class="brand-manager-logo"
            *ngIf="userService?.user?.brand_image"
            [src]="userService?.user?.brand_image"
            alt="brand-logo"
          />
        </h4>
        <div class="add-title-role text-capitalize">
          {{ userService?.user?.brand_name }} -
          {{ userService?.user?.position?.replace("_", " ") }} Panel
        </div>
      </div>
    </div>
    <div class="col-5">
      <div class="w-100">
        <div
          class="form-group has-search"
          *ngIf="checkAccessModule(FEATURES.DASHBOARD.name, PERMISSION.READ)"
        >
          <span class="fa fa-search form-control-feedback"></span>
          <input
            type="text"
            class="form-control"
            placeholder="Search by cashout point or reference code"
            (input)="searchInput($event.target.value)"
          />
        </div>
      </div>
    </div>
  </div>
  <div
    class="card"
    *ngIf="checkAccessModule(FEATURES.DASHBOARD.name, PERMISSION.READ)"
  >
    <div class="card-body">
      <div
        ngbNav
        #nav="ngbNav"
        [activeId]="activeId"
        class="tab-coupon profile-tabs brand-manager-dashboard"
        (navChange)="tabChange($event.nextId)"
      >
        <ng-container [ngbNavItem]="'pending'">
          <a ngbNavLink> Pending Visits </a>
          <ng-template ngbNavContent>
            <div class="col-12 p-0 mb-3">
              <div class="filters-list d-flex mt-2">
                <div class="col-8 p-0 m-auto">
                  <div class="d-flex width-adjust">
                    <div
                      *ngFor="let filter of pendingFilters; let i = index"
                      class="filter-list"
                      (click)="
                        filter.id == 0 ? onFilterCustomDate() : null;
                        filter.id == 0 ? null : filterApplied(filter.id)
                      "
                      [ngClass]="{
                        'selected-filter': selectedFilter == filter.id
                      }"
                    >
                      {{ filter.statusText }}
                    </div>
                  </div>
                </div>
                <div class="col-4 d-flex pr-0 justify-content-end">
                  <div class="booked-spots mr-3">
                    <div class="focus-text">
                      {{ bookedSpots }}
                    </div>
                    <div class="detail-text">Booked Spots</div>
                  </div>
                  <div class="cashout-balance">
                    <div class="focus-text" [title]="cashoutBalance">
                      {{ cashoutBalance | slice : 0 : 12 }}
                      {{ cashoutBalance.length > 12 ? "..." : "" }}
                    </div>
                    <div class="detail-text">Cashout Balance</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 p-0">
              <ng-container
                *ngTemplateOutlet="tableDataTemplate"
              ></ng-container>
            </div>
          </ng-template>
        </ng-container>
        <ng-container [ngbNavItem]="'recent'">
          <a ngbNavLink> Recently Visited Users </a>
          <ng-template ngbNavContent>
            <div class="col-12 p-0 mb-3">
              <div class="filters-list d-flex mt-2">
                <div
                  class="col-8 p-0 m-auto recently-visited-users scrollable-container"
                >
                  <div class="d-flex date-filter">
                    <div
                      *ngFor="
                        let filter of recentlyVisitedFilters;
                        let i = index
                      "
                      class="filter-list"
                      (click)="
                        filter.id == 0 ? onFilterCustomDate() : null;
                        filter.id == 0 ? null : filterApplied(filter.id)
                      "
                      [ngClass]="{
                        'selected-filter': selectedFilter == filter.id
                      }"
                    >
                      {{ filter.statusText }}
                    </div>
                  </div>
                </div>
                <div class="col-4 d-flex pr-0 justify-content-end">
                  <div class="booked-spots mr-3">
                    <div class="focus-text">
                      {{ bookedSpots }}
                    </div>
                    <div class="detail-text">Booked Spots</div>
                  </div>
                  <div class="cashout-balance">
                    <div class="focus-text" [title]="cashoutBalance">
                      {{ cashoutBalance | slice : 0 : 12 }}
                      {{ cashoutBalance.length > 12 ? "..." : "" }}
                    </div>
                    <div class="detail-text">Cashout Balance</div>
                  </div>
                </div>
              </div>
              <div class="filters-list d-flex mt-2">
                <div class="col-12 p-0 m-auto">
                  <div class="d-flex">
                    <div
                      *ngFor="
                        let status of recentlyVisitedStatus;
                        let i = index
                      "
                      class="filter-list"
                      (click)="statusApplied(status.id)"
                      [ngClass]="{
                        'selected-filter': selectedStatus == status.id
                      }"
                    >
                      {{ status.statusText }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 p-0">
              <ng-container
                *ngTemplateOutlet="tableDataTemplate"
              ></ng-container>
            </div>
          </ng-template>
        </ng-container>
      </div>

      <div [ngbNavOutlet]="nav"></div>

      <purpl-export-button
        (exportEv)="exportItems()"
        [title]="'Click to generate report'"
        *ngIf="checkAccessModule(FEATURES.DASHBOARD.name, PERMISSION.EXPORT)"
      ></purpl-export-button>
    </div>
  </div>
</div>

<ng-template #tableDataTemplate>
  <app-brand-manager-dashboard-all-users
    [tableDataListItems]="tableDataListItems"
    [pageLimit]="pageLimit"
    [page]="page"
    [hasMore]="hasMore"
    [collectionSize]="totalNumberOfRecords"
    [tableColumns]="tableColumns"
    (onPageChanged)="onPageChange($event)"
    (onPageLimitChanged)="onPageLimitChange($event)"
    (clickedOnRow)="onRowClicked($event)"
    (bookedSpotsCount)="bookedSpots = $event"
    (cashoutBalance)="cashoutBalance = $event"
    (exportDetails)="exportDetails = $event"
    [hideFilter]="true"
    [recentlyVisited]="true"
    [filters]="true"
  ></app-brand-manager-dashboard-all-users>
</ng-template>
