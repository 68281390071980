<div class="backgrund-pic">
  <div class="authentication-box">
    <div class="container">
      <div class="row">
        <div class="col-md-5 p-0 card-left">
          <div class="card bg-primary card-setting">
            <img
              class="brand-logo blur-up lazyloaded brand-zoom"
              src="assets/images/dashboard/purpl-logo.png"
              alt=""
            />
          </div>
        </div>
        <div class="col-md-7 p-0 card-right">
          <div class="card tab2-card">
            <div class="card-body body-height">
              <div class="login-text mb-5">Login</div>
              <form
                [formGroup]="formGroup"
                class="form-horizontal auth-form"
                novalidate
                (keyup.enter)="formGroup.valid ? onSubmit() : null"
              >
                <div class="form-group">
                  <input
                    required
                    name="email"
                    type="email"
                    class="form-control"
                    placeholder="Username"
                    id="email"
                    formControlName="email"
                  />
                  <ng-container
                    *ngIf="
                      checkControlValidity(
                        'email',
                        'invalid',
                        formGroup,
                        formSubmitted
                      )
                    "
                  >
                    <small class="text-danger col">
                      {{ getErrorMessage("email") }}
                    </small>
                  </ng-container>
                </div>
                <div class="form-group d-flex">
                  <input
                    required
                    name="password"
                    [type]="hide ? 'text' : 'password'"
                    class="form-control w-100"
                    placeholder="Password"
                    formControlName="password"
                  />
                  <span
                    class="eye-icon"
                    (click)="hide = !hide"
                    [title]="hide ? 'Hide the password' : 'Show the password'"
                  >
                    <img
                      [src]="
                        hide
                          ? '/assets/images/svg/eye_hide.svg'
                          : '/assets/images/svg/eye_visible.svg'
                      "
                      alt="password-field"
                    />
                  </span>
                  <ng-container
                    *ngIf="
                      checkControlValidity(
                        'password',
                        'invalid',
                        formGroup,
                        formSubmitted
                      )
                    "
                  >
                    <small class="text-danger col display-error">
                      {{ getErrorMessage("password") }}
                    </small>
                  </ng-container>
                </div>
                <div class="form-terms">
                  <div class="custom-control custom-checkbox mr-sm-2">
                    <a
                      href="javascript:void(0)"
                      class="btn btn-default forgot-pass"
                      [routerLink]="routeList.FORGET_PASSWORD"
                    >
                      Forgot my Password
                    </a>
                  </div>
                </div>
                <div class="form-button">
                  <button
                    class="btn btn-primary"
                    type="button"
                    (click)="onSubmit()"
                    [disabled]="
                      (sharedDataService.loadingBarStatus | async) ||
                      formGroup.invalid
                    "
                  >
                    <span *ngIf="!isLoading">Login</span>
                    <i
                      class="fa fa-spin fa-spinner"
                      aria-hidden="true"
                      *ngIf="isLoading"
                    ></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
