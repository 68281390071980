import { createFeatureSelector, createSelector } from '@ngrx/store';
import { createSlice } from '@reduxjs/toolkit';
import { ApiResponseBodyInterface } from '../../interfaces/api-response.interface';

export interface RemittancePartnersState {
  remittancePartners: ApiResponseBodyInterface | null;
  loadingRemittancePartners: boolean;
}

const initialState: RemittancePartnersState = {
  remittancePartners: null,
  loadingRemittancePartners: false,
};

const { actions, reducer, name } = createSlice({
  name: 'remittancePartners',
  initialState,
  reducers: {
    getAllRemittancePartnersPending: (state) => {
      state.loadingRemittancePartners = true;
    },
    getAllRemittancePartnersFullfilled: (state, { payload }) => {
      state.loadingRemittancePartners = false;
      state.remittancePartners = payload;
    },
    getAllRemittancePartnersRejected: (state, { payload }) => {
      state.loadingRemittancePartners = true;
    },
  },
});

const selectRemittancePartnersFeature =
  createFeatureSelector<ReturnType<typeof reducer>>(name);

export const selectRemittancePartnersLoading = createSelector(
  selectRemittancePartnersFeature,
  (state) => state.loadingRemittancePartners
);

export const selectAllRemittancePartners = createSelector(
  selectRemittancePartnersFeature,
  (state) => state.remittancePartners
);

export { name };
export const {
  getAllRemittancePartnersPending,
  getAllRemittancePartnersFullfilled,
  getAllRemittancePartnersRejected,
} = actions;

export default reducer;
