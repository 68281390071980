import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserListComponent } from './user-list/user-list.component';
import { UserFormComponent } from './user-form/user-form.component';
import { UsersResolverService } from './users-resolver.service.ts';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: UserListComponent,
        data: {
          // title: 'Entities',
          // breadcrumb: 'Entities'
        },
      },
      // {
      //   path: 'teller',
      //   component: UserListComponent,
      //   data: {
      //     //title: 'Entities',
      //     //breadcrumb: 'Entities'
      //   }
      // },
      {
        path: 'add',
        component: UserFormComponent,
        data: {
          title: 'Add New User',
        },
      },
      {
        path: ':id',
        component: UserFormComponent,
        data: {
          title: 'Edit User',
        },
        resolve: {
          data: UsersResolverService,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UsersRoutingModule {}
