import {
  Component,
  ViewEncapsulation,
  Injector,
  ViewChild,
  Input,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { BaseComponent } from './base.component';
import { GenericTableComponent } from '../purpl-table/purpl-table.component';
import { PERMISSION } from '../../constants/constant-list';
import FEATURES from '../../constants/navigation';

/*
 * Base Component
 * Top Level Component
 */
@Component({
  selector: 'app-listing-base-component',
  encapsulation: ViewEncapsulation.None,
  template: '',
})
export class ListingBaseComponent extends BaseComponent implements OnDestroy {
  public PERMISSION: any = PERMISSION;
  public FEATURES: any = FEATURES;

  public requestBody: any = {};
  /**
   * The following acts as the filters of the data table
   * @type {object}
   */
  public params: object = {};

  /**
   * The following acts as the columns for the pagination of the data table
   * @type {any[]}
   */
  public tableColumns: any[] = [];
  public tableColumnsRemittance: any[] = [];

  /**
   * The following acts to delete record  from the data table
   * @type {number | string}
   */
  public deletedItemId: number | string = '';

  /**
   * The following acts as the api resource configuration for the pagination of the data table
   * @type {any}
   */
  public endPointConfiguration: any = {};

  @ViewChild(GenericTableComponent, { static: false })
  table?: GenericTableComponent;
  /**
   * the following holds reference to the search subscription , which needs to be unsubscribe when respective component is destroyed from view
   */
  protected searchSubscription$?: Subscription;

  constructor(injector: Injector) {
    super(injector);
  }

  private _pageIndex: number = this.constantList.DEFAULT_PAGE_INDEX;

  get pageIndex(): number {
    return this._pageIndex;
  }

  set pageIndex(value: number) {
    this._pageIndex = value;
  }

  ngOnDestroy() {
    // to make sure the all subscribers are removed on this component being destroyed from view heiarchy
    if (this.searchSubscription$) {
      this.searchSubscription$.unsubscribe();
    }
  }

  reloadTableTranslations() {
    if (this.table) {
      this.table.tableColumns = this.tableColumns;
      this.table.params = this.params;
      this.table.updateTableColumnTextOnLanguageChanged();
    }
  }

  checkAccessModule(
    moduleName: string,
    permission: string = this.constantList.PERMISSION_READ
  ): boolean {
    return this.permissionService.canAccessModule(moduleName, permission);
  }

  isSuperAdmin(): boolean {
    return this.permissionService.isSuperAdmin();
  }
}
