import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
  ErrorHandler,
  APP_INITIALIZER,
} from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { AgmCoreModule } from '@agm/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { QuillModule } from 'ngx-quill';
import { QuicklinkModule } from 'ngx-quicklink';
import { AgGridModule } from 'ag-grid-angular';
import { DropzoneModule } from 'ngx-dropzone-wrapper';

// Import from library
import {
  NgxAwesomePopupModule,
  DialogConfigModule,
  ConfirmBoxConfigModule,
  ToastNotificationConfigModule,
} from '@costlydeveloper/ngx-awesome-popup';
import { NgxFileDropModule } from 'ngx-file-drop';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DashboardModule } from './pages/dashboard/dashboard.module';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './pages/auth/auth.module';
import { SettingModule } from './pages/setting/setting.module';
import { UsersModule } from './pages/users/users.module';
import { PurplBaseResolver } from './shared/resolvers/base-resolver';
import {
  BaseNetworkService,
  DataListingService,
  DataService,
  HelperService,
  PermissionService,
  SharedDataService,
  UserService,
  PurplTranslationLoaderService,
} from './shared/services';

import { CanActivateGuard } from './shared/guards/can-activate.guard';
import { JwtInterceptor } from './shared/interceptors/jwt.interceptor';
import { TimerInterceptor } from './shared/interceptors/timer.interceptor';
import { BaseComponent } from './shared/components/base/base.component';
import { FormBaseComponent } from './shared/components/base/form-base.component';
import { ListingBaseComponent } from './shared/components/base/listing-base.component';
import {
  MAT_RIPPLE_GLOBAL_OPTIONS,
  RippleGlobalOptions,
} from '@angular/material/core';
import * as Sentry from '@sentry/angular-ivy';
const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
};

import { environment } from '../environments/environment';
import { reducers, metaReducers } from '../app/shared/stores';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FileSaverModule } from 'ngx-filesaver';
import { UserIdleModule } from 'angular-user-idle';
import {
  GLOBAL_INACTIVITY_TIMER,
  GLOBAL_TIMEOUT_TIMER,
  GOOGLE_API_KEY,
} from './shared/constants/constant-list';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  declarations: [
    BaseComponent,
    FormBaseComponent,
    ListingBaseComponent,
    AppComponent,
  ],
  imports: [
    TranslateModule.forRoot(),
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      autoDismiss: true,
      enableHtml: true,
      closeButton: true,
      maxOpened: 1,
      resetTimeoutOnDuplicate: true,
      progressBar: false,
      timeOut: 3000,
      positionClass: 'toast-bottom-center',
      disableTimeOut: false,
    }),
    AppRoutingModule,
    DashboardModule,
    SettingModule,
    AuthModule,
    SharedModule,
    UsersModule,
    DragDropModule,
    NgxFileDropModule,
    QuillModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: GOOGLE_API_KEY,
      libraries: ['places'],
    }),
    NgxAwesomePopupModule.forRoot(), // Essential, mandatory main module.
    DialogConfigModule.forRoot(), // Needed for instantiating dynamic components.
    ConfirmBoxConfigModule.forRoot(), // Needed for instantiating confirm boxes.
    ToastNotificationConfigModule.forRoot(), // Needed for instantiating toast notifications.
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot([]),
    QuicklinkModule,
    AgGridModule,
    CommonModule,
    DropzoneModule,
    FileSaverModule,
    UserIdleModule.forRoot({
      idle: GLOBAL_INACTIVITY_TIMER,
      timeout: GLOBAL_TIMEOUT_TIMER,
      ping: 2,
    }),
    MatAutocompleteModule,
  ],
  providers: [
    TranslateService,
    UserService,
    BaseNetworkService,
    DataService,
    DataListingService,
    HelperService,
    PermissionService,
    SharedDataService,
    PurplBaseResolver,
    PurplTranslationLoaderService,
    CanActivateGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    [{ provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig }],
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({ showDialog: false }),
    },
    { provide: Sentry.TraceService, deps: [Router] },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    NgbActiveModal,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
