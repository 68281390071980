<div class="purpl-table-text">
  <div *ngIf="key === 'amount'">+$ {{ label }}</div>
  <div *ngIf="key !== 'amount'">{{ label }}</div>
  <img
    src="/assets/images/purpl/check.png"
    alt="check"
    class="ml-1"
    *ngIf="status == 'Completed' && key == 'amount'"
    style="width: 14px; margin-bottom: 5px"
  />
  <img
    src="/assets/images/purpl/exclamation.png"
    alt="exclamation"
    *ngIf="status == 'Cancelled' && key == 'amount'"
    style="width: 14px; margin-bottom: 5px"
  />
  <img
    src="/assets/images/purpl/cross.png"
    alt="cross"
    class="ml-1"
    *ngIf="status == 'Expired' && key == 'amount'"
    style="width: 13px; margin-bottom: 3px"
  />
</div>
