<div class="purpl-table-full-name">
  <img
    class="user-img mr-2"
    [src]="imgSrc ? imgSrc : 'assets/images/dashboard/user5.jpg'"
    alt="user-image"
  />
  <span
    *ngIf="full_name != null && full_name.length <= 15"
    class="text-capitalize"
    >{{ full_name }}</span
  >

  <span
    class="text-capitalize feedback-hover"
    *ngIf="full_name != null && full_name.length > 15"
    [title]="full_name"
    >{{ full_name | slice: 0:15 }}<strong> ...</strong>
  </span>
</div>
