import { Component, Injector, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { FormBaseComponent } from 'src/app/shared/components/base/form-base.component';
import { finalize, map } from 'rxjs/operators';
import * as _ from 'lodash';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent extends FormBaseComponent implements OnInit {
  public form?: UntypedFormGroup;
  image: any = { img: 'assets/images/dashboard/designer.jpg', file: null };
  hide = false;
  editDetails: any;

  constructor(
    injector: Injector,
    private formBuilder: UntypedFormBuilder,
    private _location: Location
  ) {
    super(injector);
    this.createUserForm();
  }

  ngOnInit() {
    this.getUserProfile();
  }

  createUserForm() {
    this.form = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required]],
      password: [''],
    });
  }

  onFirstNameChange(value: string) {
    this.form?.controls.firstName.setValue(value);
  }

  onLastNameChange(value: string) {
    this.form?.controls.lastName.setValue(value);
  }

  onPasswordChange(value: string) {
    this.form?.controls.password.setValue(value);
  }

  onImageSelected($event: any) {
    const MAX_SIZE = 2097152; // 2MB in binary
    if ($event.target.files.length === 0) {
      return;
    }
    const file = $event.target.files[0];
    const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    if (!_.includes(allowedFileTypes, file.type)) {
      this.toastrService.info('Only PNG, JPG and JPEG files are allowed');
      return;
    }
    if (file.size > MAX_SIZE) {
      this.toastrService.info('Image size should be less than 2mb');
      return;
    }

    // Image upload
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event: any) => {
      this.image.file = _event.target.result;
      this.image.img = reader?.result?.toString();
    };
  }

  submit() {
    const body: any = {
      image: this.image.file,
      first_name: this.form?.controls.firstName.value,
      last_name: this.form?.controls.lastName.value,
    };

    if (this.form?.controls.password.value) {
      body.password = this.form?.controls.password.value;
    }

    body.id = this.editDetails.id;
    this.dataService
      .fetchData({
        apiUrl: `${this.apiList.USER_PROFILE_UPDATE}`.replace(
          '{id}',
          this.editDetails.id
        ),
        method: 'PUT',
        contentType: 'application/json',
        params: null,
        body,
      })
      .pipe(
        map((response) => (response && response.data ? response.data : null)),
        finalize(() => {})
      )
      .subscribe(() => {
        this.router.navigateByUrl('/dashboard');
      });

    if (this.userService.user) {
      this.userService.user = {
        ...this.userService.user,
        image: this.image.img,
        first_name: this.form?.controls.firstName.value,
      };
    }

    this.userService.localService.setEncryptData(
      { key: 'user', value: this.userService.user },
      this.constantList.ENCRYPTION_SECRET
    );
  }

  getUserProfile() {
    this.dataService
      .fetchData({
        apiUrl: `${this.apiList.USER_PROFILE_UPDATE}`.replace(
          '{id}',
          String(this.userService.user?.id).toString()
        ),
        method: 'GET',
        contentType: 'application/json',
        params: null,
        body: null,
      })
      .pipe(
        map((response) => (response && response.data ? response.data : null))
      )
      .subscribe((response) => {
        this.editDetails = response;
        this.editDetails?.image
          ? (this.image.img = this.editDetails?.image)
          : null;
        this.form?.patchValue({
          firstName: this.editDetails.first_name,
          lastName: this.editDetails.last_name,
          email: this.editDetails.email,
        });
      });
  }

  backArrowClicked() {
    this._location.back();
  }
}
