import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { range } from 'rxjs';

import { User } from '../interfaces/User';
import * as API_LIST from '../constants/apis-list';
import * as ROUTE_LIST from '../constants/routes-list';
import * as CONSTANT_LIST from '../constants/constant-list';

import { SharedDataService } from './shared-data.service';
import { LocalStorageService } from './local-storage.service';
import { SessionStorageService } from './session-storage.service';
import { DataService } from './data.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class BaseNetworkService {
  public sessionService: SessionStorageService;
  public localService: LocalStorageService;
  private _toastrService: ToastrService;
  protected dataService: DataService;
  protected router: Router;
  protected http: HttpClient;
  protected constantList = CONSTANT_LIST;
  protected apiList = API_LIST;
  protected routeList = ROUTE_LIST;
  public user?: User | null | undefined;
  private _headers: HttpHeaders = new HttpHeaders();
  private _formDataHeaders: HttpHeaders = new HttpHeaders();
  private _multipartFormDataHeaders: any;
  private _sharedDataService: SharedDataService;

  constructor(injector: Injector) {
    this.sessionService = injector.get(SessionStorageService);
    this.localService = injector.get(LocalStorageService);
    this._toastrService = injector.get(ToastrService);
    this.dataService = injector.get(DataService);
    this.router = injector.get(Router);
    this.http = injector.get(HttpClient);
    this._sharedDataService = injector.get(SharedDataService);
    this.initHeaders();
    this.initFormDataHeaders();
    this.initMultiPartFormDataHeaders();
  }

  /**
   * the following method is used to initialize headers
   */
  initHeaders(): void {
    // HttpHeader class are immutable objects.
    this._headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Accept', '*/*');
    const token = this.localService.getToken();
    if (token) {
      this._headers = this._headers.set('Authorization', `${token}`);
    }

    if (!this.sessionService.get('purpl_x_uuid')) {
      this.sessionService.set({
        key: 'purpl_x_uuid',
        value: this.constantList.X_DEVICE_UUID,
      });
    }
  }

  /**
   * the following method is used to initialize headers for form render
   */
  initFormDataHeaders(): void {
    // HttpHeader class are immutable objects.
    this._formDataHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');
    const token = this.localService.getToken();
    if (token) {
      this._formDataHeaders = this._formDataHeaders.set(
        'Authorization',
        `${token}`
      );
    }

    if (
      !this.sessionService.get('purpl_x_uuid') &&
      this.constantList.X_DEVICE_UUID
    ) {
      this.sessionService.set({
        key: 'purpl_x_uuid',
        value: this.constantList.X_DEVICE_UUID,
      });
    }
  }

  /**
   * the following method is used to initialize headers for form render
   */
  initMultiPartFormDataHeaders(): void {
    // HttpHeader class are immutable objects.
    this._multipartFormDataHeaders = {
      'Content-Type': 'undefined',
      Accept: '*/*',
    };
    const token = this.localService.getToken();
    if (token) {
      this._multipartFormDataHeaders.Authorization = token;
    }

    if (
      !this.sessionService.get('purpl_x_uuid') &&
      this.constantList.X_DEVICE_UUID
    ) {
      this.sessionService.set({
        key: 'purpl_x_uuid',
        value: this.constantList.X_DEVICE_UUID,
      });
    }
  }

  /**
   * the following method is used to get the updated token
   */
  updateToken(): void {
    let token = this.sessionService.getEncryptedData(
      'purpl_token',
      CONSTANT_LIST.ENCRYPTION_SECRET
    );
    if (token) {
      token = token.indexOf('Bearer') > -1 ? token : `Bearer ${token}`;
      this._headers = this._headers.set('Authorization', `${token}`);
      this._formDataHeaders = this._formDataHeaders.set(
        'Authorization',
        `${token}`
      );
      this._multipartFormDataHeaders.Authorization = token;
    }
  }

  /**
   * The following method is used to update the headers
   */
  get headers(): HttpHeaders {
    if (!this._headers) {
      this.initHeaders();
    }

    return this._headers;
  }

  set headers(value: HttpHeaders) {
    this._headers = value;
  }

  get formDataHeaders(): HttpHeaders {
    if (!this._formDataHeaders) {
      this.initFormDataHeaders();
    }

    return this._formDataHeaders;
  }

  set formDataHeaders(value: HttpHeaders) {
    this._formDataHeaders = value;
  }

  get multiPartFormDataHeaders(): any {
    if (!this._multipartFormDataHeaders) {
      this.initMultiPartFormDataHeaders();
    }

    return this._multipartFormDataHeaders;
  }

  /**
   * The following method converts
   * @param json
   * @returns {any}
   */
  parseResponse(json: any): any {
    // just type casting the response object into type of any so that we dont get any error or warning in IDE for accessing properties statically
    return json as any;
  }

  /**
   * The following method is used to check whether the request was unauthorised or not
   * @param error
   */
  isRequestUnauthorized(error: any) {
    if (error.status === 401) {
      this.router.navigateByUrl(this.routeList.LOGIN).then(() => {
        this._sharedDataService.changeFormSubmitStatus(false);
        this.sessionService.cleatDataInSessionStorage();
        this.localService.clearDataInLocalStorage();
      });
    }
  }

  /**
   * The following method is used to get the error messages from the response
   * @param json
   * @returns {any}
   */
  getErrorMessages(json: any): any {
    const errors = [];
    if (json) {
      console.log('response: ', json);
      if (json.validation_errors) {
        range(0, Object.keys(json.validation_errors).length).subscribe(
          (index) => {
            // the following first gets the all the Keys in the errors,
            // then based on the current index of iteration gets the respective error key value pair
            errors.push(
              json.validation_errors[Object.keys(json.validation_errors)[index]]
                .error
            );
          },
          (err) => {
            errors.push(err);
          },
          () => {
            if (!errors.length && json.message) {
              errors.push(json.message);
            }
          }
        );
      }

      if (json.messages) {
        range(0, Object.keys(json.messages).length).subscribe(
          (index) => {
            // the following first gets the all the Keys in the errors,
            // then based on the current index of iteration gets the respective error key value pair
            errors.push(json.messages[Object.keys(json.messages)[index]]);
          },
          (err) => {
            errors.push(err);
          },
          () => {
            if (!errors.length && json.message) {
              errors.push(json.message);
            }
          }
        );
      }

      if (json.error && json.error.message) {
        errors.push(json.error?.message);
      }

      // check for access_denied error
      if (json.message === this.constantList.DEFAULT_ACCESS_DENIED_CODE) {
        errors.push(this.constantList.DEFAULT_ACCESS_DENIED_MESSAGE);
      } else if (json.message) {
        errors.push(json.message);
      } else if (errors.length == 0) {
        errors.push(this.constantList.DEFAULT_ERROR_MESSAGE);
      }
    }
    return errors;
  }

  handleErrorMessages(json: any) {
    console.log('handleErrorMessages: ', json);
    const errors = this.getErrorMessages(json);
    this.showMessage(Array.isArray(errors) ? errors[0] : errors);
  }

  showMessage(msg: string, type = 'error') {
    this._toastrService.clear();
    switch (type) {
      case 'error':
        this._toastrService.error('Error: ' + msg);
        break;
      case 'warning':
        this._toastrService.warning('Warning: ' + msg);
        break;
      case 'info':
        this._toastrService.info('Info: ' + msg);
        break;
      default: // 'GENERAL.TEXT.SUCCESS'
        this._toastrService.success('Success: ' + msg);
        break;
    }
  }

  rejectErrorMessages(errorData: any, reject: any) {
    this.getErrorMessages(errorData.error).then((errorsArray: any) => {
      // if errorsArray returned, give it back to the component
      if (errorsArray) {
        reject(errorsArray);
      }
    });
  }
}
