import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererParams } from 'ag-grid-community';
import { LocalStorageService } from 'src/app/shared/services';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import * as CONSTANT from 'src/app/shared/constants/constant-list';

@Component({
  selector: 'app-beneficiary-renderer',
  templateUrl: './beneficiary-renderer.component.html',
  styleUrls: ['./beneficiary-renderer.component.scss'],
})
export class BeneficiaryRendererComponent implements ICellRendererAngularComp {
  value: string | undefined;
  data: any;

  params: HttpParams = new HttpParams();

  constructor(
    private router: Router,
    private localService: LocalStorageService
  ) {}

  agInit(params: ICellRendererParams): void {
    this.value = params.value;
    this.data = params.data;

    const httpParams = (params as any)?.httpParams;
    if (httpParams) {
      this.params = httpParams;
    }
  }

  refresh(params: ICellRendererParams) {
    this.value = params.value;
    this.data = params.data;

    const httpParams = (params as any)?.httpParams;
    if (httpParams) {
      this.params = httpParams;
    }
    return true;
  }

  openBeneficiary(event: any): void {
    this.localService.setDataInLocalStorage({
      key: CONSTANT.CACHE_URL_KEY,
      value: this.params.toString(),
    });
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/dashboard/${event.id}`])
    );
    window.open(url, '_blank');
  }
}
