import { Injectable } from '@angular/core';
import { size } from 'lodash';
import momentTZ from 'moment-timezone';
import { DEFAULT_TIMEZONE } from '../constants/constant-list';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  isFormSubmittedSharedVariable = false;

  constructor() {}

  /**
   * The following method is used to convert the respective base64encoded data into a blob
   * @param dataURI
   * @returns {Blob}
   */
  static dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    const dataView = new DataView(ab);
    return new Blob([dataView], { type: mimeString });
  }

  /**
   * the following method is used to generate random color
   */
  static getRandomColor() {
    return (
      '#' + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6)
    );
  }

  /**
   * the following makes sure the given string is not null or empty
   * @param {string} value
   * @returns {boolean}
   */
  static isNotEmptyOrUndefined(value: string) {
    return value !== undefined || value !== '' || size(value) > 0;
  }

  /**
   * The following method is used to convert the image file into Data URI
   * @param imageFile
   * @param callback
   */
  static imageFileToDataURI(imageFile: any, callback: any) {
    const file: File = imageFile;
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      // @ts-ignore
      // const trimIndex = myReader.result.toString().indexOf('base64,');
      // @ts-ignore
      // callback(myReader.result.toString().substr(trimIndex + 7));
      callback(myReader.result.toString());
    };

    myReader.readAsDataURL(file);
  }

  /**
   * The following method is used to convert the image file into Data URI
   * @param str
   * @param callback
   */
  static formatBase64Image(str: string): string {
    const img = str;
    const trimIndex = img.indexOf('base64,');
    return img.substr(trimIndex + 7);
  }

  /**
   * The following method is used to check the dimensions of image
   * @param imageFile
   * @param callback
   */
  static imageFileDimensions(imageFile: any, callback: any) {
    const fReader = new FileReader();
    fReader.onload = function () {
      const img = new Image();
      img.onload = function () {
        callback(img.width, img.height);
      };

      // @ts-ignore
      img.src = fReader.result.toString();
    };

    fReader.readAsDataURL(imageFile);
  }

  /**
   * The following checks if the given is a valid base64 string or not
   * @param {string} str
   * @returns {boolean}
   */
  static isValidBase64(str: string): boolean {
    try {
      atob(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  /**
   * The following checks if the given is a valid base64 string or not
   * @param str
   * @param count
   * @returns boolean
   */
  static getFirstLetterFromWord(
    str: string | null,
    count: number | null = null
  ): string {
    if (!str) {
      return '';
    }
    const matches = str.match(/\b(\w)/g);
    if (!matches) {
      return '';
    }
    return count != null
      ? matches.splice(0, count).join('').toUpperCase()
      : matches.join('').toUpperCase();
  }

  static getNestedPropertyValue(
    theObject: any,
    path: string,
    separator = '.'
  ): string {
    try {
      separator = separator || '.';
      const pathsList = path
        .replace('[', separator)
        .replace(']', '')
        .split(separator);
      return pathsList.reduce((obj, property, index) => {
        // Checking for array
        if (property.indexOf('*') > -1) {
          if (Array.isArray(obj)) {
            return obj || [];
          }
          return (obj[pathsList[index - 1]] || []).map((i: any) => {
            return i[pathsList[index + 1]];
          });
        }
        if (Array.isArray(obj)) {
          return obj.map((i: any) => {
            return i[property];
          });
        }
        return obj[property];
      }, theObject);
    } catch (err) {
      return '';
    }
  }

  static handleInputKeyOnDateField($event: any) {
    if (
      ($event.keyCode === 65 && ($event.ctrlKey || $event.metaKey)) ||
      ($event.keyCode === 88 && ($event.ctrlKey || $event.metaKey)) ||
      [35, 36, 37, 39, 8].indexOf($event.keyCode) !== -1
    ) {
      return;
    }
    $event.preventDefault();
  }

  static handleDateFieldInputMask($event: any) {
    if (
      ($event.keyCode === 65 && ($event.ctrlKey || $event.metaKey)) ||
      ($event.keyCode === 88 && ($event.ctrlKey || $event.metaKey)) ||
      [35, 36, 37, 39, 8].indexOf($event.keyCode) !== -1
    ) {
      return;
    }
    if ($event && $event.target && $event.target.value) {
      const value = $event.target.value;
      if (value.length > 10) {
        // stop typings on after 10 characters
        $event.preventDefault();
      } else if (
        value.match(/^\d{2}$/) !== null ||
        value.match(/^\d{2}\-\d{2}$/) !== null
      ) {
        // Adding hyphen after year
        $event.target.value = value + '-';
      } /* else if (value.match(/^\d{4}$/) !== null && value.length < 6) { // Adding hyphen montth or date
        $event.target.value = value + '-';
      }*/
    }
  }

  /**
   * The following method return string with first letter uppercase
   * param string
   * returns capitalize string
   */
  static capitalizeString($event: any) {
    if ($event) {
      return $event.replace(/(^\w{1})|(\s+\w{1})/g, (letter: any) =>
        letter.toUpperCase()
      );
    } else {
      return $event;
    }
  }

  /**
   * The following method return string with first letter uppercase
   * param string
   * returns capitalize string
   */
  static compareValueChanged(oldValue: any, newValue: any): boolean {
    return JSON.stringify(oldValue) != JSON.stringify(newValue);
  }

  static capitalizeTheFirstLetterOfEachWord(words: string): string {
    var separateWord = words.toLowerCase().split(' ');
    for (var i = 0; i < separateWord.length; i++) {
      separateWord[i] =
        separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
    }
    return separateWord.join(' ');
  }

  static countWeekdayOccurrencesInMonth(date: Date) {
    var m = momentTZ(date).tz(DEFAULT_TIMEZONE),
      weekDay = m.day(),
      yearDay = m.dayOfYear(),
      count = 0;

    m.startOf('month');
    while (m.dayOfYear() <= yearDay) {
      if (m.day() == weekDay) {
        count++;
      }
      m.add('days', 1);
    }

    return count;
  }

  static formatCallString(
    element: any,
    column: {
      key: string;
      value: string;
      type: string;
      map: any;
      callback: Function;
    }
  ) {
    return column.callback.call(column.callback, (element as any)[column.key]);
  }

  static constructNestedObject(
    element: any,
    column: {
      key: string;
      value: string;
      type: string;
      map: any;
      callback: Function;
    }
  ) {
    if (!column.key && !column.callback) {
      return '';
    } else if (!column.key && column.callback) {
      return column.callback.call(column.callback, element as any);
    }

    const key =
      column.key.split('.').length > 1
        ? this.getNestedPropertyValue(element, column.key)
        : column.key;
    if (key && key !== column.key) {
      return column.map ? (column.map[key] ? column.map[key] : key) : key;
    }

    if ((!key || !element[key]) && !column.callback && !column.map) {
      return element[key] != undefined && element[key] != null
        ? element[key]
        : '';
    }

    if ((!key || !element[key]) && column.callback) {
      return column.callback.call(column.callback, element as any);
    }

    if (column.key.indexOf('.') > -1 && !column.map) {
      return key;
    }

    if (!element[key] && !column.map) {
      return element[key] != undefined && element[key] != null
        ? element[key]
        : '';
    }

    if (column.callback) {
      return column.callback.call(column.callback, (element as any)[key]);
    }

    return column.map
      ? column.map[key]
        ? column.map[key]
        : column.map[element[key]]
      : element[key];
  }

  static formatChartGroupings(barType: string, time: any): string {
    const monthStr = momentTZ(time).tz(DEFAULT_TIMEZONE).format('MMM');

    if (barType === 'D') {
      return momentTZ(time).tz(DEFAULT_TIMEZONE).format('MM/DD');
    } else if (barType === 'W') {
      return `${monthStr} - Week ${HelperService.countWeekdayOccurrencesInMonth(
        time
      ).toString()}`;
    } else if (barType === 'M') {
      return monthStr;
    } else if (barType === 'Y') {
      return momentTZ(time).tz(DEFAULT_TIMEZONE).format('YYYY');
    } else {
      return '';
    }
  }
}
