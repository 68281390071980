import { Injectable, Injector } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { throwError, BehaviorSubject, of } from 'rxjs';
import { map, shareReplay, tap, catchError } from 'rxjs/operators';
import { IFormattedModules, IModules } from '../interfaces/RolesAccess';

import { User } from '../interfaces/User';
import { BaseNetworkService } from './base-network.service';
import {
  ENCRYPTION_SECRET,
  OK_STATUS,
  SUCCESS_STATUS,
} from '../constants/constant-list';
import { isEmpty } from 'lodash';

import * as Sentry from '@sentry/angular-ivy';
import {
  AuthenticationRequest,
  ResetPasswordRequest,
} from '../interfaces/Authentication';
import { ApiResponseInterface } from '../interfaces/api-response.interface';
import { USER_DETAIL } from '../constants/apis-list';

// We declare our Pusher constant so that Angular knows that we are using the Pusher class from an external script

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseNetworkService {
  private errors = [];
  public loggedIn = false;
  public userSubject: BehaviorSubject<User | null> =
    new BehaviorSubject<User | null>(null);
  public userModulePrivileges: IModules[] = [];
  public userPrivileges: IFormattedModules[] = [];

  constructor(private injector: Injector) {
    super(injector);
    this.setUserDetails();
  }

  setUserDetails(): void {
    this.user = this.localService.getEncryptedData(
      'user',
      this.constantList.ENCRYPTION_SECRET
    );

    if (this.user) {
      this.userSubject.next(this.user);
    }
  }

  login(body: AuthenticationRequest) {
    return this.http
      .post(this.apiList.LOGIN_API, body, { headers: this.formDataHeaders })
      .pipe(
        map((response: Partial<ApiResponseInterface>) => {
          if (
            response.status === SUCCESS_STATUS ||
            response.status === OK_STATUS
          ) {
            return {
              status: response.status,
              code: response.code,
              message: response.message,
            };
          }

          this.clearErrors();

          if (response.validation_errors) {
            this.handleErrorMessages(response);
          } else {
            this.showMessage(response.message!);
          }

          return null;
        }),
        tap({
          next: (authResult) => {
            if (authResult) {
              this.loggedIn = true;
              return authResult;
            }
            this.loggedIn = false;
            return null;
          },
        })
      );
  }

  oauth(body: AuthenticationRequest) {
    return this.http
      .post(this.apiList.OATH_URL, body, { headers: this.formDataHeaders })
      .pipe(
        tap((response: any) => {
          if (response.id_token) {
            this.setLocalSessionStorage(response as any);
            this.loggedIn = true;
          } else {
            this.clearErrors();
            this.loggedIn = false;
          }
          return response;
        }),
        shareReplay(1),
        catchError((err) => throwError(() => err))
      );
  }

  twoFactor(body: AuthenticationRequest) {
    return this.http
      .post(this.apiList.TWO_FACTOR_API, body, {
        headers: this.formDataHeaders,
      })
      .pipe(
        map((response: any) => {
          if ((response.status as any) === this.constantList.SUCCESS_STATUS) {
            if (!response.body) {
              this.showMessage(
                this.constantList.ERROR_USER_NOT_EXIST_EN,
                'warning'
              );
              return null;
            }
            const position = response.body.position;
            if (!position) {
              this.showMessage(this.constantList.ERROR_USER_ROLE_EN, 'warning');
              return null;
            }

            return response.body;
          }

          this.clearErrors();
          if (response.validation_errors) {
            this.handleErrorMessages(response);
          } else {
            this.showMessage(response.message);
          }
          return null;
        }),
        tap((authResult) => {
          if (authResult) {
            this.setLocalSessionStorage(authResult as any);
            this.setLocalSession(authResult as any);
            this.loggedIn = true;
            Sentry.setUser({
              email: authResult?.email,
              first_name: authResult?.first_name,
              last_name: authResult?.last_name,
              position: authResult?.position,
              brand_name: authResult?.brand_name,
              phone_number: authResult?.phone_number,
              country_code: authResult?.country_code,
            });
            return authResult;
          }
          this.loggedIn = false;
          return null;
        }),
        shareReplay(1),
        catchError((err) => throwError(() => err))
        // catchError((err) => throwError(this.handleErrorMessages(err)))
      );
  }

  forgetPassword(email: string) {
    return this.http
      .post(
        this.apiList.FORGOT_PASSWORD,
        { email },
        { headers: this.formDataHeaders, params: {} }
      )
      .pipe(
        map((response: any) => {
          if ((response.status as any) === this.constantList.SUCCESS_STATUS) {
            return response;
          } else {
            this.clearErrors();
            this.handleErrorMessages(response);
            return null;
          }
        }),
        catchError((err) => throwError(this.handleErrorMessages(err)))
      )
      .toPromise();
  }

  confirmPassword(formData: ResetPasswordRequest) {
    return this.http
      .post(this.apiList.RESET_PASSWORD, formData, {
        headers: this.formDataHeaders,
        params: {},
      })
      .pipe(
        map((response: Partial<ApiResponseInterface>) => {
          if ((response.status = SUCCESS_STATUS)) {
            return response;
          } else {
            this.clearErrors();
            this.handleErrorMessages(response);
            return null;
          }
        }),
        catchError((err) => throwError(this.handleErrorMessages(err)))
      )
      .toPromise();
  }

  updatePassword(body: { password: string }) {
    return this.http
      .put(this.apiList.UPDATE_PASSWORD, body, {
        headers: this.formDataHeaders,
      })
      .pipe(
        map((response: any) => {
          if (response.valid as boolean) {
            return {
              valid: response.valid,
            };
          }

          this.clearErrors();

          if (response.validation_errors || response.messages) {
            this.handleErrorMessages(response);
          } else {
            this.showMessage(response.message);
          }

          return null;
        }),
        tap((authResult) => {
          if (authResult) {
            // this.setSessionStorage(authResult as any);
            this.setLocalSessionStorage(authResult as any);
            this.loggedIn = true;
            return authResult;
          }
          this.loggedIn = false;
          return null;
        }),
        shareReplay(1),
        catchError((err) => throwError(this.handleErrorMessages(err)))
      );
  }

  logout() {
    Sentry.setUser(null);
    this.localService.clearDataInLocalStorage();
    this.sessionService.cleatDataInSessionStorage();
    window.location.replace(this.routeList.LOGIN);
  }

  public isLoggedIn(): boolean {
    return (
      this.localService.getEncryptedData('purpl_token', ENCRYPTION_SECRET) ||
      this.localService.getDataInLocalStorage('purpl_token')
    );
  }

  isLoggedOut(): boolean {
    return !this.isLoggedIn();
  }

  getErrors() {
    return this.errors;
  }

  clearErrors() {
    this.errors = [];
  }

  /**
   * the following method will store info in the local session
   * @param authResult
   */
  private setLocalSession(authResult: any) {
    console.log('setLocalSession: ', authResult);
    this.localService.setEncryptData(
      {
        key: 'user',
        value: authResult,
      },
      this.constantList.ENCRYPTION_SECRET
    );
    this.localService.setEncryptData(
      {
        key: 'permissions',
        value: authResult.permissions || [],
      },
      this.constantList.ENCRYPTION_SECRET
    );
    this.updateToken();
  }

  private setLocalSessionStorage(authResult: any) {
    this.localService.setEncryptData(
      {
        key: 'purpl_token',
        // value: authResult.id_token,
        value: authResult.token,
      },
      this.constantList.ENCRYPTION_SECRET
    );

    if (!isEmpty(authResult.refresh_token)) {
      this.localService.setEncryptData(
        {
          key: 'purpl_refresh_token',
          value: authResult.refresh_token,
        },
        this.constantList.ENCRYPTION_SECRET
      );
    }

    this.updateToken();
  }

  /**
   * the following method will store info in the session storage
   * @param authResult
   */
  private setSessionStorage(authResult: any) {
    this.sessionService.setEncryptData(
      {
        key: 'purpl_token',
        value: authResult.id_token,
      },
      this.constantList.ENCRYPTION_SECRET
    );
    this.sessionService.setEncryptData(
      {
        key: 'purpl_refresh_token',
        value: authResult.id_token,
      },
      this.constantList.ENCRYPTION_SECRET
    );
    this.updateToken();
  }

  addUser(data: any, id?: number | string) {
    const url: string = id
      ? `${this.apiList.USER_UPDATE}/${id}`
      : this.apiList.USER_ADD;
    return this.http.post(url, data, { headers: this.formDataHeaders }).pipe(
      map((response: any) => {
        if (response.message) {
          this.showMessage(
            response.message,
            (response.status as any) === this.constantList.SUCCESS_STATUS
              ? 'success'
              : 'error'
          );
        }
        if ((response.status as any) === this.constantList.SUCCESS_STATUS) {
          return response.body;
        } else {
          this.clearErrors();
          this.handleErrorMessages(response);
          return null;
        }
      }),
      catchError((err) => throwError(this.handleErrorMessages(err)))
    );
  }

  removeUser(id: number | string) {
    return this.http
      .delete(this.apiList.USER_DELETE.replace('{id}', (+id).toString()), {
        headers: this.headers,
      })
      .pipe(
        map((response: any) => {
          if ((response.status as any) === this.constantList.SUCCESS_STATUS) {
            return response;
          } else {
            this.clearErrors();
            this.handleErrorMessages(response);
            return null;
          }
        }),
        catchError((err) => throwError(this.handleErrorMessages(err)))
      );
  }

  downloadFile(body: { path: string; id: string }) {
    return this.http
      .get(this.apiList.DOWNLOAD_FILE_API, {
        headers: this.formDataHeaders,
        params: body,
        responseType: 'blob',
      })
      .pipe(catchError((err) => throwError(this.handleErrorMessages(err))));
  }

  updateProfile(data: any) {
    return this.http
      .post(this.apiList.USER_PROFILE_UPDATE, data, {
        headers: this.formDataHeaders,
      })
      .pipe(
        map((response: any) => {
          if (response.message) {
            this.showMessage(
              response.message,
              (response.status as any) === this.constantList.SUCCESS_STATUS
                ? 'success'
                : 'error'
            );
          }
          if ((response.status as any) === this.constantList.SUCCESS_STATUS) {
            return response.body;
          } else {
            this.clearErrors();
            this.handleErrorMessages(response);
            return null;
          }
        }),
        catchError((err) => throwError(this.handleErrorMessages(err)))
      );
  }

  getUserPrivileges() {
    return this.dataService
      .fetchData({
        apiUrl: this.apiList.ACCESS_PRIVILEGES,
        method: 'GET',
        contentType: 'application/json',
        params: null,
        body: null,
      })
      .pipe(
        map((response) => (response && response.data ? response.data : null)),
        tap((response) => {
          this.userModulePrivileges = response;
          this.userPrivileges = this.formatModulesSubModules(response);
          this.localService.setEncryptData(
            { key: 'permissions', value: this.userPrivileges || [] },
            this.constantList.ENCRYPTION_SECRET
          );
        })
      );
  }

  private formatModulesSubModules(modules: IModules[]): IFormattedModules[] {
    const newModules: IFormattedModules[] = [];
    modules.forEach((module) => {
      module.sub_modules.forEach((sub) => {
        newModules.push({
          id: module.id,
          name: module.name,
          label: module.label,
          sort_order: module.sort_order,
          total_sub_module: module.sub_modules?.length,
          sub_module_id: sub.id,
          sub_module_name: sub.name,
          sub_module_label: sub.label,
          sub_module_sort_order: sub.sort_order,
          privileges: sub.privileges,
        });
      });
    });
    return newModules;
  }

  refreshToken() {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    headers.delete('Authorization');

    const token = this.localService.getRefreshToken();
    return this.http
      .post(
        this.apiList.REFRESH_TOKEN_URL,
        { token },
        {
          headers,
        }
      )
      .pipe(
        tap((response: any) => {
          if (response.id_token) {
            this.localService.setEncryptData(
              {
                key: 'purpl_token',
                value: response.id_token,
              },
              this.constantList.ENCRYPTION_SECRET
            );
          }
          return response;
        }),
        shareReplay(1)
      );
  }
}
