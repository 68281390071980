import {
  catchError,
  filter,
  switchMap,
  take,
  takeWhile,
  tap,
} from 'rxjs/operators';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, interval, throwError } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import * as CONST_LIST from '../constants/constant-list';
import * as ROUTE_LIST from '../constants/routes-list';
import * as API_LIST from '../constants/apis-list';
import {
  LocalStorageService,
  SessionStorageService,
  SharedDataService,
  UserService,
} from '../services';
import { find } from 'lodash';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  protected constantList = CONST_LIST;
  protected routeList = ROUTE_LIST;

  protected whiteListAPI: string[] = [];

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  protected requestCount = 0;

  constructor(
    private router: Router,
    private sessionService: SessionStorageService,
    private localService: LocalStorageService,
    private sharedDataService: SharedDataService,
    private toastrService: ToastrService,
    private userService: UserService,
    private modalService: NgbModal
  ) {
    this.whiteListAPI = [
      API_LIST.LOGIN_API,
      API_LIST.FORGOT_PASSWORD,
      API_LIST.RESET_PASSWORD,
    ];
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.requestCount++;

    interval(this.constantList.DEFAULT_REQUEST_LONG_TIME_INTERVAL)
      .pipe(takeWhile((value: any) => this.requestCount > 0))
      .subscribe((val: any) => {
        // to make sure the snack bar is not already opened
      });

    let authReq = request;
    const token = this.localService.getToken();
    if (
      token != null &&
      !request.url.includes('users/login') &&
      !request.url.includes('oauth/refresh')
    ) {
      authReq = this.addTokenHeader(request, token);
    }

    return next.handle(authReq).pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.requestCount--;
            // once the request count has exhausted i.e. all requests have returned then simple dismiss the rendered snack bar
            if (this.requestCount === 0) {
              this.toastrService.clear();
              this.handleRequest();
            }

            if (event.body) {
              if (
                event.body.message ===
                  this.constantList.DEFAULT_INVALID_TOKEN_SERVER_RESPONSE ||
                event.body.message ===
                  this.constantList
                    .DEFAULT_INVALID_TOKEN_SIGNATURE_SERVER_RESPONSE
              ) {
                if (event.status === 401) {
                  this.handleLogout();
                } else {
                  this.router.navigateByUrl(this.routeList.LOGIN).then();
                }
              }
            }
          } else if (
            this.requestCount > 0 &&
            !this.sharedDataService.loadingBarSource.getValue()
          ) {
            this.handleRequest(true);
          }
        },
        error: (err) => {
          if (err instanceof HttpErrorResponse) {
            this.requestCount--;
            if (err.status !== 401) {
              if (err.message) {
                this.toastrService.error('Error: ' + err.message);
              }
            } else if (
              err.status === 401 &&
              request.url.includes('try-login')
            ) {
              if (err.error) {
                this.toastrService.error('Error: ' + err.error?.message);
              } else if (err.message) {
                this.toastrService.error('Error: ' + err.message);
              }
            } else if (
              err.status === 401 &&
              (!request.url.includes('try-login') ||
                !request.url.includes('oauth') ||
                !request.url.includes('login'))
            ) {
              return this.handle401Error(request, next);
            }

            if (
              this.requestCount < 1 &&
              this.sharedDataService.loadingBarSource.getValue()
            ) {
              this.handleRequest();
            }
          } else if (
            this.requestCount > 0 &&
            !this.sharedDataService.loadingBarSource.getValue()
          ) {
            this.handleRequest(true);
          }
        },
        complete: () => {
          if (
            this.requestCount < 1 &&
            this.sharedDataService.loadingBarSource.getValue()
          ) {
            this.handleRequest();
          }
        },
      })
    );
  }

  private handleLogout(): void {
    // closing all opened modal on logout
    this.modalService.dismissAll(null);
    // dismiss all opened toastr
    this.toastrService.clear();
    this.sharedDataService.showLoadingBar(false);
    this.sharedDataService.changeFormSubmitStatus(false);
    this.sessionService.cleatDataInSessionStorage();
    this.localService.clearDataInLocalStorage();
    this.router
      .navigateByUrl(this.routeList.LOGIN)
      .then(() => window.location.reload());
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      if (!!this.userService.isLoggedIn()) {
        return this.userService
          .refreshToken()
          .pipe(
            switchMap((response) => {
              this.isRefreshing = false;
              this.refreshTokenSubject.next(response?.id_token);
              return next.handle(
                this.addTokenHeader(request, response?.id_token)
              );
            }),
            catchError((error) => {
              this.isRefreshing = false;
              if (error?.status == 403) {
                this.handleLogout();
              } else if (error?.status == 400) {
                const err = error?.error;

                if (err?.validation_errors) {
                  // Token should not be blank.
                  const hasRefreshToken = find(err?.validation_errors, {
                    field_name: 'token',
                  });
                  if (hasRefreshToken) {
                    this.handleLogout();
                  }
                } else {
                  if (err?.status === 'BAD_REQUEST') {
                    this.handleLogout();
                  }
                }
              }
              return throwError(() => error);
            })
          )
          .subscribe();
      } else {
        this.localService.setDataInLocalStorage({
          key: 'redirect',
          value: window.location.href.toString(),
        });
        this.router
          .navigate([this.routeList.LOGIN], {
            queryParams: {
              redirect: window.location.href.toString(),
            },
            queryParamsHandling: 'merge',
          })
          .then(() => window.location.reload());
      }
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    const params = new HttpParams({ fromString: request.params.toString() });
    const newToken = token.indexOf('Bearer') > -1 ? token : `Bearer ${token}`;
    return request.clone({
      headers: request.headers.set('Authorization', `${newToken}`),
      params,
      reportProgress: true,
    });
  }

  private handleRequest(bool: boolean = false) {
    this.sharedDataService.showLoadingBar(bool);
    this.sharedDataService.changeFormSubmitStatus(bool);
  }
}
