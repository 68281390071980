<mat-form-field>
  <input
    matInput
    name=""
    type="text"
    class="form-control"
    id=""
    placeholder=""
    (input)="filterSearchText.next($event)"
    [(ngModel)]="filterText"
  />
</mat-form-field>
