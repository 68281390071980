import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular-ivy';

if (environment.production || environment.staging) {
  Sentry.init({
    environment: environment.production
      ? 'production'
      : environment.staging
      ? 'staging'
      : 'development',
    dsn: 'https://cfeecbfdb5ca47a6918a274573c76357@o1242196.ingest.sentry.io/4504524601819136',
    // debug: !environment.production && !environment.staging,
    release: '1.0.0',
    integrations: [
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          'https://api-dev.purplme.com/api/',
          'https://api.purplme.com/api/',
          'https://api.stg.purplme.com/api/',
        ],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'https://api-dev.purplme.com/api/',
      'https://api.purplme.com/api/',
      'https://api.stg.purplme.com/api/',
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeBreadcrumb(breadcrumb, hint) {
      return breadcrumb.category === 'ui.click' ? null : breadcrumb;
    },
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
