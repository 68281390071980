import { createFeatureSelector, createSelector } from '@ngrx/store';
import { createSlice } from '@reduxjs/toolkit';
import { ApiResponseBodyInterface } from '../../interfaces/api-response.interface';

export interface UnregisteredAppUsersState {
  unregisteredAppUsers: ApiResponseBodyInterface | null;
  loadingUnrigesteredAppUsers: boolean;
}

const initialState: UnregisteredAppUsersState = {
  unregisteredAppUsers: null,
  loadingUnrigesteredAppUsers: false,
};

const { actions, reducer, name } = createSlice({
  name: 'unregisteredAppUsers',
  initialState,
  reducers: {
    getAllUnregisteredAppUsersPending: (state) => {
      state.loadingUnrigesteredAppUsers = true;
    },
    getAllUnregisteredAppUsersFullfilled: (state, { payload }) => {
      state.loadingUnrigesteredAppUsers = false;
      state.unregisteredAppUsers = payload;
    },
    getAllUnregisteredAppUsersRejected: (state, { payload }) => {
      state.loadingUnrigesteredAppUsers = true;
    },
  },
});

const selectUnregisteredAppUsersFeature =
  createFeatureSelector<ReturnType<typeof reducer>>(name);

export const selectUnregisteredUsersLoading = createSelector(
  selectUnregisteredAppUsersFeature,
  (state) => state.loadingUnrigesteredAppUsers
);

export const selectAllUnregisteredAppUsers = createSelector(
  selectUnregisteredAppUsersFeature,
  (state) => state.unregisteredAppUsers
);

export { name };
export const {
  getAllUnregisteredAppUsersPending,
  getAllUnregisteredAppUsersFullfilled,
  getAllUnregisteredAppUsersRejected,
} = actions;
export default reducer;
