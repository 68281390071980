<admin-dashboard
  *ngIf="userService?.user?.position === 'purpl_admin'"
></admin-dashboard>
<app-partners
  *ngIf="
    userService?.user?.position !== 'purpl_admin' &&
    userService?.user?.position === 'partner'
  "
></app-partners>
<brand-manager-dashboard
  *ngIf="
    userService?.user?.position !== 'purpl_admin' &&
    userService?.user?.position !== 'partner'
  "
></brand-manager-dashboard>
