<br />
<div class="user-profile-view">
  <div>
    <img
      class="user-img rounded-circle"
      [src]="
        details?.image ? details?.image : '/assets/images/dashboard/man.png'
      "
      alt="app-user-profile-img"
    />
  </div>
  <div class="username-view">
    <label class="user-name"
      >{{ details?.first_name }} {{ details?.last_name }}</label
    ><br />
    <div class="other-details-view">
      <label
        class="status fully-verified"
        *ngIf="
          details?.compliance?.verification_status === 'Fully_Verified' ||
          details?.compliance?.verification_status === 'Verified' ||
          details?.compliance?.verification_status === 'Verified_2'
        "
      >
        <div class="mr-1">
          <img src="./assets/images/svg/check.svg" />
        </div>
        {{ details?.compliance?.verification_status }}</label
      >
      <label
        class="status soft-deleted"
        *ngIf="
          details?.compliance?.verification_status === 'Soft_Deleted' ||
          details?.compliance?.verification_status === 'Pending'
        "
        >{{ details?.compliance?.verification_status }}</label
      >
      <label
        class="status unverified"
        *ngIf="details?.compliance?.verification_status === 'Unverified'"
        >{{ details?.compliance?.verification_status }}</label
      >
      <label
        class="status jumio"
        *ngIf="
          details?.compliance?.verification_status === 'Bounced_Jumio' ||
          details?.compliance?.verification_status === 'Failed_Jumio'
        "
        >{{ details?.compliance?.verification_status }}</label
      >

      <label class="contacts">{{ details?.email }} </label>
      <label class="contacts"
        >+{{ details?.country_code }}{{ details?.phone_number }}</label
      >
    </div>
  </div>
</div>

<!-- <div class="filter-buttons">Transactions</div> -->

<div class="row mt-4 app-user-profile">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div
          class="col-xl-12 col-md-12"
          style="justify-content: center; align-content: center"
        >
          <div class="pull-right">
            <a class="btn btn-primary text-capitalize" (click)="clearFilter()"
              >Clear Sorting</a
            >
          </div>
        </div>
        <br />
        <div class="table-view">
          <table
            mat-table
            [dataSource]="dataSource"
            multiTemplateDataRows
            matSort
            class="mat-elevation-z8"
          >
            <ng-container
              matColumnDef="{{ def.key }}"
              *ngFor="let def of tableDef"
            >
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="header-text"
              >
                <div style="flex: 1">
                  <ng-container
                    *ngIf="
                      def.filter !== 'type' &&
                      def.filter !== 'transaction_status'
                    "
                  >
                    <div
                      class="has-sorter header"
                      (click)="onSortChanged(def.sortColumn)"
                      *ngIf="def.sorting"
                    >
                      {{ def.header }}
                    </div>
                    <ng-container *ngIf="!def.sorting">
                      {{ def.header }}
                    </ng-container></ng-container
                  >

                  <div class="header" *ngIf="def.searchable">
                    <ng-container *ngIf="def.filter === 'type'">
                      <mat-select
                        class="form-control cursor"
                        placeholder="Transaction Type"
                        (selectionChange)="
                          onSearchInputChanged(def, $event?.value)
                        "
                      >
                        <mat-option value="all"> All </mat-option>
                        <mat-option
                          *ngFor="let option of def.options || []"
                          [value]="option.value | lowercase"
                        >
                          {{ option.type | uppercase }}
                        </mat-option>
                      </mat-select>
                    </ng-container>

                    <ng-container *ngIf="def.filter === 'transaction_status'">
                      <mat-select
                        class="form-control cursor"
                        placeholder="Status"
                        (selectionChange)="
                          onSearchInputChanged(def, $event?.value)
                        "
                      >
                        <mat-option value="all"> All </mat-option>
                        <mat-option
                          *ngFor="let option of def.options || []"
                          [value]="option.transaction_status | lowercase"
                        >
                          {{ option.transaction_status | uppercase }}
                        </mat-option>
                      </mat-select>
                    </ng-container>
                  </div>
                </div>
              </th>

              <td
                mat-cell
                class="row-value row-data"
                *matCellDef="let element; in: transactions"
              >
                <!-- Transaction Type -->
                <div
                  *ngIf="def.header === 'Transaction type'"
                  class="transaction-view"
                >
                  <div>
                    <button
                      mat-icon-button
                      aria-label="expand row"
                      (click)="
                        expandedElement =
                          expandedElement === element ? null : element;
                        $event.stopPropagation()
                      "
                    >
                      <mat-icon
                        *ngIf="
                          expandedElement !== element &&
                          element.status_history.length > 0
                        "
                        class="iconStyle"
                        >keyboard_arrow_down</mat-icon
                      >
                      <mat-icon
                        *ngIf="
                          expandedElement === element &&
                          element.status_history.length > 0
                        "
                        class="iconStyle"
                        >keyboard_arrow_up</mat-icon
                      >
                    </button>
                  </div>

                  <div style="display: flex; flex-grow: 2">
                    <img
                      src="./assets/images/svg/remittance-rcvd.svg"
                      width="32"
                      height="32"
                      *ngIf="
                        element.status === 'Received' &&
                        element.type === 'REMITTANCE'
                      "
                    />

                    <img
                      src="./assets/images/svg/remittance-moved.svg"
                      width="32"
                      height="32"
                      *ngIf="
                        (element.status === 'Cashed Out' ||
                          element.status === 'Moved to wallet' ||
                          element.status === 'Accepted') &&
                        element.type === 'REMITTANCE'
                      "
                    />

                    <img
                      src="./assets/images/svg/remittance-returned.svg"
                      width="32"
                      height="32"
                      *ngIf="
                        (element.status === 'Returned' ||
                          element.status === 'Rejected' ||
                          element.status === 'Expired') &&
                        element.type === 'REMITTANCE'
                      "
                    />

                    <img
                      src="./assets/images/svg/cashout-cancelled.svg"
                      width="32"
                      height="32"
                      *ngIf="
                        (element.status === 'Cancelled' ||
                          element.status === 'Expired') &&
                        element.type === 'CASH_OUT'
                      "
                    />

                    <img
                      src="./assets/images/svg/cashout.svg"
                      width="32"
                      height="32"
                      *ngIf="
                        (element.status === 'Completed' ||
                          element.status === 'Pending' ||
                          element.status === 'Successful') &&
                        element.type === 'CASH_OUT'
                      "
                    />

                    <img
                      src="./assets/images/svg/payment.svg"
                      width="32"
                      height="32"
                      *ngIf="element.type === 'PAYMENT'"
                    />
                    <div style="padding-left: 8px">
                      {{ element[def.key].replace("_", " ") | titlecase }}
                      <div class="name" *ngIf="element.type !== 'CASH_OUT'">
                        {{ element.sender_name }}
                      </div>

                      <div class="name" *ngIf="element.type === 'CASH_OUT'">
                        {{
                          element?.transaction_object
                            ?.final_cash_out_branch_name === null
                            ? "N/A"
                            : element?.transaction_object
                                ?.final_cash_out_branch_name
                        }}
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Amount -->
                <div *ngIf="def.header === 'Amount'">
                  <div
                    class="incoming"
                    *ngIf="
                      element.type === 'REMITTANCE' ||
                      (element.type === 'TRANSFER' &&
                        element.status !== 'Expired' &&
                        element.status !== 'Rejected' &&
                        element.status !== 'Cancelled')
                    "
                  >
                    +${{ element[def.key] | number }}
                  </div>

                  <div
                    class="outgoing"
                    *ngIf="
                      (element.type === 'PAYMENT' ||
                        element.type === 'CASH_OUT') &&
                      element.status !== 'Cancelled' &&
                      element.status !== 'Expired'
                    "
                  >
                    -${{ element[def.key] | number }}
                  </div>

                  <div
                    *ngIf="
                      element.status === 'Cancelled' ||
                      element.status === 'Returned' ||
                      element.status === 'Rejected' ||
                      element.status === 'Expired'
                    "
                  >
                    ${{ element[def.key] | number }}
                  </div>
                </div>

                <!-- Dates -->
                <div
                  *ngIf="
                    def.header === 'Updated on' || def.header === 'Created on'
                  "
                  style="width: 100px"
                >
                  {{ element[def.key] | date : "MMM dd, YYYY" }}
                  {{ element[def.key] | date : "hh:mm a" }}
                </div>

                <!-- Status -->
                <div *ngIf="def.header === 'Status'">
                  {{ element[def.key] | titlecase }}
                </div>

                <!-- Other cols -->
                <div *ngIf="def.header === 'Provider' || def.header === 'ID'">
                  {{ element[def.key] }}
                </div>
              </td>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
              <div class="expanded-view">
                <tr class="mat-row-expanded">
                  <td
                    mat-cell
                    *matCellDef="let collapsedElement"
                    [attr.colspan]="columnsToDisplayWithExpand.length"
                  >
                    <div
                      class="example-element-detail"
                      [@detailExpand]="
                        collapsedElement == expandedElement
                          ? 'expanded'
                          : 'collapsed'
                      "
                    >
                      <table
                        #innerTables
                        mat-table
                        [dataSource]="collapsedElement?.status_history"
                        matSort
                        *ngIf="collapsedElement?.status_history.length > 0"
                      >
                        <ng-container
                          matColumnDef="{{ innerColumn }}"
                          *ngFor="
                            let innerColumn of innerDisplayedColumns;
                            last as isColumnLast
                          "
                        >
                          <ng-container>
                            <th
                              mat-header-cell-expanded
                              *matHeaderCellDef
                              class="history"
                            >
                              <span
                                *ngIf="
                                  !isColumnLast &&
                                  collapsedElement?.status_history.length > 0
                                "
                                class="row-value"
                                >Status history</span
                              >
                            </th>
                          </ng-container>
                          <ng-container
                            *ngFor="let e of collapsedElement?.status_history"
                          >
                            <ng-container>
                              <td *matCellDef="let item">
                                <div class="row-value">
                                  <div *ngIf="innerColumn === 'status'">
                                    {{ item.status | titlecase }}
                                  </div>
                                  <div *ngIf="innerColumn === 'updatedAt'">
                                    {{ item.updatedAt | date : "medium" }}
                                  </div>
                                </div>
                              </td>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                        <tr
                          mat-header-row
                          *matHeaderRowDef="innerDisplayedColumns"
                        ></tr>
                        <tr
                          mat-row
                          class="mat-row-expanded"
                          *matRowDef="let row; columns: innerDisplayedColumns"
                        ></tr>
                      </table>
                    </div>
                  </td>
                </tr>
              </div>
            </ng-container>

            <ng-container matColumnDef="expand">
              <th
                mat-header-cell
                *matHeaderCellDef
                aria-label="row actions"
                class="header-text"
                style="padding-left: 0%; padding-right: 0%"
              >
                Actions
              </th>
              <td *matCellDef="let element">
                <a
                  class="details-btn"
                  (click)="onViewTransaction(element, $event)"
                >
                  View details
                </a>
              </td>
            </ng-container>
            <tr class="mat-row" *matNoDataRow>
              <td
                class="row-value text-center"
                style="color: #808e9e; height: 124px"
                [attr.colspan]="columnsToDisplayWithExpand.length"
              >
                No data found
              </td>
            </tr>
            <tr>
              <td
                class="row-value text-center"
                style="color: #808e9e; height: 124px"
              >
                No data found
              </td>
            </tr>
            <tr
              mat-header-row
              *matHeaderRowDef="columnsToDisplayWithExpand"
            ></tr>
            <tr
              mat-row
              *matRowDef="let element; columns: columnsToDisplayWithExpand"
              class="example-element-row"
              [class.example-expanded-row]="expandedElement === element"
              (click)="
                expandedElement = expandedElement === element ? null : element
              "
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: ['expandedDetail']"
              class="example-detail-row"
            ></tr>
          </table>
        </div>
        <div
          class="d-flex justify-content-between m-t-10 w-100"
          *ngIf="users.length > 0"
        >
          <app-page-limiter
            (onPageLimitChanged)="onPageLimitChanged($event)"
            [pageLimit]="pageLimit"
          ></app-page-limiter>
          <ngb-pagination
            [collectionSize]="totalNumberOfRecords"
            [(page)]="page"
            [pageSize]="pageLimit"
            [boundaryLinks]="true"
            [disabled]="!hasMore"
            [boundaryLinks]="true"
            [ellipses]="true"
            (pageChange)="onPageChanged($event)"
          >
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
