import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  public loadingBarSource = new BehaviorSubject(false);
  loadingBarStatus = this.loadingBarSource.asObservable();

  private formSubmitButtonSource = new BehaviorSubject(false);
  formSubmitButtonStatus = this.formSubmitButtonSource.asObservable();

  constructor() { }

  /**
   * the following method is used to emit for loading bar
   * @param status
   */
  showLoadingBar(status: boolean) {
    this.loadingBarSource.next(status);
  }

  /**
   * the following method is used to emit for loading bar
   * @param submit
   */
  changeFormSubmitStatus(submit: boolean) {
    this.formSubmitButtonSource.next(submit);
  }
}
