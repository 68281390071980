<div class="d-flex flex-row align-items-center justify-content-start h-100">
  <ng-container *ngIf="value?.length > 0 || value?.length !== null">
    <div
      class="view-details-btn"
      (click)="onViewDetail(value)"
      title="View Details"
    >
      {{ value }}
    </div>
  </ng-container>
</div>
