import {
  createFeature,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import { createSlice } from '@reduxjs/toolkit';
import { ApiResponseBodyInterface } from '../../interfaces/api-response.interface';

export interface CashoutTransactionState {
  cashout: ApiResponseBodyInterface | null;
  loadingCashout: boolean;
}

const initialState: CashoutTransactionState = {
  cashout: null,
  loadingCashout: false,
};

const { actions, reducer, name } = createSlice({
  name: 'cashoutTransaction',
  initialState,
  reducers: {
    getAllCashoutTransactionPending: (state) => {
      state.loadingCashout = true;
    },
    getAllCashoutTransactionFullfilled: (state, { payload }) => {
      state.loadingCashout = false;
      state.cashout = payload;
    },
    getAllCashoutTransactionRejected: (state, { payload }) => {
      state.loadingCashout = true;
    },
  },
});

const selectCashoutTransactionFeature =
  createFeatureSelector<ReturnType<typeof reducer>>(name);

export const selectCashoutTransationLoading = createSelector(
  selectCashoutTransactionFeature,
  (state) => state.loadingCashout
);

export const selectAllCashoutTransaction = createSelector(
  selectCashoutTransactionFeature,
  (state) => state.cashout
);

export { name };
export const {
  getAllCashoutTransactionPending,
  getAllCashoutTransactionFullfilled,
  getAllCashoutTransactionRejected,
} = actions;
export default reducer;
