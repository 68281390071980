import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { ENCRYPTION_SECRET } from '../constants/constant-list';
import {
  CHANGE_PASSWORD,
  DASHBOARD,
  LOGIN,
  TWO_FACTOR,
} from '../constants/routes-list';
import { User } from '../interfaces/User';
import { SessionStorageService, UserService } from '../services';

@Injectable()
export class CanActivateGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private sessionService: SessionStorageService
  ) {}
  /**
   * The following checks if the access can be provided or not to the current user
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isAuthRoute = state.url?.indexOf('/auth') > -1;
    const isDashboardRoute = state.url?.indexOf('/dashboard') > -1;
    const isLoggedIn = this.userService.isLoggedIn();
    const user: User | null | undefined = this.userService.user;
    const twoFAuth = this.sessionService.getEncryptedData(
      '2FAuth',
      ENCRYPTION_SECRET
    );

    if (isLoggedIn) {
      if (isAuthRoute) {
        return true;
      } else {
        if (!user?.password_changed) {
          if (isDashboardRoute) {
            return this.router.navigateByUrl(LOGIN).then().catch();
          } else {
            return this.router.navigateByUrl(CHANGE_PASSWORD).then().catch();
          }
        } else {
          return true;
        }
      }
    } else if (!isLoggedIn && isAuthRoute) {
      return true;
    } else if (twoFAuth) {
      return this.router.navigateByUrl(TWO_FACTOR).then().catch();
    } else {
      return true;
    }
  }
}