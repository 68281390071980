import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { isUndefined } from 'lodash';
import {
  ACCEPTED_REMITTANCE_BASE_URL,
  APP_PARTNERS,
  BENEFICIARIES,
  TRANSFERS,
} from '../constants/apis-list';
import {
  DEFAULT_PAGE_INDEX,
  NUMBER_RECORDS_PER_PAGE,
} from '../constants/constant-list';
import { ApiResponseBodyInterface } from '../interfaces/api-response.interface';
import { BaseNetworkService } from './base-network.service';
import {
  getAllBeneficiariesFullfilled,
  getAllBeneficiariesPending,
  getAllBeneficiariesRejected,
  getAllPartnersFullfilled,
  getAllPartnersPending,
  getAllPartnersRejected,
  getAllTransactionsFullfilled,
  getAllTransactionsPending,
  getAllTransactionsRejected,
} from '../stores/slices/AppPartners';
import { PartnerBody } from '../interfaces/Partner';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppPartnersService extends BaseNetworkService {
  constructor(private injector: Injector, private store: Store) {
    super(injector);
  }

  getAllPartners(params?: HttpParams) {
    let _params: HttpParams = new HttpParams();
    _params = _params.set('limit', NUMBER_RECORDS_PER_PAGE);
    _params = _params.set('offset', DEFAULT_PAGE_INDEX);

    this.store.dispatch(getAllPartnersPending());
    this.dataService
      .fetchData({
        apiUrl: APP_PARTNERS,
        method: 'GET',
        params: isUndefined(params?.toString()) ? _params : params,
      })
      .subscribe(
        (resource: ApiResponseBodyInterface) =>
          this.store.dispatch(getAllPartnersFullfilled(resource)),
        (error) =>
          this.store.dispatch(getAllPartnersRejected(error?.response?.results))
      );
  }

  createPartner(body: PartnerBody) {
    return this.dataService
      .fetchData({
        apiUrl: this.apiList.ADD_PARTNER,
        method: 'POST',
        contentType: 'application/json',
        params: null,
        body,
      })
      .pipe(
        map((response) => {
          return response && response.data ? response.data : null;
        })
      );
  }

  getPartner(id: string) {
    return this.dataService
      .fetchData({
        apiUrl: this.apiList.PARTNER.replace('{id}', id),
        method: 'GET',
        contentType: 'application/json',
        params: null,
        body: null,
      })
      .pipe(
        map((response) => {
          return response && response.data ? response.data : null;
        })
      );
  }

  updatePartner(id: string, body: PartnerBody) {
    return this.dataService
      .fetchData({
        apiUrl: this.apiList.PARTNER.replace('{id}', id),
        method: 'PATCH',
        contentType: 'application/json',
        params: null,
        body,
      })
      .pipe(
        map((response) => {
          return response && response.data ? response.data : null;
        })
      );
  }

  exportPartners(userEmail: any) {
    // let email = `janine@purplme.com`;
    return this.dataService.fetchData({
      apiUrl: `${ACCEPTED_REMITTANCE_BASE_URL}/?limit=${1001}&send_to=${userEmail}&status=${'Accepted'}`,
      method: 'GET',
      contentType: 'application/json',
      observe: 'response',
      isRawResponse: true,
    });
  }

  getAllPartnersTransactions(params?: HttpParams) {
    let _params: HttpParams = new HttpParams();
    _params = _params.set('limit', NUMBER_RECORDS_PER_PAGE);
    _params = _params.set('offset', DEFAULT_PAGE_INDEX);

    this.store.dispatch(getAllTransactionsPending());
    this.dataService
      .fetchData({
        apiUrl: TRANSFERS,
        method: 'GET',
        params: isUndefined(params?.toString()) ? _params : params,
      })
      .subscribe(
        (resource: ApiResponseBodyInterface) =>
          this.store.dispatch(getAllTransactionsFullfilled(resource)),
        (error) =>
          this.store.dispatch(
            getAllTransactionsRejected(error?.response?.results)
          )
      );
  }

  getAllBeneficiaries(params?: HttpParams) {
    let _params: HttpParams = new HttpParams();
    _params = _params.set('limit', NUMBER_RECORDS_PER_PAGE);
    _params = _params.set('offset', DEFAULT_PAGE_INDEX);

    this.store.dispatch(getAllBeneficiariesPending());
    this.dataService
      .fetchData({
        apiUrl: BENEFICIARIES,
        method: 'GET',
        params: isUndefined(params?.toString()) ? _params : params,
      })
      .subscribe(
        (resource: ApiResponseBodyInterface) =>
          this.store.dispatch(getAllBeneficiariesFullfilled(resource)),
        (error) =>
          this.store.dispatch(
            getAllBeneficiariesRejected(error?.response?.results)
          )
      );
  }
}
