import { Component, Injector, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { PERMISSION } from 'src/app/shared/constants/constant-list';
import FEATURES from 'src/app/shared/constants/navigation';
import { User } from 'src/app/shared/interfaces/User';
import { UserService } from 'src/app/shared/services';
import { getAllPermissionFullfilled } from 'src/app/shared/stores/slices/Permissions';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent extends BaseComponent implements OnInit {
  user: User | null | undefined;
  public PERMISSION: any = PERMISSION;
  public FEATURES: any = FEATURES;

  constructor(
    injector: Injector,
    public userService: UserService,
    private store: Store
  ) {
    super(injector);
    this.user = this.userService.user;

    if (this.userService.isLoggedIn()) {
      this.userService.getUserPrivileges().subscribe((response) => {
        this.store.dispatch(getAllPermissionFullfilled(response));
      });
    }
  }

  ngOnInit() {}
}
