import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
} from 'ag-grid-community';
import { isEmpty } from 'lodash';
import { BeneficiaryRendererComponent } from '../../renderers/beneficiary-renderer/beneficiary-renderer.component';
import { ComplianceBetaFiltersComponent } from 'src/app/pages/compliance-beta/components/compliance-beta-filters/compliance-beta-filters.component';
import { PartnersFilterRendererComponent } from '../../renderers/partners-filter-renderer/partners-filter-renderer.component';

@Component({
  selector: 'app-beneficiaries-ag-grid',
  templateUrl: './beneficiaries-ag-grid.component.html',
  styleUrls: ['./beneficiaries-ag-grid.component.scss'],
})
export class BeneficiariesAgGridComponent implements OnInit {
  private gridApi!: GridApi<any>;
  public gridColumnApi: any = {};

  tableColumns: any[] = [];
  tableFilters: any[] = [];

  @Input() params: HttpParams | undefined;
  @Input() tableDataListItems: any[] = [];
  @Input() pageLimit?: number;
  @Input() page?: number;
  @Input() totalElements?: number;
  @Input() hasMore?: boolean;

  @Output() onPageLimitChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPageChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() onColumnFilterChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {
    this.setTableConfigurationByLanguage();
  }

  setTableConfigurationByLanguage(): void {
    this.tableColumns = [
      {
        field: 'id',
        headerName: 'ID',
        width: 150,
        filter: true,
        floatingFilter: true,
        floatingFilterComponent: PartnersFilterRendererComponent,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          filter: 'id',
          onSearchEvent: (event: any) => {
            this.onSearchInputChanged(event);
          },
        },
      },
      {
        field: 'first_name',
        headerName: 'FIRST NAME',
        width: 330,
        cellRendererSelector: (params: ICellRendererParams<any>) => {
          return {
            component: BeneficiaryRendererComponent,
            params: {
              ...params,
              httpParams: this.params,
            },
          };
        },
        filter: true,
        floatingFilter: true,
        floatingFilterComponent: PartnersFilterRendererComponent,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          filter: 'first_name__icontains',
          onSearchEvent: (event: any) => {
            this.onSearchInputChanged(event);
          },
        },
      },
      {
        field: 'last_name',
        headerName: 'LAST NAME',
        width: 330,
        filter: true,
        floatingFilter: true,
        floatingFilterComponent: PartnersFilterRendererComponent,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          filter: 'last_name__icontains',
          onSearchEvent: (event: any) => {
            this.onSearchInputChanged(event);
          },
        },
      },
      {
        field: 'email',
        headerName: 'EMAIL',
        width: 400,
        filter: true,
        floatingFilter: true,
        floatingFilterComponent: PartnersFilterRendererComponent,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          filter: 'email__icontains',
          onSearchEvent: (event: any) => {
            this.onSearchInputChanged(event);
          },
        },
      },
      {
        field: 'phone_number',
        headerName: 'PHONE NUMBER',
        width: 250,
        filter: true,
        floatingFilter: true,
        floatingFilterComponent: PartnersFilterRendererComponent,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          filter: 'phone_number__icontains',
          onSearchEvent: (event: any) => {
            this.onSearchInputChanged(event);
          },
        },
      },
    ];
  }

  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (!isEmpty(this.tableDataListItems)) {
      this.gridApi.setRowData(this.tableDataListItems);
    }
  }

  onSearchInputChanged(event: { filter: string; value: string }) {
    const appIndex = this.tableFilters.findIndex(
      (i) => i.field === event.filter
    );

    if (appIndex === -1) {
      this.tableFilters.push({
        field: event.filter,
        search: event.value,
      });
    } else {
      this.tableFilters[appIndex].field = event.filter;
      this.tableFilters[appIndex].search = event.value;
    }

    this.onColumnFilterChanged.emit(this.tableFilters);
  }
}
