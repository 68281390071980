import { createFeatureSelector, createSelector } from '@ngrx/store';
import { createSlice } from '@reduxjs/toolkit';
import { IFormattedModules } from '../../interfaces/RolesAccess';

export interface PermissionState {
  permissions: IFormattedModules[];
  loadingPermissions: boolean;
}

const initialState: PermissionState = {
  permissions: [],
  loadingPermissions: false,
};

const { actions, reducer, name } = createSlice({
  name: '_permissions',
  initialState,
  reducers: {
    getAllPermissionPending: (state) => {
      state.loadingPermissions = true;
    },
    getAllPermissionFullfilled: (state, { payload }) => {
      state.loadingPermissions = false;
      state.permissions = payload;
    },
    getAllPermissionRejected: (state, { payload }) => {
      state.loadingPermissions = false;
    },
  },
});

const selectPermissionFeature =
  createFeatureSelector<ReturnType<typeof reducer>>(name);

export const selectPermissionsLoading = createSelector(
  selectPermissionFeature,
  (state) => state.loadingPermissions
);

export const selectAllPermissions = createSelector(
  selectPermissionFeature,
  (state) => state.permissions
);

export { name };
export const {
  getAllPermissionPending,
  getAllPermissionFullfilled,
  getAllPermissionRejected,
} = actions;
export default reducer;
