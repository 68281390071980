import { createFeatureSelector, createSelector } from '@ngrx/store';
import { createSlice } from '@reduxjs/toolkit';
import { ApiResponseBodyInterface } from '../../interfaces/api-response.interface';

export interface RevenueTransactionState {
  revenue: ApiResponseBodyInterface | null;
  loadingRevenue: boolean;
}

const initialState: RevenueTransactionState = {
  revenue: null,
  loadingRevenue: false,
};

const { actions, reducer, name } = createSlice({
  name: 'revenueTransaction',
  initialState,
  reducers: {
    getAllRevenueTransactionPending: (state) => {
      state.loadingRevenue = true;
    },
    getAllRevenueTransactionFullfilled: (state, { payload }) => {
      state.loadingRevenue = false;
      state.revenue = payload;
    },
    getAllRevenueTransactionRejected: (state, { payload }) => {
      state.loadingRevenue = true;
    },
  },
});

const selectRevenueTransactionFeature =
  createFeatureSelector<ReturnType<typeof reducer>>(name);

export const selectRevenueTransactionLoading = createSelector(
  selectRevenueTransactionFeature,
  (state) => state.loadingRevenue
);

export const selectAllRevenueTransactions = createSelector(
  selectRevenueTransactionFeature,
  (state) => state.revenue
);

export { name };
export const {
  getAllRevenueTransactionPending,
  getAllRevenueTransactionFullfilled,
  getAllRevenueTransactionRejected,
} = actions;
export default reducer;
