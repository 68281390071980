import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexPlotOptions,
  ApexResponsive,
  ApexXAxis,
  ApexYAxis,
} from 'ng-apexcharts';

export class BaseModel {
  public id: number | null = null;
  public active = true;
  public created_at: string | null = null;
  public updated_at: string | null = null;
  public modified_at: string | null = null;
  public deleted_at: string | null = null;
}

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  legend: ApexLegend;
  fill: ApexFill;
};
