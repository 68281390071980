import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SkeletonItemComponent } from './skeleton-item.component';

@NgModule({
  declarations: [SkeletonItemComponent],
  exports: [SkeletonItemComponent],
  imports: [CommonModule],
})
export class SkeletonItemModule {}
