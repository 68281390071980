import { Injectable, Injector } from '@angular/core';
import { BaseNetworkService } from './base-network.service';
import { map } from 'rxjs';
import { URLQueryParams } from '../interfaces/query-params.interface';
import {
  DEFAULT_PAGE_INDEX,
  NUMBER_RECORDS_PER_PAGE,
} from '../constants/constant-list';
import { Store } from '@ngrx/store';
import {
  getAllCMSUsersFullfilled,
  getAllCMSUsersPending,
  getAllCMSUsersRejected,
} from '../stores/slices/CMSUsers';
import { ApiResponseInterface } from '../interfaces/api-response.interface';

@Injectable({
  providedIn: 'root',
})
export class CmsUsersService extends BaseNetworkService {
  constructor(private injector: Injector, private store: Store) {
    super(injector);
  }

  getAllCMSUsers(query?: Partial<URLQueryParams> | any) {
    const requestBody: any = {
      ...query,
      from_dropdown: true,
      page: query?.page || DEFAULT_PAGE_INDEX,
      size: query?.size || NUMBER_RECORDS_PER_PAGE,
      search: query?.search || '',
    };

    this.store.dispatch(getAllCMSUsersPending());
    this.dataService
      .fetchData({
        apiUrl: this.apiList.USER_LISTING,
        body: requestBody,
      })
      .pipe(
        map((response) => {
          return response && response.data ? response.data : null;
        })
      )
      .subscribe(
        (resource: ApiResponseInterface) =>
          this.store.dispatch(getAllCMSUsersFullfilled(resource)),
        (error) =>
          this.store.dispatch(
            getAllCMSUsersRejected({
              error: error?.response?.data || error,
            })
          )
      );
  }
}
