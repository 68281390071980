import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericTableComponent } from './purpl-table.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../../pipes/pipes.module';
import { VerificationBadgeModule } from '../verification-badge/verification-badge.module';
import { PurplTableVideoComponent } from './components/purpl-table-video/purpl-table-video.component';
import { PurplTableHyperlinkComponent } from './components/purpl-table-hyperlink/purpl-table-hyperlink.component';
import { PurplTableHtmlComponent } from './components/purpl-table-html/purpl-table-html.component';
import { PurplTableAvatarComponent } from './components/purpl-table-avatar/purpl-table-avatar.component';
import { PurplTablePlatformComponent } from './components/purpl-table-platform/purpl-table-platform.component';
import { PurplTableFullNameComponent } from './components/purpl-table-full-name/purpl-table-full-name.component';
import { PurplTableRatingComponent } from './components/purpl-table-rating/purpl-table-rating.component';
import { PurplTableMoreDetailsComponent } from './components/purpl-table-more-details/purpl-table-more-details.component';
import { PurplTableFeedbackComponent } from './components/purpl-table-feedback/purpl-table-feedback.component';
import { PurplTableButtonComponent } from './components/purpl-table-button/purpl-table-button.component';
import { PurpleTableStatusComponent } from './components/purple-table-status/purple-table-status.component';
import { PurplTableTextComponent } from './components/purpl-table-text/purpl-table-text.component';
import { PageLimiterModule } from '../page-limiter/page-limiter.module';
import { PurplTableOpenNewTabComponent } from './components/purpl-table-open-new-tab/purpl-table-open-new-tab.component';
import { PurplTableCommentComponent } from './components/purpl-table-comment/purpl-table-comment.component';
import { PurplTablePopupLinkComponent } from './components/purpl-table-popup-link/purpl-table-popup-link.component';
import { PurplPopupAvatarComponent } from './components/purpl-popup-avatar/purpl-popup-avatar.component';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [
    GenericTableComponent,
    PurplTableVideoComponent,
    PurplTableHyperlinkComponent,
    PurplTableHtmlComponent,
    PurplTableAvatarComponent,
    PurplTablePlatformComponent,
    PurplTableFullNameComponent,
    PurplTableRatingComponent,
    PurplTableMoreDetailsComponent,
    PurplTableFeedbackComponent,
    PurplTableButtonComponent,
    PurpleTableStatusComponent,
    PurplTableTextComponent,
    PurplTableOpenNewTabComponent,
    PurplTableCommentComponent,
    PurplTablePopupLinkComponent,
    PurplPopupAvatarComponent,
  ],
  exports: [
    GenericTableComponent,
    PurplTableVideoComponent,
    PurplTableHyperlinkComponent,
    PurplTableHtmlComponent,
    PurplTableAvatarComponent,
    PurplTablePlatformComponent,
    PurplTableFullNameComponent,
    PurplTableRatingComponent,
    PurplTableMoreDetailsComponent,
    PurplTableFeedbackComponent,
    PurplTableButtonComponent,
    PurpleTableStatusComponent,
    PurplTableTextComponent,
    PurplTableOpenNewTabComponent,
    PurplTableCommentComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    RouterModule,
    PipesModule,
    VerificationBadgeModule,
    PageLimiterModule,
    MatSelectModule,
  ],
})
export class PurplTableModule {}
