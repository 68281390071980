import { createFeatureSelector, createSelector } from '@ngrx/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiResponseBodyInterface } from '../../interfaces/api-response.interface';
import { IUser } from '../../interfaces/User';
import { Features } from '../../interfaces/Features';

export interface AppUsersState {
  appUsers: ApiResponseBodyInterface | null;
  loadingAppUsers: boolean;

  appUser: IUser | null;
  appUserFeatures: Features[];

  userTransactions: ApiResponseBodyInterface | null;
  loadingTransactions: boolean;
}

const initialState: AppUsersState = {
  appUsers: null,
  loadingAppUsers: false,

  appUser: null,
  appUserFeatures: [],

  userTransactions: null,
  loadingTransactions: false,
};

const { actions, reducer, name } = createSlice({
  name: 'appUsers',
  initialState,
  reducers: {
    // App Users
    getAllAppUsersPending: (state) => {
      state.loadingAppUsers = true;
    },
    getAllAppUsersFullfilled: (state, { payload }) => {
      state.loadingAppUsers = false;
      state.appUsers = payload;
    },
    getAllAppUsersRejected: (state, { payload }) => {
      state.loadingAppUsers = true;
    },

    setSelectedUser: (state, { payload }) => {
      state.appUser = payload;
    },
    setSelectedUserFeatures: (
      state,
      { payload }: PayloadAction<Features[]>
    ) => {
      state.appUserFeatures = payload;
    },

    // User transactions
    getAllUserTransactionsPending: (state) => {
      state.loadingTransactions = true;
    },
    getAllUserTransactionsFullfilled: (state, { payload }) => {
      state.loadingTransactions = false;
      state.userTransactions = payload;
    },
    getAllUserTransactionsRejected: (state, { payload }) => {
      state.loadingTransactions = true;
    },
  },
});

const selectAppUsersFeature =
  createFeatureSelector<ReturnType<typeof reducer>>(name);

const selectUserTransactionsFeature =
  createFeatureSelector<ReturnType<typeof reducer>>(name);

export const selectAppUsersLoading = createSelector(
  selectAppUsersFeature,
  (state) => state.loadingAppUsers
);

export const selectUserTransactionsLoading = createSelector(
  selectUserTransactionsFeature,
  (state) => state.loadingTransactions
);

export const selectAllAppUsers = createSelector(
  selectAppUsersFeature,
  (state) => state.appUsers
);

export const selectAllTransactions = createSelector(
  selectUserTransactionsFeature,
  (state) => state.userTransactions
);

export const selectAppUser = createSelector(
  selectUserTransactionsFeature,
  (state) => ({ ...state.appUser, features: state.appUserFeatures })
);

export const selectAppUserFeatures = createSelector(
  selectUserTransactionsFeature,
  (state) => state.appUserFeatures
);


export { name };
export const {
  getAllAppUsersPending,
  getAllAppUsersFullfilled,
  getAllAppUsersRejected,
  getAllUserTransactionsPending,
  getAllUserTransactionsFullfilled,
  getAllUserTransactionsRejected,
  setSelectedUser,
  setSelectedUserFeatures,
} = actions;
export default reducer;
