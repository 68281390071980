import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-purpl-table-text',
  templateUrl: './purpl-table-text.component.html',
  styleUrls: ['./purpl-table-text.component.scss'],
})
export class PurplTableTextComponent implements OnInit {
  @Input() label?: string;
  @Input() status?: string;
  @Input() key?: string;

  constructor() {}

  ngOnInit(): void {}
}
