import { createFeatureSelector, createSelector } from '@ngrx/store';
import { createSlice } from '@reduxjs/toolkit';
import { ApiResponseBodyInterface } from '../../interfaces/api-response.interface';

export interface DashboardBMUsersVisitsState {
  dashboardBMUsersVisits: ApiResponseBodyInterface | null;
  loadingDashboardBMUsersVisits: boolean;
}

const initialState: DashboardBMUsersVisitsState = {
  dashboardBMUsersVisits: null,
  loadingDashboardBMUsersVisits: false,
};

const { actions, reducer, name } = createSlice({
  name: 'dashboardBMUsersVisits',
  initialState,
  reducers: {
    getAllUsersVisitsPending: (state) => {
      state.loadingDashboardBMUsersVisits = true;
    },
    getAllUsersVisitsFullfilled: (state, { payload }) => {
      state.loadingDashboardBMUsersVisits = false;
      state.dashboardBMUsersVisits = payload;
    },
    getAllUsersVisitsRejected: (state, { payload }) => {
      state.loadingDashboardBMUsersVisits = true;
    },
  },
});

const selectDashboardBMFeature =
  createFeatureSelector<ReturnType<typeof reducer>>(name);

export const selectDashboardBMLoading = createSelector(
  selectDashboardBMFeature,
  (state) => state.loadingDashboardBMUsersVisits
);

export const selectDashboardBMAllUsersVisits = createSelector(
  selectDashboardBMFeature,
  (state) => state.dashboardBMUsersVisits
);

export { name };
export const {
  getAllUsersVisitsPending,
  getAllUsersVisitsFullfilled,
  getAllUsersVisitsRejected,
} = actions;
export default reducer;
