<div
  class="text-center"
  (click)="$event.stopPropagation()"
  [title]="'Delete item'"
>
  <span
    class="fa fa-trash fa-lg cursor delete-icon"
    alt="delete-icon"
    (click)="onPress.emit($event)"
  ></span>
</div>
