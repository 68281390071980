import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-purpl-table-popup-link',
  templateUrl: './purpl-table-popup-link.component.html',
  styleUrls: ['./purpl-table-popup-link.component.scss'],
})
export class PurplTablePopupLinkComponent implements OnInit {
  @Input() label?: string;
  @Input() data?: any;
  @Input() popContent?: TemplateRef<any>;
  @Output() onTogglePopover: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}
}
