import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppDashboardTitleModule } from '../app-dashboard-title/app-dashboard-title.module';
import { BreadcrumbComponent } from './breadcrumb.component';

@NgModule({
  declarations: [BreadcrumbComponent],
  imports: [CommonModule, AppDashboardTitleModule],
  exports: [BreadcrumbComponent],
})
export class BreadcrumbModule {}
