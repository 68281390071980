import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuicklinkStrategy } from 'ngx-quicklink';

import { LoginComponent } from './pages/auth/login/login.component';
import { ForgotPasswordComponent } from './pages/auth/forgot-password/forgot-password.component';
import { CanActivateGuard } from './shared/guards/can-activate.guard';
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';
import { TwoFactorAuthComponent } from './pages/auth/two-factor-auth/two-factor-auth.component';
import { ChangePwLoginComponent } from './pages/auth/change-pw-login/change-pw-login.component';
// import { JumioCallbackComponent } from './components/jumio-callback/jumio-callback.component';

const routes: Routes = [
  {
    path: 'auth/login',
    component: LoginComponent,
    canActivate: [CanActivateGuard],
  },
  {
    path: 'auth/forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [CanActivateGuard],
  },
  {
    path: 'auth/reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'auth/two-factor',
    component: TwoFactorAuthComponent,
  },
  {
    path: 'auth/change-password',
    component: ChangePwLoginComponent,
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/layout/layout.module').then((m) => m.LayoutModule),
    canActivate: [CanActivateGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
      preloadingStrategy: QuicklinkStrategy,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
