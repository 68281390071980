<div class="w-100">
  <div class="form-group has-search">
    <span class="fa fa-search form-control-feedback"></span>
    <input
      type="text"
      class="form-control"
      #input
      [placeholder]="placeholder"
      [value]="value || ''"
      (keyup.enter)="onSearchKeyPress()"
    />
  </div>
</div>
