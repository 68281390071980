<div class="page-wrapper backgrund-pic">
  <div class="authentication-box">
    <div class="container">
      <div class="row">
        <div class="col-md-5 p-0 card-left">
          <div class="card bg-primary card-setting">
            <div class="logo-wrapper text-center m-auto">
              <a class="text-center">
                <img class="brand-logo blur-up lazyloaded brand-zoom"
                     src="assets/images/dashboard/purpl-logo.png" alt="">
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-7 p-0 card-right">
          <div class="card tab2-card">
            <div class="card-body body-height">
              <div class="login-text mb-5">
                Reset Password
              </div>
              <div class="text-color mb-3">
                A link will be send to your email, allowing you to change your password
              </div>
              <form [formGroup]="formGroup" class="form-horizontal auth-form" novalidate
                    (keyup.enter)="formGroup.valid ? onSubmit() : null;">
                <div class="form-group">
                  <input required name="email" type="email" class="form-control" formControlName="email"
                         placeholder="Email">
                  <ng-container *ngIf="checkControlValidity('email','invalid', formGroup, formSubmitted)">
                    <small class="text-danger col">
                      {{getErrorMessage('email')}}
                    </small>
                  </ng-container>
                </div>
                <div class="form-terms">
                  <div class="custom-control custom-checkbox mr-sm-2">
                    <a href="javascript:void(0)" class="btn btn-default forgot-pass"
                       [routerLink]="routeList.LOGIN">
                      Login
                    </a>
                  </div>
                </div>
                <div class="form-button">
                  <button class="btn btn-primary" type="button" (click)="onSubmit();"
                          [disabled]="(sharedDataService.loadingBarStatus | async) || formGroup.invalid">
                    Recover
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
