import { createFeatureSelector, createSelector } from '@ngrx/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiResponseBodyInterface } from '../../interfaces/api-response.interface';

export interface AppPartnersState {
  partners: ApiResponseBodyInterface | null;
  loadingPartners: boolean;

  partnersTransactions: ApiResponseBodyInterface | null;
  loadingTransactions: boolean;

  beneficiaries: ApiResponseBodyInterface | null;
  loadingBeneficiaries: boolean;
}

const initialState: AppPartnersState = {
  partners: null,
  loadingPartners: false,

  partnersTransactions: null,
  loadingTransactions: false,

  beneficiaries: null,
  loadingBeneficiaries: false,
};

const { actions, reducer, name } = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    // partners
    getAllPartnersPending: (state) => {
      state.loadingPartners = true;
    },
    getAllPartnersFullfilled: (
      state,
      { payload }: PayloadAction<ApiResponseBodyInterface>
    ) => {
      state.loadingPartners = false;
      state.partners = payload;
    },
    getAllPartnersRejected: (state) => {
      state.loadingPartners = true;
    },

    // transactions
    getAllTransactionsPending: (state) => {
      state.loadingTransactions = true;
    },
    getAllTransactionsFullfilled: (
      state,
      { payload }: PayloadAction<ApiResponseBodyInterface>
    ) => {
      state.loadingTransactions = false;
      state.partnersTransactions = payload;
    },
    getAllTransactionsRejected: (state) => {
      state.loadingTransactions = true;
    },

    // beneficiaries
    getAllBeneficiariesPending: (state) => {
      state.loadingBeneficiaries = true;
    },
    getAllBeneficiariesFullfilled: (
      state,
      { payload }: PayloadAction<ApiResponseBodyInterface>
    ) => {
      state.loadingBeneficiaries = false;
      state.beneficiaries = payload;
    },
    getAllBeneficiariesRejected: (state) => {
      state.loadingBeneficiaries = true;
    },
  },
});

const selectPartnersFeature =
  createFeatureSelector<ReturnType<typeof reducer>>(name);

const selectPartnersTransactionFeature =
  createFeatureSelector<ReturnType<typeof reducer>>(name);

const selectBeneficiariesFeature =
  createFeatureSelector<ReturnType<typeof reducer>>(name);

export const selectPartnersLoading = createSelector(
  selectPartnersFeature,
  (state) => state.loadingPartners
);

export const selectTransactionsLoading = createSelector(
  selectPartnersTransactionFeature,
  (state) => state.loadingPartners
);

export const selectBeneficiariesLoading = createSelector(
  selectBeneficiariesFeature,
  (state) => state.loadingBeneficiaries
);

export const selectAllPartners = createSelector(
  selectPartnersFeature,
  (state) => state.partners
);

export const selectAllPartnersTransactions = createSelector(
  selectPartnersTransactionFeature,
  (state) => state.partnersTransactions
);

export const selectAllBeneficiaries = createSelector(
  selectBeneficiariesFeature,
  (state) => state.beneficiaries
);

export { name };
export const {
  getAllPartnersPending,
  getAllPartnersFullfilled,
  getAllPartnersRejected,
  getAllTransactionsPending,
  getAllTransactionsFullfilled,
  getAllTransactionsRejected,
  getAllBeneficiariesPending,
  getAllBeneficiariesFullfilled,
  getAllBeneficiariesRejected,
} = actions;

export default reducer;
