import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  constructor() {}

  get(key: string): any {
    return this.getDataInSessionStorage(key);
  }

  // @ts-ignore
  set({ key, value }): void {
    this.setDataInSessionStorage({ key, value });
  }

  getEncryptedData(key: string, secret: any = null): any {
    try {
      const item = sessionStorage.getItem(key);
      if (item && item != 'undefined') {
        const bytes = CryptoJS.AES.decrypt(item, secret);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return null;
    } catch {
      return null;
    }
  }

  // @ts-ignore
  setEncryptData({ key, value }, secret: any = null): void {
    sessionStorage.setItem(
      key,
      CryptoJS.AES.encrypt(JSON.stringify(value), secret).toString()
    );
  }

  // @ts-ignore
  setDataInSessionStorage({ key, value }): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  getDataInSessionStorage(key: string): any {
    try {
      const item = sessionStorage.getItem(key);
      if (item && item != 'undefined') {
        return JSON.parse(item);
      }

      return null;
    } catch {
      return null;
    }
  }

  removeDataFromSessionStorage(key: string): void {
    sessionStorage.removeItem(key);
  }

  remove(key: string): void {
    this.removeDataFromSessionStorage(key);
  }

  cleatDataInSessionStorage(): void {
    sessionStorage.clear();
  }

  getToken(): any {
    return this.getDataInSessionStorage('purpl_token');
  }

  storeLocalStorageInSessionStorage(): void {
    for (let i = 0; i < localStorage.length; i++) {
      this.setDataInSessionStorage({
        key: localStorage.key(i),
        // @ts-ignore
        value: JSON.parse(localStorage.getItem(localStorage.key(i))),
      });
    }
  }
}
