import { Component, Injector } from '@angular/core';
import { isEmpty, orderBy } from 'lodash';
import {
  map,
  finalize,
  tap,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs/operators';
import { Positions } from 'src/app/shared/interfaces/Positions';
import { HelperService, LocalStorageService } from 'src/app/shared/services';
import { ListingBaseComponent } from '../../../shared/components/base/listing-base.component';
import { Store } from '@ngrx/store';
import {
  selectAllCMSUsers,
  selectCMSUsersLoading,
} from 'src/app/shared/stores/slices/CMSUsers';
import { ApiResponseBodyInterface } from 'src/app/shared/interfaces/api-response.interface';
import {
  DEFAULT_PAGE_INDEX,
  NUMBER_RECORDS_PER_PAGE,
} from 'src/app/shared/constants/constant-list';
import { CmsUsersService } from 'src/app/shared/services/cms-users.service';
import { HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent extends ListingBaseComponent {
  filterSearchText = new Subject<any>();

  params: HttpParams = new HttpParams();

  queryParams: Params = {};
  positions: Positions[] = [];
  position: any;
  loading = false;

  tableSearching: any = {};
  tableFilters: any[] = [];
  tableDataListItems: any[] = [];
  tableColumns: any[] = [];

  pageLimit: number = NUMBER_RECORDS_PER_PAGE;
  page: number = DEFAULT_PAGE_INDEX;
  lastPage = 0;
  totalNumberOfPages = 0;
  totalNumberOfRecords = 0;
  hasMore = false;
  selected?: string;

  filterParams = ['firstName', 'lastName', 'email', 'position'];

  constructor(
    injector: Injector,
    private store: Store,
    private CMSUserService: CmsUsersService,
    private localStorage: LocalStorageService
  ) {
    super(injector);
    this.requestBody.from_dropdown = true;

    if (this.userService.user?.brand_id) {
      this.requestBody.add_filters = ['brand_id'];
      this.requestBody.brand_id = this.userService.user.brand_id;
    }

    this.getPositionList()
      .pipe(
        map((response) => {
          return response.map((val: any) => {
            const word = val.replace(/_/g, ' ');
            return {
              name: HelperService.capitalizeTheFirstLetterOfEachWord(word),
              key: val,
              value: val,
            };
          });
        }),
        tap((response) => {
          this.positions = orderBy(response, ['value'], ['asc']);
          console.log('positions: ', this.positions);
        })
      )
      .subscribe(() => {
        this.setTableConfigurationByLanguage();
      });

    this.filterSearchText
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((value) => {
        this.onSearchInputChanged(value.elem, value.value, value.filter);
      });

    this.store
      .select(selectCMSUsersLoading)
      .subscribe((resp) => (this.loading = resp));

    this.store
      .select(selectAllCMSUsers)
      .subscribe(
        (resource: Partial<ApiResponseBodyInterface | null | undefined>) => {
          if (resource?.items && Array.isArray(resource.items)) {
            this.tableDataListItems = [];
            this.tableDataListItems = resource?.items?.map((item) => ({
              ...item,
              loading: false,
            }));
          }

          const totalPages = Math.ceil(
            Number(resource?.total_elements) / Number(resource?.size)
          );
          this.lastPage = totalPages;
          this.totalNumberOfPages = totalPages;
          this.totalNumberOfRecords = Number(resource?.total_elements);
          this.hasMore = 0 <= totalPages - this.page;
        }
      );
    this.setTableConfigurationByLanguage();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.queryParams = params;

      if (params && params?.position) {
        this.position = params.position;
        this.requestBody.position =
          params.page == 2 ? [params.position] : params.position;
      }

      this.initData();
      this.setTableConfigurationByLanguage();
    });
  }

  setTableConfigurationByLanguage(): void {
    this.tableColumns = [
      {
        key: 'first_name',
        value: 'First Name',
        type: 'hyper_link',
        searchable: true,
        filter: 'firstName',
      },
      {
        key: 'last_name',
        value: 'Last Name',
        type: 'text',
        searchable: true,
        filter: 'lastName',
      },
      {
        key: 'email',
        value: 'Email',
        type: 'text',
        searchable: true,
        filter: 'email',
      },
      {
        key: 'position',
        value: 'Role',
        type: 'toggle',
        class: 'logo-min-width',
        disabled: false,
        searchable: true,
        filter: 'position',
        map: {},
        options: [...this.positions],
        optionValue: 'all',
        callback: (position: any) => {
          const word = position.replace(/_/g, ' ');
          return HelperService.capitalizeTheFirstLetterOfEachWord(word);
        },
      },
      {
        key: 'active',
        value: 'Status',
        type: 'toggle',
        class: 'logo-min-width',
        disabled: false,
        searchable: true,
        filter: 'active',
        map: {
          true: 'Active',
          false: 'Inactive',
        },
        options: [
          {
            value: true,
            name: 'Active',
          },
          {
            value: false,
            name: 'Inactive',
          },
        ],
      },
    ];

    this.remappedURLParamsForTableColumns();
  }

  onCheckboxChanged(event: any): void {
    const body = { active: event.checked };
    const params = null;

    this.dataService
      .fetchData({
        apiUrl: this.apiList.USER_STATUS + event.item.id + '/active',
        method: 'PUT',
        contentType: 'application/json',
        params,
        body,
      })
      .pipe(
        map((response) => {
          if (response.status == 'success') {
            this.toastrService.success(
              response.data.first_name +
                ' ' +
                (event.checked == true ? 'is now enabled' : 'is now disabled')
            );
            return response && response.data ? response.data : null;
          } else {
            this.toastrService.error(response.message, response.error);
          }
        }),
        finalize(() => {})
      )
      .subscribe(() => {});
  }

  onRowClicked(event: { id: number }): void {
    this.router
      .navigate([event.id], { relativeTo: this.route.parent })
      .then(() => null)
      .catch(() => null);
  }

  getPositionList() {
    return this.dataService.fetchData({
      apiUrl: this.apiList.POSITIONS_BASE_URL,
      method: 'GET',
      contentType: 'application/json',
      params: null,
      body: null,
    });
  }

  onPageLimitChange(event: number) {
    this.pageLimit = Number(event);
    this.page = DEFAULT_PAGE_INDEX;

    this.initData();
  }

  onPageChange(ev: number) {
    this.page = Number(ev);

    this.initData();
  }

  onSearchInputChanged(
    elem: { key: string; value: string; optionValue: string },
    value: string,
    filter?: string
  ): void {
    let new_filter: any = null;
    this.selected = elem.optionValue;

    new_filter = elem.key === 'position' ? 'position' : filter;

    if (elem.key === 'position') {
      new_filter = 'position';
    } else if (elem.key === 'active') {
      new_filter = 'active';
    } else {
      new_filter = filter;
    }

    Object.assign(this.tableSearching, { [new_filter]: value });

    console.log('tableSearching: ', this.tableSearching);
    console.log('tableFilters 1: ', this.tableFilters);

    const appIndex = this.tableFilters.findIndex((i) => i.field === new_filter);
    console.log('appIndex: ', appIndex);

    if (appIndex === -1) {
      if (new_filter === 'position' || new_filter === 'active') {
        if (value === 'all') {
        } else {
          this.tableFilters.push({
            field: new_filter,
            search: value,
          });
        }
      } else {
        this.tableFilters.push({
          field: new_filter,
          search: value,
        });
      }
    } else {
      this.tableFilters[appIndex].field = new_filter;
      // this.tableFilters[appIndex].search = elem.optionValue;
      this.tableFilters[appIndex].search = value;
      this.tableFilters = this.tableFilters.filter((i) => i.search !== '');
    }

    console.log('tableFilters 2: ', this.tableFilters);

    this.page = 1;
    this.initData();
  }

  private initData() {
    if (this.tableFilters && this.tableFilters.length) {
      this.tableFilters.forEach((item, index) => {
        if (item.field == 'position') {
          if (item.search !== 'all') {
            this.requestBody.position = [item.search];
          } else {
            this.requestBody = this.tableFilters;
            this.tableSearching.position = 'all';
          }
        } else {
          this.requestBody.fields = this.tableFilters;
          this.requestBody.page = !isEmpty(item.value) ? 1 : Number(this.page);
        }
      });
    }

    this.requestBody.page = Number(this.page);
    this.requestBody.size = Number(this.pageLimit);

    this.CMSUserService.getAllCMSUsers({
      ...this.requestBody,
    });
  }

  private remappedURLParamsForTableColumns() {
    this.tableColumns = this.tableColumns.map((item) => {
      let newData: any = {
        ...item,
      };

      if (
        this.queryParams &&
        this.queryParams.firstName &&
        item.key === 'first_name'
      ) {
        newData = {
          ...newData,
          optionValue: this.queryParams.firstName,
        };
      }

      if (
        this.queryParams &&
        this.queryParams.lastName &&
        item.key === 'last_name'
      ) {
        newData = {
          ...newData,
          optionValue: this.queryParams.lastName,
        };
      }

      if (this.queryParams && this.queryParams.email && item.key === 'email') {
        newData = {
          ...newData,
          optionValue: this.queryParams.email,
        };
      }

      if (
        this.queryParams &&
        this.queryParams?.position &&
        item.key === 'position'
      ) {
        newData = {
          ...newData,
          optionValue: this.queryParams.position,
        };
      }

      return newData;
    });
  }
}
