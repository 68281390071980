import { Component, Injector, OnInit } from '@angular/core';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

import { FormInterface } from '../../../shared/interfaces/form.interface';
import { FormBaseComponent } from '../../../shared/components/base/form-base.component';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent
  extends FormBaseComponent
  implements OnInit, FormInterface
{
  public newPasswordString = 'new_password';
  public confirmPasswordString = 'confirm_password';
  private user: any = null;
  public formSubmitted: boolean = false;
  public isLoading: boolean = false;
  hide = false;
  confirmHide = false;

  /*
   * The following used to hold the token & email from url
   * */
  private token: any = null;
  private email: any = null;

  constructor(injector: Injector) {
    super(injector);
    this.user = this.userService.user;
    // if logged in simple redirect to the dashboard
    if (this.userService.isLoggedIn()) {
      this.handleRedirection();
    }
    // Set the default language
    this.translate.setDefaultLang('en');
    // Use a language
    this.translate.use('en');
    this.route.queryParams.subscribe((params) => {
      if (params.token) {
        this.token = params.token;
      }
      if (params.email) {
        this.email = params.email;
      }
      if (!this.token || !this.email) {
        this.goToWithoutConfirmation('/' + this.routeList.RESET_PASSWORD);
      }
    });
  }

  // TODO: It should be generic and moved to framework after testing
  static passwordsDoNotMatchValidator(control: AbstractControl): any {
    if (!control.parent || !control) {
      return;
    }

    const password = control.parent.get('new_password');
    const confirmPassword = control.parent.get('confirm_password');
    if (!password || !confirmPassword) {
      return;
    }

    if (confirmPassword.value === '') {
      return;
    }

    if (password.value !== confirmPassword.value) {
      return {
        passwordsDoNotMatch: true,
      };
    }
  }

  ngOnInit(): void {
    this.addFormValidations();
  }

  onSubmit(): void {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.formSubmitted = true;
    if (this.formGroup.status === this.constantList.VALID_FORM_STATE) {
      this.userService
        .confirmPassword({
          email: atob(this.email),
          reset_token: this.token,
          password: this.formGroup.value.confirm_password,
        })
        .then((result) => {
          this.sharedDataService.changeFormSubmitStatus(false);
          if (result) {
            if (result.message) {
              this.toastrService.success(result.message);
              setTimeout(() => {
                this.router.navigateByUrl(this.routeList.LOGIN);
              }, 2000);
            }
          }
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
  }

  /**
   * The following method is used
   * @returns {string}
   * @param formElementName
   */
  getErrorMessage(formElementName: string): string {
    const formElement = this.formGroup.get(formElementName);

    // check if any error exists or not
    if (formElement.errors) {
      if (formElement.errors.required) {
        // detect the required validation being passed on the respective form control i.e. new password in this case
        if (formElementName === this.newPasswordString) {
          return 'Please enter your new password';
        }
        // detect the required validation being passed on the respective form control i.e. confirm password in this case
        if (formElementName === this.confirmPasswordString) {
          return 'Please enter confirm password';
        }
      }
      // detect the minLength validation being passed on the respective form control i.e. password in this case
      if (formElement.errors.minlength) {
        return (
          'Must have min. length of ' +
          formElement.errors.minlength.requiredLength
        );
      }
      if (
        formElementName === this.confirmPasswordString &&
        formElement.errors.passwordsDoNotMatch
      ) {
        return `Password doesn't match`;
      }
    }
    return '';
  }

  addFormValidations(): void {
    const passwordValidations: ValidatorFn[] = [
      Validators.required,
      Validators.minLength(this.constantList.PASSWORD_MIN_LENGTH),
    ];
    // TODO: Use RxJS merge method to merge below with above arrays
    const confirmPasswordValidations: ValidatorFn[] = [
      Validators.required,
      Validators.minLength(this.constantList.PASSWORD_MIN_LENGTH),
      ResetPasswordComponent.passwordsDoNotMatchValidator,
    ];

    this.addFormControlWithValidations(
      this.newPasswordString,
      passwordValidations
    );
    this.addFormControlWithValidations(
      this.confirmPasswordString,
      confirmPasswordValidations
    );
  }

  handleRedirection(reload: boolean = false): void {
    if (!reload) {
      this.sharedDataService.changeFormSubmitStatus(false);
    }
    reload
      ? window.location.replace(this.routeList.DASHBOARD)
      : this.goToWithoutConfirmation(this.routeList.DASHBOARD);
  }
}
