import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppConfig {
  config = {
    name: 'Purpl',
    title: 'Purpl',
    version: '1.0.0',
    apiUrl: {
      backendUrl: environment.backendUrl,
    },
  };

  constructor() {
    this.config.apiUrl.backendUrl = environment.backendUrl;
  }

  getConfig(): any {
    return this.config;
  }
}
