import { createFeatureSelector, createSelector } from '@ngrx/store';
import { createSlice } from '@reduxjs/toolkit';
import { ApiResponseBodyInterface } from '../../interfaces/api-response.interface';

export interface AppUsersFeedbacksState {
  appUsersFeedbacks: ApiResponseBodyInterface | null;
  loadingAppUsersFeedbacks: boolean;
}

const initialState: AppUsersFeedbacksState = {
  appUsersFeedbacks: null,
  loadingAppUsersFeedbacks: false,
};

const { actions, reducer, name } = createSlice({
  name: 'appUsersFeedbacks',
  initialState,
  reducers: {
    getAllAppUsersFeedbacksPending: (state) => {
      state.loadingAppUsersFeedbacks = true;
    },
    getAllAppUsersFeedbacksFullfilled: (state, { payload }) => {
      state.loadingAppUsersFeedbacks = false;
      state.appUsersFeedbacks = payload;
    },
    getAllAppUsersFeedbacksRejected: (state, { payload }) => {
      state.loadingAppUsersFeedbacks = true;
    },
  },
});

const selectAppUsersFeedbacksFeature =
  createFeatureSelector<ReturnType<typeof reducer>>(name);

export const selectAppUsersFeedbacksLoading = createSelector(
  selectAppUsersFeedbacksFeature,
  (state) => state.loadingAppUsersFeedbacks
);

export const selectAllAppUsersFeedbacks = createSelector(
  selectAppUsersFeedbacksFeature,
  (state) => state.appUsersFeedbacks
);

export { name };
export const {
  getAllAppUsersFeedbacksPending,
  getAllAppUsersFeedbacksFullfilled,
  getAllAppUsersFeedbacksRejected,
} = actions;
export default reducer;
