import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';
import { Observable, forkJoin } from 'rxjs';
import { head } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private httpClient: HttpClient) {}

  fetchData({
    apiUrl,
    method = 'POST',
    contentType = 'application/json',
    params = new HttpParams(),
    body = null,
    responseType = null,
    isRawResponse = false,
    observe = 'body',
  }: {
    apiUrl: string;
    method?: string;
    contentType?: string;
    params?: HttpParams | null;
    body?: any;
    accept?: any;
    responseType?: any;
    isRawResponse?: boolean;
    observe?: 'body' | 'response' | undefined;
  }): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    if (contentType) {
      headers = headers.append('Content-Type', contentType);
    }

    const options:
      | {
          headers?: HttpHeaders;
          params?: HttpParams | null;
          body?: any;
          responseType?: 'json';
        }
      | any = { headers, params, body, responseType };

    const httpClient = this.httpClient.request(method, apiUrl, {
      ...options,
      observe,
    });
    const httpResponseMapping = (response: any) => {
      if (response && response.status) {
        if (
          response.status === 'success' ||
          response.status === 'OK' ||
          response.status === 'deleted'
        ) {
          return {
            data: response.body,
            message: response.message,
            status: response.status || null,
          };
        } else if (response.status === 'error') {
          return {
            errors: response.body.validation_errors,
            message: response.message,
            status: response.status || null,
          };
        } else if (response.status === 'failed') {
          return {
            errors: response.validation_errors,
            message: response.message,
            status: response.status || null,
          };
        }
      }
      return response?.data ? response?.data : response;
    };

    return isRawResponse
      ? httpClient
      : httpClient.pipe(map(httpResponseMapping), shareReplay());
  }

  getImage(apiURL: string, imageUrl?: string): Observable<any> {
    return this.httpClient.post(
      apiURL,
      { url: imageUrl },
      { observe: 'response', responseType: 'blob' }
    );
  }

  getImageV2(apiURL: string): Observable<any> {
    return this.httpClient.get(apiURL, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  public getForkedJoinDataListing(
    receviedTransactions: any,
    cashedOutTransactions: any
  ) {
    const received = this.httpClient.get(receviedTransactions.apiURL);
    const cashedOut = this.httpClient.get(cashedOutTransactions.apiURL);
    return forkJoin([received, cashedOut]);
  }

  uploadFile(method = 'POST', url: string, formData: any): Observable<any> {
    const req = new HttpRequest(method, url, formData);
    return this.httpClient.request(req).pipe(
      map((response: any) => {
        if (response.status === 200 && response.body) {
          return response.body;
        } else {
          response.body;
        }
      })
    );
  }

  downloadFile(
    method = 'GET',
    url: string,
    contentType: string,
    filename: string
  ): void {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers
      .append('Content-Type', contentType)
      .append('Accept', '*/*');

    const req = new HttpRequest(method, url, null, {
      responseType: 'blob',
      headers,
    });

    this.httpClient.request(req).subscribe((response: any) => {
      if (response.body) {
        const url = window.URL.createObjectURL(response.body);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
      }
    });
  }

  exportFile(
    method = 'GET',
    url: string,
    contentType: string,
    filename: string
  ): void {
    let headers: HttpHeaders = new HttpHeaders();

    headers = headers
      .append('Content-Type', contentType)
      .append('Accept', 'text/csv');

    console.log('headers: ', headers);

    const req = new HttpRequest(method, url, null, {
      responseType: 'blob',
      headers,
    });

    this.httpClient.request(req).subscribe((response: any) => {
      if (response.body) {
        const url = window.URL.createObjectURL(response.body);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
      }
    });
  }
}
