import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectCountdownTimer, setShowPrompt } from '../../stores/slices/App';
import {
  LocalStorageService,
  SessionStorageService,
  SharedDataService,
  UserService,
} from '../../services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as ROUTE_LIST from '../../constants/routes-list';
import { GLOBAL_TIMEOUT_TIMER } from '../../constants/constant-list';
import { UserIdleService } from 'angular-user-idle';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-idle-warning-dialog',
  templateUrl: './idle-warning-dialog.component.html',
  styleUrls: ['./idle-warning-dialog.component.scss'],
})
export class IdleWarningDialogComponent implements OnInit {
  countdown: number | null = null;
  timedOut = false;
  protected routeList = ROUTE_LIST;

  constructor(
    private store: Store,
    public userService: UserService,
    private sessionService: SessionStorageService,
    private localService: LocalStorageService,
    private sharedDataService: SharedDataService,
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private router: Router,
    private userIdle: UserIdleService,
    public dialogRef: MatDialogRef<IdleWarningDialogComponent>
  ) {
    this.store.select(selectCountdownTimer).subscribe((count) => {
      if (count == null) {
        this.countdown = 0;
        return;
      }

      this.countdown = GLOBAL_TIMEOUT_TIMER - count;
    });
  }

  ngOnInit(): void {}

  reset() {
    this.timedOut = false;
    this.userIdle.resetTimer();
  }

  public stayLoggedIn(): void {
    this.reset();
    this.store.dispatch(setShowPrompt(false));
    this.modalService.dismissAll();
    this.dialogRef.close();
  }

  public handleLogout(): void {
    this.modalService.dismissAll(null);

    this.toastrService.clear();
    this.store.dispatch(setShowPrompt(false));
    this.sharedDataService.showLoadingBar(false);
    this.sharedDataService.changeFormSubmitStatus(false);
    this.sessionService.cleatDataInSessionStorage();
    this.localService.clearDataInLocalStorage();
    this.router
      .navigateByUrl(this.routeList.LOGIN)
      .then(() => window.location.reload());
  }
}
