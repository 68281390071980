import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2SmartTableModule } from 'ng2-smart-table';

import { SharedModule } from '../../shared/shared.module';
import { UsersRoutingModule } from './users-routing.module';

import { UserListComponent } from './user-list/user-list.component';
import { CommonModule } from '@angular/common';
import { UserFormComponent } from './user-form/user-form.component';
import { UsersResolverService } from './users-resolver.service.ts';
import { AppDashboardTitleModule } from 'src/app/shared/components/app-dashboard-title/app-dashboard-title.module';
import { PurplTableModule } from 'src/app/shared/components/purpl-table/purpl-table.module';
import { BreadcrumbModule } from 'src/app/shared/components/breadcrumb/breadcrumb.module';
import { AllUsersComponent } from './all-users/all-users.component';
import { PageLimiterModule } from 'src/app/shared/components/page-limiter/page-limiter.module';
import { MatSelectModule } from '@angular/material/select';
@NgModule({
  declarations: [UserListComponent, UserFormComponent, AllUsersComponent],
  imports: [
    NgbModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    SharedModule,
    UsersRoutingModule,
    CommonModule,
    AppDashboardTitleModule,
    PurplTableModule,
    BreadcrumbModule,
    PageLimiterModule,
    FormsModule,
    MatSelectModule,
  ],
  providers: [UsersResolverService],
})
export class UsersModule {}
