<div class="page-wrapper backgrund-pic">
  <div class="authentication-box">
    <div class="container">
      <div class="row">
        <div class="col-md-5 p-0 card-left">
          <mat-card class="bg-primary card-setting">
            <img
              class="brand-logo blur-up lazyloaded brand-zoom"
              src="assets/images/dashboard/purpl-logo.png"
              alt=""
            />
          </mat-card>
        </div>
        <div class="col-md-7 p-0 card-right">
          <mat-card class="tab2-card">
            <mat-card-content class="body-height">
              <div class="header-text">Update Password</div>
              <div class="sub-header-text">
                You need to update your password because this is the first time
                you are signing in, or because your password has expired.
              </div>
              <form
                [formGroup]="formGroup"
                class="form-horizontal auth-form"
                novalidate
                (ngSubmit)="onSubmit()"
                (keyup.enter)="formGroup.valid ? onSubmit() : null"
              >
                <div class="form-group">
                  <div class="input-group has-validation">
                    <input
                      required
                      name="password"
                      [type]="hideNew ? 'text' : 'password'"
                      class="form-control"
                      placeholder="New Password"
                      formControlName="password"
                      [ngClass]="{
                        'is-invalid': checkControlValidity(
                          'password',
                          'invalid',
                          formGroup,
                          formSubmitted
                        )
                      }"
                    />
                    <div class="input-group-append">
                      <span
                        class="input-group-text"
                        (click)="hideNew = !hideNew"
                        [title]="
                          hideNew ? 'Hide the password' : 'Show the password'
                        "
                      >
                        <img
                          [src]="
                            hideNew
                              ? '/assets/images/svg/eye_hide.svg'
                              : '/assets/images/svg/eye_visible.svg'
                          "
                          alt="password-field"
                        />
                      </span>
                    </div>
                    <ng-container
                      *ngIf="
                        checkControlValidity(
                          'password',
                          'invalid',
                          formGroup,
                          formSubmitted
                        )
                      "
                    >
                      <div
                        class="ml-2 invalid-feedback"
                        *ngFor="let msg of getErrorMessage('password')"
                      >
                        {{ msg }}
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="form-button">
                  <button
                    class="btn btn-primary"
                    type="submit"
                    [disabled]="
                      (sharedDataService.loadingBarStatus | async) ||
                      formGroup.invalid
                    "
                  >
                    <span *ngIf="!isLoading">Update Password</span>
                    <i
                      class="fa fa-spin fa-spinner"
                      aria-hidden="true"
                      *ngIf="isLoading"
                    ></i>
                  </button>
                </div>
              </form>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
