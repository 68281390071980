import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  @Input() placeholder?: string;
  @Input() value?: string;
  @Output() onSearch: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('input') input?: ElementRef;

  constructor() {}

  ngOnInit(): void {}

  onSearchKeyPress(): void {
    this.onSearch.emit(this.input?.nativeElement.value);
  }
}
