import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as CONST_LIST from '../constants/constant-list';
import * as ROUTE_LIST from '../constants/routes-list';
import * as API_LIST from '../constants/apis-list';

import { UserService, SharedDataService, PermissionService, DataListingService } from '../services';

/*
 * Base Resolver
 * Top Level Resolver
 */
@Injectable()
export class PurplBaseResolver {
  protected route: ActivatedRoute;
  protected router: Router;

  public constantList = CONST_LIST;
  public routeList = ROUTE_LIST;
  public apiList = API_LIST;
  public userService: UserService;
  public permissionService: PermissionService;
  public sharedDataService: SharedDataService;
  public dataListingService: DataListingService;

  constructor(injector: Injector) {
    this.route = injector.get(ActivatedRoute);
    this.router = injector.get(Router);
    this.userService = injector.get(UserService);
    this.permissionService = injector.get(PermissionService);
    this.sharedDataService = injector.get(SharedDataService);
    this.dataListingService = injector.get(DataListingService);
  }

  public redirectToListPage(url?: string): void {
    this.router.navigate([url || this.routeList.DASHBOARD]).then().catch();
  }
}
