import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-purpl-popup-avatar',
  templateUrl: './purpl-popup-avatar.component.html',
  styleUrls: ['./purpl-popup-avatar.component.scss'],
})
export class PurplPopupAvatarComponent implements OnInit {
  @Input() avatarSrc?: string;
  @Input() status_id?: number | string;
  @Input() compliance: any;
  @Input() did_jumio_and_failed?: boolean;
  @Input() bounced_jumio?: boolean;
  @Input() popContent?: TemplateRef<any>;
  @Input() data?: any;

  @Output() onPress: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}
}
