import { createFeatureSelector, createSelector } from '@ngrx/store';
import { createSlice } from '@reduxjs/toolkit';
import { ApiResponseBodyInterface } from '../../interfaces/api-response.interface';

export interface MerchantsState {
  merchants: ApiResponseBodyInterface | null;
  loadingMerchants: boolean;
}

const initialState: MerchantsState = {
  merchants: null,
  loadingMerchants: false,
};

const { actions, reducer, name } = createSlice({
  name: 'merchants',
  initialState,
  reducers: {
    getAllMerchantsPending: (state) => {
      state.loadingMerchants = true;
    },
    getAllMerchantsFullfilled: (state, { payload }) => {
      state.loadingMerchants = false;
      state.merchants = payload;
    },
    getAllMerchantsRejected: (state, { payload }) => {
      state.loadingMerchants = true;
    },
  },
});

const selectMerchantsFeature =
  createFeatureSelector<ReturnType<typeof reducer>>(name);

export const selectMerchantsLoading = createSelector(
  selectMerchantsFeature,
  (state) => state.loadingMerchants
);

export const selectAllMerchants = createSelector(
  selectMerchantsFeature,
  (state) => state.merchants
);

export { name };
export const {
  getAllMerchantsPending,
  getAllMerchantsFullfilled,
  getAllMerchantsRejected,
} = actions;
export default reducer;
