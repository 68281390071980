import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-purpl-table-hyperlink',
  templateUrl: './purpl-table-hyperlink.component.html',
  styleUrls: ['./purpl-table-hyperlink.component.scss'],
})
export class PurplTableHyperlinkComponent implements OnInit {
  @Input() label?: string;

  @Output() onPress: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}
}
