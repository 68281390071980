<app-breadcrumb>
  <div class="btn-popup pull-right">
    <a
      class="btn btn-primary text-capitalize"
      [routerLink]="'/users/cms-users/add'"
      *ngIf="
        checkAccessModule(
          FEATURES.USERS_CMS_USERS_LISTING.name,
          PERMISSION.WRITE
        ) &&
        checkAccessModule(FEATURES.USERS_CMS_USERS_ADD.name, PERMISSION.WRITE)
      "
      >Add New User</a
    >
  </div>
</app-breadcrumb>
<div class="container-fluid">
  <div class="card">
    <div class="card-body">
      <div class="table-heading mb-4">CMS Users</div>
      <app-all-users
        [tableDataListItems]="tableDataListItems"
        [hasMore]="hasMore"
        [collectionSize]="totalNumberOfRecords"
        [tableColumns]="tableColumns"
        (clickedOnLink)="onRowClicked($event)"
        (checkboxCheckedUpdated)="onCheckboxChanged($event)"
        [pageLimit]="pageLimit"
        [page]="page"
        (onPageChanged)="onPageChange($event)"
        (onPageLimitChanged)="onPageLimitChange($event)"
        (onFilterSearchText)="
          filterSearchText.next({
            elem: $event.elem,
            value: $event.value,
            filter: $event.filter
          })
        "
      ></app-all-users>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
