<div class="page-wrapper backgrund-pic">
  <div class="authentication-box">
    <div class="container">
      <div class="row">
        <div class="col-md-5 p-0 card-left">
          <mat-card class="bg-primary card-setting">
            <img
              class="brand-logo blur-up lazyloaded brand-zoom"
              src="assets/images/dashboard/purpl-logo.png"
              alt=""
            />
          </mat-card>
        </div>
        <div class="col-md-7 p-0 card-right">
          <mat-card class="tab2-card">
            <mat-card-content class="body-height">
              <div class="header-text">Verify</div>
              <div class="sub-header-text">
                A 6 digit OTP was just sent to {{ email }} to verify it’s really
                you trying to sign in
              </div>
              <div class="code-input-container">
                <code-input
                  [codeLength]="6"
                  (codeChanged)="onCodeChanged($event)"
                  (codeCompleted)="onCodeCompleted($event)"
                >
                </code-input>
              </div>
              <div class="form-terms">
                <a
                  href="javascript:void(0)"
                  class="forgot-pass"
                  (click)="resenOTP()"
                >
                  <span *ngIf="!isResending">Resend OTP</span>
                  <i
                    class="fa fa-spin fa-spinner fa-lg"
                    aria-hidden="true"
                    *ngIf="isResending"
                  ></i>
                </a>
              </div>
              <div class="form-button">
                <button
                  class="btn btn-primary"
                  (click)="validateOTP()"
                  [disabled]="!formValid || isLoading"
                >
                  <span *ngIf="!isLoading">Verify</span>
                  <i
                    class="fa fa-spin fa-spinner"
                    aria-hidden="true"
                    *ngIf="isLoading"
                  ></i>
                </button>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
