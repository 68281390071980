<div class="example-wrapper textStyle">
  <ag-grid-angular
    style="width: 100%; height: 100%"
    class="ag-theme-material"
    [columnDefs]="tableColumns"
    domLayout="autoHeight"
    rowHeight="67"
    rowSelection="single"
    pagination="true"
    [suppressPaginationPanel]="true"
    [paginationPageSize]="pageLimit"
    [rowData]="tableDataListItems"
    (gridReady)="onGridReady($event)"
  >
  </ag-grid-angular>
  <div class="d-flex justify-content-between m-t-10 w-100">
    <app-page-limiter
      (onPageLimitChanged)="onPageLimitChanged.emit($event)"
      [pageLimit]="pageLimit"
    ></app-page-limiter>
    <ngb-pagination
      *ngIf="tableDataListItems?.length > 0"
      [collectionSize]="totalElements"
      [(page)]="page"
      [pageSize]="pageLimit"
      [boundaryLinks]="true"
      [disabled]="!hasMore"
      [ellipses]="true"
      (pageChange)="onPageChanged.emit($event)"
      [maxSize]="10"
    >
    </ngb-pagination>
  </div>
</div>
