import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import {
  Component,
  Injector,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import _, { isEmpty, map, uniqBy } from 'lodash';
import { UserProfileTransactionDetailsComponent } from 'src/app/pages/app-users/app-user-profile/components/user-profile-transaction-details/user-profile-transaction-details.component';
import { ListingBaseComponent } from 'src/app/shared/components/base/listing-base.component';
import { USER_TRANSACTIONS } from 'src/app/shared/constants/apis-list';
import {
  APP_USER_PAYMENT_FILTERS,
  APP_USER_STATUS,
  DEFAULT_PAGE_INDEX,
  NUMBER_RECORDS_PER_PAGE,
} from 'src/app/shared/constants/constant-list';
import { selectUserTransactionsLoading } from 'src/app/shared/stores/slices/AppUsers';

interface TransactionElement {
  type: string;
  remittance: string;
  status: string;
  transaction_object_id: number;
  amount: number;
  createdOn: string;
  updatedOn: string;
}

@Component({
  selector: 'app-beneficiary-profile',
  templateUrl: './beneficiary-profile.component.html',
  styleUrls: ['./beneficiary-profile.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class BeneficiaryProfileComponent
  extends ListingBaseComponent
  implements OnInit, OnChanges
{
  @Input() details?: any;

  params: HttpParams = new HttpParams();

  loading = false;
  disableInfiniteScroll = true;
  isDisabled = true;
  documentFocused = false;
  clearFilterValue: any;
  selectedStatus: number | null = null;
  transactions: any[] = [];

  tableColumns: any[] = [];
  users: any[] = [];
  balance: any;

  pageLimit: number = NUMBER_RECORDS_PER_PAGE;
  page: number = DEFAULT_PAGE_INDEX;

  appUserStatus: {
    id: number;
    statusText: string;
    condition: string;
    value: any;
  }[] = APP_USER_STATUS;
  lastPage = 0;
  totalNumberOfPages = 0;
  totalNumberOfRecords = 0;
  hasMore = false;

  sortFieldDesc = true;

  columnsToDisplay = [
    'type',
    'remittance',
    'status',
    'transaction_object_id',
    'amount',
    'updatedOn',
    'createdOn',
  ];

  innerDisplayedColumns = ['status', 'updatedAt'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  dataSource = new MatTableDataSource<TransactionElement>();
  expandedElement: TransactionElement | null = null;

  tableFilters: any[] = [];

  tableDef: Array<any> = [
    {
      key: 'type',
      header: 'Transaction type',
      sorting: true,
      sortColumn: 'type',
      searchable: true,
      filter: 'type',
      options: [
        { value: 'REMITTANCE', type: 'Remittance' },
        { value: 'CASH_OUT', type: 'Cash Out' },
        { value: 'PAYMENT', type: 'Payment' },
      ],
    },
    {
      key: 'remittance',
      header: 'Provider',
      sorting: false,
      sortColumn: 'remittance',
      searchable: false,
    },
    {
      key: 'status',
      header: 'Status',
      sorting: true,
      sortColumn: 'transaction_status',
      searchable: true,
      filter: 'transaction_status',
      options: [
        { value: 'Completed', transaction_status: 'Completed' },
        { value: 'Received', transaction_status: 'Received' },
        { value: 'Cancelled', transaction_status: 'Cancelled' },
        { value: 'Pending', transaction_status: 'Pending' },
        { value: 'Expired', transaction_status: 'Expired' },
        { value: 'Cashed Out', transaction_status: 'Cashed Out' },
        { value: 'Accepted', transaction_status: 'Accepted' },
        { value: 'Returned', transaction_status: 'Returned' },
        { value: 'Rejected', transaction_status: 'Rejected' },
        { value: 'Failed', transaction_status: 'Failed' },
      ],
      // { value: 'Moved to wallet', transaction_status: 'Moved to wallet' },
    },
    {
      key: 'transaction_object_id',
      header: 'ID',
      sorting: false,
      sortColumn: 'transaction_object_id',
      searchable: false,
    },
    {
      key: 'amount',
      header: 'Amount',
      sorting: true,
      sortColumn: 'decimal_amount',
      searchable: false,
    },
    {
      key: 'updatedOn',
      header: 'Updated on',
      sorting: true,
      sortColumn: 'datetime_updated',
      searchable: false,
    },
    {
      key: 'createdOn',
      header: 'Created on',
      sorting: true,
      sortColumn: 'datetime_created',
      searchable: false,
    },
  ];

  constructor(
    injector: Injector,
    private dialog: MatDialog,
    private store: Store,
    private datePipe: DatePipe
  ) {
    super(injector);

    this.store
      .select(selectUserTransactionsLoading)
      .subscribe({ next: (resp) => (this.loading = resp) });
  }

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      if (data && data.data) {
        this.details = data.data;

        this.initData();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
  }

  onViewTransaction(details: any, $event: any) {
    $event.preventDefault();
    $event.stopPropagation();
    this.dialog.open(UserProfileTransactionDetailsComponent, {
      data: {
        details,
      },
      width: '600px',
      panelClass: 'custom',
    });
  }

  getAllUserTransactions(user_code: string) {
    this.dataService
      .fetchData({
        apiUrl: USER_TRANSACTIONS.replace('{userCode}', user_code),
        method: 'GET',
        params: this.params,
      })
      .subscribe({
        next: (response) => {
          if (response && Array.isArray(response.results)) {
            this.users = [];
            this.users = response?.results.map((item: any) => {
              const item_history = item?.transaction_object?.status_history;

              const status_history = map(item_history, (itm) => ({
                ...itm,
              })).slice(0, -1);

              return {
                ...item,
                sender_name: item?.transaction_object?.sender_name
                  ? item?.transaction_object?.sender_name
                  : 'N/A',
                remittance:
                  item?.type === 'TRANSFER'
                    ? item?.transaction_object?.remittance?.platform_name
                    : item?.transaction_object?.remittance_platform_name,
                status_history,
                updatedOn: this.datePipe.transform(
                  item?.datetime_updated,
                  'medium'
                ),
                createdOn: this.datePipe.transform(
                  item?.datetime_created,
                  'medium'
                ),
                loading: false,
              };
            });

            this.users = [...this.users];

            this.dataSource = new MatTableDataSource(this.users);

            const totalPages = Math.ceil(
              Number(response.count) / Number(this.pageLimit)
            );

            this.lastPage = totalPages;
            this.totalNumberOfPages = totalPages;
            this.totalNumberOfRecords = Number(response.count);
            this.hasMore = 0 <= totalPages - this.page;
          }
        },
      });
  }

  private initData() {
    if (this.tableFilters && this.tableFilters.length) {
      this.tableFilters = uniqBy(this.tableFilters, 'field');

      this.tableFilters.forEach((item, index) => {
        Object.entries(item).forEach(([key, value]) => {
          if (typeof value !== 'undefined') {
            if (this.clearFilterValue === 1) {
              this.params = this.params.set('ordering', '');
              this.clearFilterValue = 0;
            } else {
              if (item?.ordering) {
                this.params = this.params.set('ordering', String(value));
              } else {
                if (value === 'all') {
                  this.params = this.params.set(
                    item?.order
                      ? String(`ordering[${index}].${key}`)
                      : String(item?.field),
                    ''
                  );
                } else {
                  this.params = this.params.set(
                    item?.order
                      ? String(`ordering[${index}].${key}`)
                      : String(item?.field),
                    String(value)
                  );
                }
              }
            }
          }
        });
      });
    }

    if (this.page > 1) {
      const OFFSET = 10 * this.page - 10;
      this.params = this.params.set('limit', this.pageLimit);
      this.params = this.params.set('offset', OFFSET);
    } else {
      this.params = this.params.set('limit', this.pageLimit);
      this.params = this.params.delete('offset');
    }

    if (!!this.details?.compliance?.app_user_uuid) {
      this.getAllUserTransactions(this.details?.compliance?.app_user_uuid);
    }
  }

  onPageLimitChanged(event: number) {
    this.pageLimit = Number(event);
    this.page = DEFAULT_PAGE_INDEX;

    this.initData();
  }

  onPageChanged(event: number) {
    this.page = Number(event);

    this.initData();
  }

  onSortChanged(sortColumn: string) {
    this.sortFieldDesc = !this.sortFieldDesc;
    const prefix = this.sortFieldDesc === true ? '-' : '';
    this.tableFilters = [
      {
        ordering: prefix + sortColumn,
      },
    ];

    this.initData();
  }

  onSearchInputChanged(elem: any, value?: string): void {
    let new_filter: any = null;

    new_filter = elem.filter;

    Object.assign(this.tableDef, { [new_filter]: value?.toLocaleLowerCase });

    const appIndex = this.tableFilters.findIndex((i) => i.field === new_filter);
    if (appIndex === -1) {
      this.tableFilters.push({
        field: new_filter,
        search: value,
      });
    } else {
      this.tableFilters[appIndex].field = new_filter;
      this.tableFilters[appIndex].search = value;
    }

    this.page = 1;
    this.initData();
  }

  clearFilter() {
    this.clearFilterValue = 1;
    this.initData();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  statusApplied(status: any) {
    if (this.selectedStatus == status.id) {
      this.selectedStatus = null;
      this.requestBody.status_id = 0;

      this.requestBody.statuses =
        status.condition === '4' ? APP_USER_PAYMENT_FILTERS : [];
    } else {
      this.selectedStatus = status.id;

      if (status.condition === '4') {
        this.requestBody.statuses = APP_USER_PAYMENT_FILTERS.filter(
          (filter) => filter == status.statusText
        );
      } else {
        this.requestBody.status_id = this.appUserStatus.filter(
          (filter) => filter.id == status.id
        )[0].value;
      }
    }
    this.transactions = [];
    this.disableInfiniteScroll = true;
    this.requestBody.page_no = 0;
    this.loadTransactions();
  }

  loadTransactions() {
    this.loading = true;
    this.dataService
      .fetchData({
        apiUrl: this.apiList.TRANSACTION_BASE_URL,
        method: 'POST',
        contentType: 'application/json',
        params: null,
        body: this.requestBody,
      })
      .subscribe({
        next: (response) => {
          const response1 = response.data;

          this.loading = false;
          response1.length == 0 ? (this.disableInfiniteScroll = false) : null;
          const response2 = response1?.map((item: any) => ({
            ...item,
            payment_date: item.status_history.slice(-1)[0]?.updated_at,
            final_branch:
              item.transaction_status === 'Completed' &&
              !isEmpty(item.final_branch)
                ? item.final_branch
                : item.transaction_status !== 'Completed' &&
                  isEmpty(item.final_branch)
                ? null
                : item.branch_dto,
          }));

          if (this.transactions != null && this.transactions.length) {
            this.transactions = [...this.transactions, ...response2];
            _.orderBy(this.transactions, ['date_created'], ['desc']);
          } else {
            this.transactions = [...response2];
            _.orderBy(this.transactions, ['date_created'], ['desc']);
          }
        },
      });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
