import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ApiResponseBodyInterface } from '../../interfaces/api-response.interface';

export interface EKYCState {
  //   ekyc: ApiResponseBodyInterface | null;
  formValues: Record<string, any> | null;
}

const initialState: EKYCState = {
  formValues: null,
};

const { actions, reducer, name } = createSlice({
  name: 'ekyc',
  initialState,
  reducers: {
    setFormValues: (
      state,
      { payload }: PayloadAction<Record<string, any> | null>
    ) => {
      state.formValues = {
        ...state.formValues,
        ...payload,
      };
    },
    clearFormValues: (state) => {
      state.formValues = null;
    },
  },
})
const selectState = createFeatureSelector<ReturnType<typeof reducer>>(name);

export const selectFormValues = createSelector(
  selectState,
  (state) => state.formValues
);

export { name };
export const { setFormValues, clearFormValues } = actions;
export default reducer;
