'use strict';
import { environment } from 'src/environments/environment';
import { AppConfig } from '../../app.config';

const apiConfig = new AppConfig();

export const URL_SERVER: string = apiConfig.config.apiUrl.backendUrl;

// GENERIC Constants
export const DEFAULT_TOASTR_DURATION = 3000;
export const DEFAULT_REDIRECTION_WAIT_TIME = 3000;
export const DEFAULT_REQUEST_LONG_TIME_INTERVAL = 3000;
export const DEFAULT_DEBOUNCE_TIME = 1000;
export const DEFAULT_SCROLL_DELAY_TIME = 500;
export const DEFAULT_SCROLL_OFFSET = 1000;
export const DEFAULT_LOGO_SIZE_BYTES = 2097152;
export const DEFAULT_ZOOM_LEVEL = 16;
export const DEFAULT_IMAGE_WIDTH = 1024;
export const DEFAULT_IMAGE_HEIGHT = 768;
export const DEFAULT_IMAGE_SIZE = 2097152;
export const DEFAULT_COVER_PHOTO_ASPECT_RATIO: number = 1 / 1;
export const DEFAULT_CURRENCY_CODE = 'USD';
// export const GLOBAL_INACTIVITY_TIMER = 1800; // 180s or 30 mins
// export const GLOBAL_TIMEOUT_TIMER = 60; // 10s
export const GLOBAL_INACTIVITY_TIMER = environment.GLOBAL_INACTIVITY_TIMER; // 180s or 30 mins
export const GLOBAL_TIMEOUT_TIMER = environment.GLOBAL_TIMEOUT_TIMER; // 10s
export const GOOGLE_API_KEY = 'AIzaSyAjvUDKCZDiOh5dgCVpCOGIp9yvzSGf6P4';

export const DEFAULT_TIMEZONE = 'Asia/Beirut';

export const CACHE_URL_KEY = 'cache_url_key';

export const DEFAULT_SNACKBAR_LABEL = 'OK';
export const CLASS_DEFAULT_MSG = ['snack-bar-container'];
export const CLASS_SUCCESS_MSG = ['snackbar-success-panel'];
export const CLASS_WARNING_MSG = ['snackbar-warning-panel'];
export const DEFAULT_REMEMBER_ME_MONTHS = 2;
export const DEFAULT_SESSION_HOURS = 1;
export const DEFAULT_SORT_KEY = 'id';
export const DEFAULT_SORT_ORDER = 'desc';
export const DEFAULT_REQUEST_TOO_LONG_TEXT = `Your request is Received. Don't go away!`;
export const DEFAULT_ERROR_MESSAGE =
  'Server rejected your request. Please try again!';
export const DEFAULT_ACCESS_DENIED_CODE = 'access_denied';
export const DEFAULT_ACCESS_DENIED_MESSAGE = `You don't have permission to perform the required action.`;
export const DEFAULT_INVALID_TOKEN_SERVER_RESPONSE = 'Token not provided';
export const DEFAULT_INVALID_TOKEN_SIGNATURE_SERVER_RESPONSE =
  'Token Signature could not be verified.';
export const SEARCH = 'search';
export const POP_UP_LARGE_WIDTH = '60%';
export const PATH_IMAGE_FLAGS = '/assets/images/flags';
export const DEFAULT_USER_PLACEHOLDER = '/assets/images/user-placeholder.png';
export const DEFAULT_IMAGE_PLACEHOLDER = '/assets/images/card-placeholder.png';
export const DEFAULT_UPLOAD_ICON = '/assets/images/logos/ic-upload.png';
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_TIME_FORMAT = 'HH:mm:ss';
export const DEFAULT_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const REGEX_FILE_EXT = /(?:\.([^.]+))?$/;
export const DEFAULT_IMG_PATH = 'assets/images/';
export const DEFAULT_MAP_MARKER_ICON_PATH = `${DEFAULT_IMG_PATH}/svg/location_pin.svg`;
export const ENCRYPTION_SECRET = 'iA9iU1xE1wA4bO3xJ8eL0tW9aG1lF2vF';
export const ERROR_USER_NOT_EXIST_EN = 'No user details found';
export const ERROR_USER_ROLE_EN = 'Invalid user role';
export const REGEX_EMAIL_ADDRESS =
  /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const REGEX_EMPTY_SPACES_ALLOWED_BETWEEN_WORDS = /^\w+(\s\w+)*$/;

// Map Constant
export const DEFAULT_LATITUDE: any = 25.2854473;
export const DEFAULT_LONGITUDE: any = 51.53103979999999;

// Status Constant
export const STATUS = 'status';
export const STATUS_BOTH = '';
export const STATUS_AVAILABLE = '1';
export const STATUS_OFFLINE = '1';

// Permission Constant
export const PERMISSION_READ = 'allow_read';
export const PERMISSION_WRITE = 'allow_write';
export const PERMISSION_DELETE = 'allow_delete';
export const PERMISSION_EXPORT = 'allow_export';
export const PERMISSION_PRINT = 'allow_print';
export const PERMISSION_APPROVE = 'allow_approve';
export const PERMISSION_REJECT = 'allow_reject';

export const PERMISSION = {
  READ: 'allow_read',
  WRITE: 'allow_write',
  DELETE: 'allow_delete',
  EXPORT: 'allow_export',
  PRINT: 'allow_print',
  APPROVE: 'allow_approve',
  REJECT: 'allow_reject',
};

// LOGIN page constants
export const PASSWORD_MIN_LENGTH = 8;

// From Element constants
export const PASSWORD_MAX_LENGTH = 18;
export const CODE_LENGTH = 5;
export const PATTERN_DECIMAL: RegExp = new RegExp(/(\d+){0,15}(.\d{1,2})?$/);
export const PATTERN_PURPL_FEES: RegExp = new RegExp(/^[0-9]{1,15}$/);
export const MOBILE_NUMBER: RegExp = new RegExp(/^([+])?[0-9]{8,20}$/);
export const PHONE_NUMBER = /^[0-9]+$/gm;
export const PATTERN_ARABIC_CHAR: RegExp = new RegExp(
  /[\u0600-۾ݐ-ݾ\u08a0-\u08feﭐ-\ufdfeﹰ-\ufefe]|\ud83b[\ude00-\udefe]/g
);
export const NAME_MAX_LENGTH = 256;
export const DESCRIPTION_MAX_LENGTH = 400;
export const MOBILE_NUMBER_MIN_LENGTH = 6;
export const MAX_AUTO_SIZE_MIN_ROWS = 4;
export const MAX_AUTO_SIZE_MAX_ROWS = 5;
export const NUMBER_RECORDS_PER_PAGE = 10;
export const DEFAULT_PAGE_INDEX = 1;
export const DEFAULT_HTTP_TIME_OUT = 200000;
export const PAGE_SIZE_OPTIONS = [5, 10, 20, 50, 100];
export const SUCCESS_RESPONSE = 200;
export const SUCCESS_STATUS = 'success';
export const OK_STATUS = 'OK';
export const FAILURE_RESPONSE = '0';
export const FAILURE_STATUS = 'error';
export const VALID_FORM_STATE = 'VALID';
export const DISABLED_FORM_STATE = 'DISABLED';

// ACCOUNT Constants
export const INACTIVE_ACCOUNT = false;
export const ALL_ACCOUNT_STATUS = false;
export const ACTIVE_ACCOUNT_STATUS = true;

export const STATUSES: any[] = [
  {
    value: true,
    name: 'Enabled',
    name_ar: 'مفعّل',
  },
  {
    value: false,
    name: 'Disabled',
    name_ar: 'غير مفعّل',
  },
];

export const ARABIC_STATUSES: any[] = [
  {
    value: true,
    name: 'مفعّل',
  },
  {
    value: false,
    name: 'غير مفعّل',
  },
];

// Http Headers Constants
export const X_DEVICE_ID = '1';
export const X_DEVICE_UUID = 'browser';

export const BLF_BRAND_MANAGER = 'Banque Libano-Française';

export const ROLE_TELLER = 'teller';
export const ROLE_APP_USER = 'app_user';
export const ROLE_ADMIN = 'purpl_admin';
export const ROLE_BRAND_MANAGER = 'brand_manager';
export const ROLE_COMPLIANCE_MANAGER = 'compliance_manager_user';
export const ROLE_PARTNER = 'partner';

export const ROLES: any[] = [
  {
    name: 'Admin',
    name_ar: 'Admin',
    slug: ROLE_ADMIN,
    value: ROLE_ADMIN,
    role: ROLE_ADMIN,
  },
  {
    name: 'Brand Manager',
    name_ar: 'Brand Manager',
    slug: ROLE_BRAND_MANAGER,
    role: ROLE_BRAND_MANAGER,
    value: ROLE_BRAND_MANAGER,
  },
  {
    name: 'Teller',
    name_ar: 'Teller',
    slug: ROLE_TELLER,
    role: ROLE_TELLER,
    value: ROLE_TELLER,
  },
  {
    name: 'App User',
    name_ar: 'App User',
    slug: ROLE_APP_USER,
    role: ROLE_APP_USER,
    value: ROLE_APP_USER,
  },
];

// CKEditor5 configuration
export const CKEDITOR5_IMAGE_UPLOAD_URI = '';
export const CKEDITOR5_TOKEN_URI = '';

export const PURPL_STATUS = [
  { id: 1, value: 'Unverified' },
  { id: 2, value: 'Verified' },
  { id: 3, value: 'Blocked' },
  { id: 4, value: 'Unblocked' },
  { id: 5, value: 'Pending' },
];

export const days: any = {};
days.mon = 'Monday';
days.tue = 'Tuesday';
days.wed = 'Wednesday';
days.thu = 'Thursday';
days.fri = 'Friday';
days.sat = 'Saturday';
days.sun = 'Sunday';

export const COMPLIANCE_ALL_USER_FILTER = [
  { id: 1, value: 'Today' },
  { id: 2, value: 'Yesterday' },
  { id: 3, value: 'This Week' },
  { id: 4, value: 'MTD' },
  { id: 5, value: 'YTD' },
  { id: 6, value: 'Custom' },
];

export const COMPLIANCE_VERIFICATION_STATUS = {
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  VERIFIED: 'VERIFIED',
  VERIFIED_1: 'VERIFIED_1',
  VERIFIED_2: 'VERIFIED_2',
  FAILED_JUMIO: 'FAILED_JUMIO',
  BOUNCED_JUMIO: 'BOUNCED_JUMIO',
  PENDING_JUMIO: 'PENDING_JUMIO',
  UNVERIFIED: 'UNVERIFIED',
  BLOCKED: 'BLOCKED',
  SOFT_DELETED: 'SOFT_DELETED',
};

export const IDENTITY_VERIFICATION_STATUS = [
  {
    value: 'VERIFIED',
    display_name: 'VERIFIED',
  },
  {
    value: 'UNVERIFIED',
    display_name: 'UNVERIFIED',
  },
];

export const VERIFICATION_STATUS = [
  {
    display_name: 'FAILED JUMIO',
    value: 'FAILED_JUMIO',
  },
  {
    display_name: 'BOUNCED JUMIO',
    value: 'BOUNCED_JUMIO',
  },
  {
    display_name: 'PENDING JUMIO',
    value: 'PENDING_JUMIO',
  },
  {
    display_name: 'UNVERIFIED',
    value: 'UNVERIFIED',
  },
  {
    display_name: 'PENDING',
    value: 'PENDING',
  },
  {
    display_name: 'REJECTED',
    value: 'REJECTED',
  },
  {
    display_name: 'VERIFIED',
    value: 'VERIFIED',
  },
  {
    display_name: 'VERIFIED_1',
    value: 'VERIFIED_1',
  },
  {
    display_name: 'VERIFIED_2',
    value: 'VERIFIED_2',
  },
];

export const COMPLIANCE_RESOLVE_STATUS = [
  { id: 1, label: 'Resolved', value: true },
  { id: 2, label: 'Unresolved', value: false },
];

export const FILE_TYPE = {
  EXCEL_SHEET:
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CSV_TEXT: 'text/csv',
  CSV_APPLICATION: 'application/csv',
  TEXT: 'text/plain',
};

export const USER_STATUS_ID = {
  UN_VERIFIED: 1,
  VERIFIED: 2,
  BLOCKED: 3,
  UN_BLOCKED: 4,
  PENDING: 5,
  SOFT_DELETED: 6,

  // UN_VERIFIED: 'Unverified',
  // VERIFIED: 'Verified',
  // BLOCKED: 'Blocked',
  // UN_BLOCKED: 'Unblocked',
  // PENDING: 'Pending',
  // SOFT_DELETED: 'Soft Deleted',
};

export const APP_USER_FILTERS = [
  { id: 1, statusText: 'OTC - Cashout', value: 'Branch' },
  { id: 2, statusText: 'ATM - Cashout', value: 'ATM' },
  { id: 3, statusText: 'Received', value: 'Received' },
  { id: 4, statusText: 'Payment', value: 'PAYMENT' },
];

export const APP_USER_STATUS = [
  { id: 1, statusText: 'Received', condition: '3', value: 4 },
  { id: 10, statusText: 'Cashing Out', condition: '3', value: 5 },
  { id: 2, statusText: 'Expired', condition: '3', value: 6 },
  { id: 3, statusText: 'Cashed Out', condition: '3', value: 7 },
  { id: 14, statusText: 'Failed', condition: '2', value: 8 },
  { id: 6, statusText: 'Completed', condition: '2', value: 1 },
  { id: 12, statusText: 'Cancelled', condition: '2', value: 2 },
  { id: 4, statusText: 'Pending', condition: '2', value: 3 },
  { id: 5, statusText: 'Expired', condition: '2', value: 6 },
  { id: 13, statusText: 'Failed', condition: '1', value: 8 },
  { id: 9, statusText: 'Completed', condition: '1', value: 1 },
  { id: 11, statusText: 'Cancelled', condition: '1', value: 2 },
  { id: 7, statusText: 'Pending', condition: '1', value: 3 },
  { id: 8, statusText: 'Expired', condition: '1', value: 6 },
  { id: 15, statusText: 'Accepted', condition: '3', value: 10 },
  { id: 16, statusText: 'Rejected', condition: '3', value: 11 },
  { id: 17, statusText: 'PENDING', condition: '4', value: 'PENDING' },
  { id: 18, statusText: 'PROCESSED', condition: '4', value: 'PROCESSED' },
  { id: 19, statusText: 'CANCELLED', condition: '4', value: 'CANCELLED' },
  { id: 20, statusText: 'REVERSED', condition: '4', value: 'REVERSED' },
];

export const APP_USER_PAYMENT_FILTERS = [
  'PENDING',
  'PROCESSED',
  'CANCELLED',
  'REVERSED',
];

export const APP_SENDER_FILTERS = [
  { id: 1, statusText: 'Cashout', value: 'CASHED_OUT' },
  { id: 2, statusText: 'Cashing Out', value: 'CASHING_OUT' },
  { id: 3, statusText: 'Received', value: 'RECEIVED' },
  { id: 3, statusText: 'Expired', value: 'EXPIRED' },
];

export const BLF_ROLES = [
  'security_inputter_user',
  'security_authorizer_user',
  'marketing_user',
  'monetique_user',
  'compliance_manager_user',
  'compliance_analyst_user',
];

export const GENDER = [
  {
    value: 'MALE',
    display_name: 'MALE',
  },
  {
    value: 'FEMALE',
    display_name: 'FEMALE',
  },
];

export const USER_PROFILE_BUTTONS = [
  {
    id: 1,
    name: 'Overview',
    value: 'Overview',
    isAdmin: true,
    isPCSU: false,
  },
  {
    id: 2,
    name: 'Compliance',
    value: 'Compliance',
    isAdmin: false,
    isPCSU: true,
  },
  {
    id: 3,
    name: 'Transactions',
    value: 'Transactions',
    isAdmin: false,
    isPCSU: true,
  },
  {
    id: 4,
    name: 'Feedback',
    value: 'Feedback',
    isAdmin: true,
    isPCSU: false,
  },
  {
    id: 5,
    name: 'Activity & comments',
    value: 'Activity & comments',
    isAdmin: false,
    isPCSU: true,
  },
  {
    id: 6,
    name: 'To-dos',
    value: 'To-dos',
    isAdmin: true,
    isPCSU: true,
  },
];
