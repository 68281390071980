'use strict';

// LOGIN routes
export const LOGIN = '/auth/login';
export const TWO_FACTOR = '/auth/two-factor';
export const RESET_PASSWORD = '/auth/reset-password';
export const FORGET_PASSWORD = '/auth/forgot-password';
export const CHANGE_PASSWORD = '/auth/change-password';

// ERROR route
export const ERROR = 'error';

// Dashboard
export const DASHBOARD = 'dashboard';

// Brand routes
export const BRAND_LISTING = '/entities/brands';
export const BRAND_ADD = `${BRAND_LISTING}/add`;
export const BRAND_EDIT = `${BRAND_LISTING}/edit`;

// Users routes
export const USERS_LISTING = '/users/cms-users';
export const USER_ADD = `${USERS_LISTING}/add`;
export const TELLERS_LISTING = '/users/tellers';
export const TELLER_ADD = `${TELLERS_LISTING}/add`;

// App Users routes
export const APP_USERS_LISTING = '/users/app-users';
export const UNREGISTERED_APP_USERS_LISTING = '/users/unregistered-app-users';
export const APP_USERS_FEEBACKS_LISTING = '/users/app-users-feedbacks';

// App Settings
// export const HOME_PAGE = '/app-settings/home-page';
// export const PRIVACY_POLICY = '/app-settings/privacy-policy';
// export const HELP_CENTER = '/app-settings/help-center';


// Remittance Platforms
export const REMITTANCE_PLATFORMS = 'remittance-platforms';

// Received Transactions
export const RECEIVED_TRANSACTIONS = 'received-transactions';

// Jumio Callback URL for Backend
export const JUMIO_CALLBACK = 'jumio-callback';
