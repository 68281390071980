<div class="col-md-12 p-4 custom-date-heading text-center">Custom Date</div>

<div class="col-md-12 p-2 text-center date-picker">
  <div class="input-group col-md-7 m-auto input-grp-p">
    <input
      class="datepicker-here form-control digits"
      (dateSelect)="onSelect('from', $event)"
      ngbDatepicker
      placeholder="From"
      #from="ngbDatepicker"
    />
    <div class="input-group-append">
      <button class="btn calendar" (click)="from.toggle()" type="button">
        <i class="fa fa-calendar"></i>
      </button>
    </div>
  </div>
</div>

<div class="col-md-12 p-2 text-center date-picker">
  <div class="input-group col-md-7 m-auto input-grp-p">
    <input
      class="datepicker-here form-control digits"
      (dateSelect)="onSelect('to', $event)"
      ngbDatepicker
      #to="ngbDatepicker"
      placeholder="To"
    />
    <div class="input-group-append">
      <button class="btn calendar" (click)="to.toggle()" type="button">
        <i class="fa fa-calendar"></i>
      </button>
    </div>
  </div>
</div>

<div class="col-md-12 p-4 d-flex justify-content-around">
  <button
    type="button"
    class="btn btn-primary btn-light-clr text-capitalize font-weight-normal"
    (click)="onCancel()"
  >
    Cancel
  </button>
  <button
    type="button"
    class="btn btn-primary text-capitalize font-weight-normal"
    (click)="onApplyFilterDate()"
  >
    Filter
  </button>
</div>
