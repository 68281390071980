import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppDashboardTitleComponent } from './app-dashboard-title.component';

@NgModule({
  declarations: [AppDashboardTitleComponent],
  imports: [CommonModule],
  exports: [AppDashboardTitleComponent],
})
export class AppDashboardTitleModule {}
