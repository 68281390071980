import { createFeatureSelector, createSelector } from '@ngrx/store';
import { createSlice } from '@reduxjs/toolkit';
import { ApiResponseBodyInterface } from '../../interfaces/api-response.interface';

export interface AppNGOState {
  appNGO: ApiResponseBodyInterface | null;
  loadingAppNGO: boolean;
}

const initialState: AppNGOState = {
  appNGO: null,
  loadingAppNGO: false,
};

const { actions, reducer, name } = createSlice({
  name: 'ngo',
  initialState,
  reducers: {
    // App Users
    getAllAppNGOPending: (state) => {
      state.loadingAppNGO = true;
    },
    getAllAppNGOFullfilled: (state, { payload }) => {
      state.loadingAppNGO = false;
      state.appNGO = payload;
    },
    getAllAppNGORejected: (state, { payload }) => {
      state.loadingAppNGO = true;
    },
  },
});

const selectAppNGOFeature =
  createFeatureSelector<ReturnType<typeof reducer>>(name);

export const selectAppNGOLoading = createSelector(
  selectAppNGOFeature,
  (state) => state?.loadingAppNGO
);

export const selectAllNGO = createSelector(
  selectAppNGOFeature,
  (state) => state?.appNGO
);

export { name };
export const {
  getAllAppNGOPending,
  getAllAppNGOFullfilled,
  getAllAppNGORejected,
} = actions;
export default reducer;
