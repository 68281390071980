import { Component, Injector, OnInit } from '@angular/core';
import { FormBaseComponent } from 'src/app/shared/components/base/form-base.component';
import { ENCRYPTION_SECRET } from 'src/app/shared/constants/constant-list';
import {
  LocalStorageService,
  SessionStorageService,
  UserService,
} from 'src/app/shared/services';

@Component({
  selector: 'app-two-factor-auth',
  templateUrl: './two-factor-auth.component.html',
  styleUrls: ['./two-factor-auth.component.scss'],
})
export class TwoFactorAuthComponent
  extends FormBaseComponent
  implements OnInit
{
  public isLoading = false;
  public isResending = false;
  public formValid = false;
  private user: any = null;
  private otpCode?: string;
  public email?: string;

  constructor(
    injector: Injector,
    private sessionService: SessionStorageService,
    private localService: LocalStorageService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    const twoFAuth = this.sessionService.getEncryptedData(
      '2FAuth',
      ENCRYPTION_SECRET
    );
    if (twoFAuth && twoFAuth?.email) {
      this.email = twoFAuth.email;
    }
  }

  // this called every time when user changed the code
  onCodeChanged(code: string) {
    this.formValid = false;
  }

  // this called only if user entered full code
  onCodeCompleted(code: string) {
    this.otpCode = code;
    this.formValid = true;
  }

  validateOTP(): void {
    if (this.isLoading) {
      return;
    }

    if (!this.formValid) {
      return;
    }

    this.isLoading = true;
    const twoFAuth = this.sessionService.getEncryptedData(
      '2FAuth',
      ENCRYPTION_SECRET
    );

    this.userService
      .twoFactor({
        ...twoFAuth,
        otp: this.otpCode,
      })
      .subscribe(
        (user) => {
          console.log('twoFactor: ', user);
          if (user) {
            this.user = user;
            this.userService.localService.setEncryptData(
              { key: 'user', value: this.user },
              ENCRYPTION_SECRET
            );

            this.userService.setUserDetails();
            this.sessionService.remove('2FAuth');
            this.handleRedirection(true, user?.password_changed);
          }
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  resenOTP(): void {
    this.isResending = true;
    this.formValid = false;

    const twoFAuth = this.sessionService.getEncryptedData(
      '2FAuth',
      ENCRYPTION_SECRET
    );

    this.userService
      .login({
        ...twoFAuth,
      })
      .subscribe({
        next: () => {
          this.isResending = false;
        },
        error: () => {
          this.isResending = false;
        },
      });
  }

  private handleRedirection(
    reload: boolean = false,
    password_changed: boolean = false
  ): void {
    const redirectUri = this.localService.getDataInLocalStorage('redirect');
    if (!reload) {
      this.sharedDataService.changeFormSubmitStatus(false);
    }

    if (reload) {
      if (redirectUri) {
        this.localService.removeDataInLocalStorage('redirect');
        window.location.replace(redirectUri);
      } else {
        if (password_changed) {
          window.location.replace(this.routeList.DASHBOARD);
        } else {
          window.location.replace(this.routeList.CHANGE_PASSWORD);
        }
      }
    } else {
      this.goToWithoutConfirmation(this.routeList.DASHBOARD);
    }
  }
}