import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
} from 'ag-grid-community';
import { isEmpty } from 'lodash';
import { TransactionDetailRendererComponent } from '../../renderers/transaction-detail-renderer/transaction-detail-renderer.component';
import { PartnersFilterRendererComponent } from '../../renderers/partners-filter-renderer/partners-filter-renderer.component';

@Component({
  selector: 'app-transactions-ag-grid',
  templateUrl: './transactions-ag-grid.component.html',
  styleUrls: ['./transactions-ag-grid.component.scss'],
})
export class TransactionsAgGridComponent implements OnInit {
  private gridApi!: GridApi<any>;
  public gridColumnApi: any = {};

  tableColumns: any[] = [];
  tableFilters: any[] = [];

  @Input() params: HttpParams | undefined;
  @Input() tableDataListItems: any[] = [];
  @Input() pageLimit?: number;
  @Input() page?: number;
  @Input() totalElements?: number;
  @Input() hasMore?: boolean;

  @Output() onPageLimitChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPageChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() onColumnFilterChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(private datePipe: DatePipe) {}

  ngOnInit(): void {
    this.setTableConfigurationByLanguage();
  }

  setTableConfigurationByLanguage(): void {
    this.tableColumns = [
      {
        field: 'datetime_created',
        headerName: 'SEND DATE',
        width: 220,
        valueFormatter: (obj: any) => {
          if (obj?.value) {
            return this.datePipe.transform(obj.value, 'medium');
          } else {
            return 'N/A';
          }
        },
      },
      {
        field: 'partner_reference',
        headerName: 'PARTNER REFERENCE',
        width: 250,
        filter: true,
        floatingFilter: true,
        floatingFilterComponent: PartnersFilterRendererComponent,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          filter: 'partner_reference__icontains',
          onSearchEvent: (event: any) => {
            this.onSearchInputChanged(event);
          },
        },
      },
      {
        field: 'amount',
        headerName: 'AMOUNT',
        width: 200,
      },
      {
        field: 'currency',
        headerName: 'CURRENCY',
        width: 150,
      },
      {
        field: 'datetime_updated',
        headerName: 'STATUS DATE',
        width: 220,
        valueFormatter: (obj: any) => {
          if (obj?.value) {
            return this.datePipe.transform(obj.value, 'medium');
          } else {
            return 'N/A';
          }
        },
      },
      {
        field: 'status',
        headerName: 'STATUS',
        width: 200,
        filter: true,
        floatingFilter: true,
        floatingFilterComponent: PartnersFilterRendererComponent,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          filter: 'status',
          onSearchEvent: (event: any) => {
            this.onSearchInputChanged(event);
          },
        },
      },
      {
        field: 'sender',
        headerName: 'SENDER',
        width: 300,
        filter: true,
        floatingFilter: true,
        floatingFilterComponent: PartnersFilterRendererComponent,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          filter: 'sender__name__icontains',
          onSearchEvent: (event: any) => {
            this.onSearchInputChanged(event);
          },
        },
      },
      {
        field: 'beneficiary',
        headerName: 'BENEFICIARY',
        width: 300,
        filter: true,
        floatingFilter: true,
        floatingFilterComponent: PartnersFilterRendererComponent,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          filter: 'receiver__last_name',
          onSearchEvent: (event: any) => {
            this.onSearchInputChanged(event);
          },
        },
      },
      {
        field: 'sender_receiver_relationship',
        headerName: 'SENDER RELATION',
        width: 200,
        filter: true,
        floatingFilter: true,
        floatingFilterComponent: PartnersFilterRendererComponent,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          filter: 'sender_receiver_relationship__icontains',
          onSearchEvent: (event: any) => {
            this.onSearchInputChanged(event);
          },
        },
      },
      {
        field: 'purpose',
        headerName: 'PURPOSE',
        width: 200,
        filter: true,
        floatingFilter: true,
        floatingFilterComponent: PartnersFilterRendererComponent,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          filter: 'purpose__icontains',
          onSearchEvent: (event: any) => {
            this.onSearchInputChanged(event);
          },
        },
      },
      {
        field: 'remarks',
        headerName: 'REMARKS',
        width: 250,
      },
      {
        field: 'id',
        headerName: 'TRANS. ID',
        width: 150,
        cellRendererSelector: (params: ICellRendererParams<any>) => {
          return {
            component: TransactionDetailRendererComponent,
            params: {
              ...params,
              httpParams: this.params,
            },
          };
        },
        filter: true,
        floatingFilter: true,
        floatingFilterComponent: PartnersFilterRendererComponent,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          filter: 'id',
          onSearchEvent: (event: any) => {
            this.onSearchInputChanged(event);
          },
        },
      },
    ];
  }

  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (!isEmpty(this.tableDataListItems)) {
      this.gridApi.setRowData(this.tableDataListItems);
    }
  }

  onSearchInputChanged(event: { filter: string; value: string }) {
    const appIndex = this.tableFilters.findIndex(
      (i) => i.field === event.filter
    );

    if (appIndex === -1) {
      this.tableFilters.push({
        field: event.filter,
        search: event.value,
      });
    } else {
      this.tableFilters[appIndex].field = event.filter;
      this.tableFilters[appIndex].search = event.value;
    }

    this.onColumnFilterChanged.emit(this.tableFilters);
  }
}
