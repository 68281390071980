<mat-dialog-content>
  <div class="card-heading mb-2">Transaction Details</div>
  <div class="d-flex">
    <div class="col-md-7 p-0">
      <div>
        <div class="detail-height">
          <div class="label-name text-style mb-1">Transaction ID</div>
          <div class="sub-details-val">
            {{ details.id }}
          </div>
        </div>
        <div class="detail-height">
          <div class="label-name text-style mb-1">Send Date</div>
          <div class="sub-details-val">
            {{ sendDate }}
          </div>
        </div>
        <div class="detail-height">
          <div class="label-name text-style mb-1">Beneficiary Name</div>
          <div class="sub-details-val">
            {{ details.beneficiary }}
          </div>
        </div>
        <div class="detail-height">
          <div class="label-name text-style mb-1">Transaction Status</div>
          <div class="sub-details-val">
            {{ status }}
          </div>
        </div>
        <div class="detail-height">
          <div class="label-name text-style mb-1">Reason for Transfer</div>
          <div class="sub-details-val">
            {{ details.purpose }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5 p-0">
      <div class="detail-height">
        <div class="label-name text-style mb-1">Partner Reference</div>
        <div class="sub-details-val">
          {{ details.partner_reference }}
        </div>
      </div>
      <div class="detail-height">
        <div class="label-name text-style mb-1">Status Date</div>
        <div class="sub-details-val">
          {{ statusDate }}
        </div>
      </div>
      <div class="detail-height">
        <div class="label-name text-style mb-1">Amount</div>
        <div class="sub-details-val">
          {{ details.amount }} {{ details.currency }}
        </div>
      </div>
      <div class="detail-height">
        <div class="label-name text-style mb-1">Sender Relation</div>
        <div class="sub-details-val">
          {{ details.sender_receiver_relationship }}
        </div>
      </div>
      <div class="detail-height">
        <div class="label-name text-style mb-1">Remarks</div>
        <div class="sub-details-val">
          {{ details.remarks }}
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="actions-btn pull-right">
  <a class="button button-text close" (click)="close()"> Close</a>
</mat-dialog-actions>
