<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="d-flex flex-row justify-content-between align-items-center">
    <div>
      <h4 class="add-title">
        <img
          (click)="backArrowClicked()"
          class="back-arrow"
          src="/assets/images/purpl/back-arrow.png"
          alt="back-arrow"
        />
        Edit Profile
      </h4>
      <div class="add-title-role text-capitalize">
        {{ userService.user?.position?.replace("_", " ") }} Panel
      </div>
    </div>
    <div class="pull-right">
      <button
        type="button"
        class="btn btn-primary"
        (click)="submit()"
        [disabled]="!form?.valid"
      >
        Save
      </button>
    </div>
  </div>
  <div class="row m-t-20">
    <div class="col-12">
      <div class="card tab2-card">
        <div class="card-body tab2-card">
          <form
            [formGroup]="form"
            class="needs-validation user-add"
            novalidate=""
          >
            <div class="pb-5">
              <h5 class="float-left">Edit Profile Details</h5>
            </div>

            <div class="form-group row">
              <label for="image" class="col-xl-2 col-md-2">
                Profile Picture
              </label>
              <div class="col-xl-8 col-md-7 p-l-0">
                <div class="box-input-file">
                  <img
                    class="imgUpload img-placeholder-width"
                    alt=""
                    [src]="image.img"
                  />
                  <button
                    type="button"
                    class="btn btn-primary m-l-20"
                    [title]="'Upload the picture'"
                    (click)="fileElement.click()"
                  >
                    Upload
                  </button>
                  <input
                    class="form-control d-none"
                    id="image"
                    type="file"
                    #fileElement
                    (change)="onImageSelected($event)"
                  />
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label for="firstName" class="col-xl-2 col-md-2"
                ><span>*</span> First Name</label
              >
              <input
                (input)="onFirstNameChange($event.target.value)"
                fromControlName="firstName"
                [value]="editDetails?.first_name"
                class="form-control col-xl-3 col-md-3"
                id="firstName"
                type="text"
                required
              />
              <label for="lastName" class="pl-3 col-xl-2 col-md-2">
                <span class="pl-4">*</span> Last Name
              </label>
              <input
                (input)="onLastNameChange($event.target.value)"
                fromControlName="lastName"
                [value]="editDetails?.last_name"
                class="form-control col-xl-3 col-md-3"
                id="lastName"
                type="text"
                required
              />
            </div>

            <div class="form-group row">
              <label for="email" class="col-xl-2 col-md-2"
                ><span>*</span> Email</label
              >
              <input
                [disabled]="true"
                fromControlName="email"
                autocomplete="off"
                [value]="editDetails?.email"
                class="form-control col-xl-3 col-md-3"
                id="email"
                type="email"
                required
              />
              <label for="password" class="pl-3 col-xl-2 col-md-2">
                <span class="pl-4">*</span> Password
              </label>
              <input
                (input)="onPasswordChange($event.target.value)"
                autocomplete="off"
                fromControlName="password"
                class="form-control col-xl-3 col-md-3"
                id="password"
                [type]="hide ? 'text' : 'password'"
                required
                [placeholder]="editDetails ? 'Leave empty if unchanged' : ''"
              />
              <span
                class="password-field"
                (click)="hide = !hide"
                [title]="hide ? 'Hide the password' : 'Show the password'"
              >
                <img
                  [src]="
                    hide
                      ? '/assets/images/svg/eye_hide.svg'
                      : '/assets/images/svg/eye_visible.svg'
                  "
                  alt="password-field"
                />
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
