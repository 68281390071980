<div class="modal-header justify-content-center">
  <h5 class="modal-title">
    <span *ngIf="isSuspend">Suspend </span>
    <span *ngIf="!isSuspend">Unsuspend </span>
    <span *ngIf="suspend === 'transaction'">Transaction</span>
    <span *ngIf="suspend === 'remittance'">Remittance</span>
  </h5>
</div>
<div class="modal-body">
  <p>
    Are you sure you want to <span *ngIf="isSuspend">suspend</span>
    <span *ngIf="!isSuspend">unsuspend</span>
    <span *ngIf="suspend === 'transaction'"> transaction</span>
    <span *ngIf="suspend === 'remittance'"> remittance</span>
    “{{ transaction?.id }}”?
  </p>
</div>
<div class="modal-footer justify-content-center">
  <button type="button" class="btn btn-danger" (click)="onCancel()">
    Cancel
  </button>
  <button type="button" class="btn btn-primary" (click)="onConfirm()">
    <i class="fa fa-spinner fa-spin" *ngIf="isLoading"></i>
    <span *ngIf="!isLoading">Confirm</span>
  </button>
</div>
