import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-purpl-table-full-name',
  templateUrl: './purpl-table-full-name.component.html',
  styleUrls: ['./purpl-table-full-name.component.scss'],
})
export class PurplTableFullNameComponent implements OnInit {
  @Input() imgSrc?: string;
  @Input() full_name?: string;

  constructor() {}

  ngOnInit(): void {}
}
